.sections_main_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url(../../img/fullfon.png);
    background-size: cover;
    overflow-y: scroll;
    position: relative;
    padding: 12vw 5vw 5vw 5vw;
}
.section_container_box{
    display: flex;
    width: 100%;
}
.sections_undertitle{
    font-size: 4.2vw;
    text-align: center;
    margin-bottom: 4vw;
}
.sections_header_img{
    width: 60%;
    margin-bottom: 8vw;
}