.birgesPage_container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11vw 5vw 5vw 5vw;
    background: url(../../img/welcomeBackground.png);
    background-size: cover;
}
.birgesPage_title{
    align-self: flex-start;
    font-size: 10vw;
    line-height: 8vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 2vw;
}
.birgesImg{
    width: 50vw;
    margin-bottom: 3.5vw;
    transform: rotateZ(0deg) scale(0);
}
.birges_row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vw;
}
.birges_partOne{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 57%;
    padding-top: 4vw;
    background: #ffffff;
    overflow: hidden;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
}
.birges_partTwo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 39%;
    padding-top: 4vw;
    background: #ffffff;
    overflow: hidden;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
}
.birges_partThree{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 31.5%;
    padding-top: 2vw;
    background: #ffffff;
    overflow: hidden;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
}
.birges_partOne img{
    width: 44vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.05s;
}
.birges_partTwo img{
    width: 28vw;
    margin-top: 2.7vw;
    margin-bottom: 3.2vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.1s;
}
.birgesImg3{
    width: 23.7vw;
    margin-bottom: 1vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.15s;
}
.birgesImg4{
    margin-top: 1vw;
    width: 23vw;
    margin-bottom: 5.2vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.2s;
}
.birgesImg5{
    margin-top: 4vw;
    width: 18vw;
    margin-bottom: 4.3vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.25s;
}
.part_title{
    color: #561AD3;
    font-weight: 900;
    font-size: 4.2vw;
}
.part_text{
    font-size: 3.1vw;
    color: #1B1B1B;
    margin-bottom: 3.5vw;
}
.birges_partOne a{
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 2vw 0;
    font-size: 3.5vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    text-align: center;
}
.birges_partOne a:link {
    color: #ffffff;
}
.birges_partOne a:visited {
    color: #ffffff;
}
.birges_partOne a:hover {
    color: #ffffff;
}
.birges_partOne a:active {
    color: #ffffff;
}
.birges_partTwo a{
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 2vw 0;
    font-size: 3.5vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    text-align: center;
}
.birges_partTwo a:link {
    color: #ffffff;
}
.birges_partTwo a:visited {
    color: #ffffff;
}
.birges_partTwo a:hover {
    color: #ffffff;
}
.birges_partTwo a:active {
    color: #ffffff;
}
.birges_partThree a{
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 2vw 0;
    font-size: 3.5vw;
    margin-top: 2.5vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    text-align: center;
}
.birges_partThree a:link {
    color: #ffffff;
}
.birges_partThree a:visited {
    color: #ffffff;
}
.birges_partThree a:hover {
    color: #ffffff;
}
.birges_partThree a:active {
    color: #ffffff;
}
@keyframes wiggle {
    0%, 2% {
      transform: rotateZ(0);
    }
    5% {
      transform: rotateZ(-15deg);
    }
    7% {
      transform: rotateZ(10deg);
    }
    8% {
      transform: rotateZ(-10deg);
    }
    10% {
      transform: rotateZ(6deg);
    }
    12% {
      transform: rotateZ(-4deg);
    }
    13%, 100% {
      transform: rotateZ(0);
    }
  }
.birges_first_animation{
    animation: 6s linear infinite;
}
.birges_sec_animation{
    animation: 6s linear infinite;
    animation-delay: 0.7s;
}
.birgesImg3{
    animation: 6s linear infinite;
    animation-delay: 1.4s;
}
.birgesImg4{
    animation: 6s linear infinite;
    animation-delay: 2.1s;
}
.birgesImg5{
    animation: 6s linear infinite;
    animation-delay: 2.8s;
}