.settings {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 4vw;
  height: 4vw;
  padding: 0.5vw;
  border-radius: 50%;
  background: rebeccapurple;
  cursor: pointer;
}
.settings img {
  width: 100%;
}
.tgs {
  position: absolute;
  bottom: 0vw;
  right: 1vw;
  z-index: 100000;
  width: 11vw;
  height: 11vw;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}
.mainTgs {
  position: fixed;
  bottom: 0vw;
  right: 1vw;
  width: 11vw;
  height: 11vw;
  z-index: 100000;
  cursor: pointer;
}
.mainTgs:hover .tgsText {
  transform: translate(-50%, -50%) scaleX(1);
}
.tg,
.tgt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}
.tg {
  width: 6.5vw;
  z-index: 100001;
  pointer-events: none;
}
.tgt {
  width: 11vw;
  animation: rotate 12s infinite;
  animation-timing-function: linear;
}
.mainTgs:hover .tgt {
  width: 5vw;
}
.tgsText p {
  margin-left: 2vw;
}
.tgsText {
  color: #fff;
  font-size: 1.2vw;
  background: #4514aa;
  border: 1px solid #ffffff;
  border-radius: 50px 0 0 50px;
  position: absolute;
  height: 4.7vw;
  text-align: right;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: -24%;
  transform-origin: right;
  transform: translate(-50%, -50%) scaleX(0);
  width: 14vw;
  overflow: hidden;
}
.landing__body {
  background: #1f1d21;
  overflow-x: hidden;
}
.land_img {
  width: 100%;
}
.settings {
  z-index: 100;
}
.pop_settings {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  z-index: 50;
  flex-direction: column;
  align-items: center;
  background: url(./settings.jpg);
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3.5vw;
}
.kek {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5vw;
  font-weight: 800;
  margin-left: 0.5vw;
  width: fit-content;
}
.pop_settings input {
  background: rgba(255, 255, 255, 0.2);

  outline: none;
  border-radius: 1vw;
  border: none;
  padding: 2vw;
  font-size: 1.2vw;
  color: #b7b1c2;
  width: 45vw;
}
.pop_set_body > div {
  margin-bottom: 2.5vw;
}
.pop_settings div {
  display: flex;
  align-items: center;
}
.header_land {
  position: relative;
}
.ult_head {
  top: 12vw;
  left: 27%;
  transform: translateX(-50%);
  width: 46vw;
  position: absolute;
}
.header_desc {
  text-align: center;
  font-size: 1.2vw;
  color: #ffffff;
  position: absolute;
  top: 18vw;
  width: 56vw;
  left: 22%;
  transform: translateX(-50%);
}
.title_pop {
  padding-bottom: 2vw;
}
.pop_set_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.desc_pop {
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.6vw;
  width: 31vw;
}
.desc_more {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.land9,
.land12 {
  position: relative;
}
.tel_img {
  display: flex;
  align-items: center;
  position: absolute;
  top: 36vw;
  width: 100vw;
  justify-content: center;
}
.tel_img img {
  transition: 0.3s;
  width: 25vw;
}
.tel_img img:hover {
  transform: scale(1.05);
}
.tel_img img:first-child {
  margin-right: -6vw;
}
.tel_img img:last-child {
  margin-left: -6vw;
}
.timer {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.11);
  border-radius: 10vw;
  padding: 1.5vw 2vw;
  width: fit-content;
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 28vw;
}
.casul {
  display: flex;
  align-items: center;
}
.copy_link input {
  background: none;
  border: none;
  color: #fff;
}
.personal__code {
  padding: 2vw 3vw;
  border: 2px solid rgba(54, 50, 56, 0.6);
  border-radius: 1vw;
  position: absolute;
  left: 50%;
  top: 17vw;
  font-size: 1.5vw;
  font-weight: 800;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-50%);
}
.copy {
  color: black;
  font-size: 1vw;
  padding: 0.8vw 2vw;
  background: #a8ff00;
  border-radius: 2.5vw;
  display: flex;
  align-items: center;
  margin-left: 2vw;
  cursor: pointer;
}
.reg {
  position: absolute;
  left: 50%;
  bottom: 11vw;
  font-size: 1.5vw;
  margin-left: 0 !important;
  font-weight: 800;
  text-align: center;
  transform: translateX(-50%);
}
.copy img {
  width: 1.5vw;
  margin-left: 0.5vw;
}
.casul {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 2.5vw;
  padding: 0.5vw;
  padding-left: 2vw;
  margin-top: 1.5vw;
}
.timer div {
  margin-right: 5vw;
  width: 16vw;
}
.timer p {
  font-size: 4.5vw;
  font-weight: 800;
  line-height: 3.5vw;
}
.timer span {
  font-size: 1.3vw;
}
.timer .link__to {
  padding: 1vw 1.5vw !important;
}
.desc_more p {
  font-size: 1vw;
}
.img_load,
.link__to {
  background: #a8ff00;
  border-radius: 2.5vw;
  font-size: 1.3vw;
  color: #1f1d21;
  padding: 1.3vw 2.5vw;
  cursor: pointer;
}
.link__to {
  padding: 1vw 3.5vw !important;
}
#img__path {
  display: none;
}
.wait_photo {
  width: 12vw;
  height: 10vw;
  background: #ffffff;
  mix-blend-mode: overlay;
  margin-left: 0.5vw;
  margin-right: 2vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #b7b1c2;
  font-size: 1.2vw;
}
.side_content {
  position: absolute;
  right: 12vw;
  bottom: 5vw;
  background: rgba(54, 50, 56, 0.6);
  border-radius: 2vw;
  padding: 1vw;
  display: flex;
  align-items: center;
}
.side_content > img {
  width: 12.5vw;
  margin: 2.5vw;
}
.side_content h3 {
  color: #533989;
  font-size: 2.3vw;
  margin-bottom: 1vw;
}
.side_content div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.side_content p {
  font-size: 1.3vw;
  color: #fff;
  width: 50vw;
  line-height: 1.5vw;
  margin-bottom: 1.5vw;
}
.photo_red {
  width: 12vw;
  height: 10vw;

  margin-left: 0.5vw;
  margin-right: 2vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #b7b1c2;
  font-size: 1.2vw;
}
.get_trainer {
  text-decoration: none;
  color: #fff;
  background: rebeccapurple;
  padding: 5vw 10vw;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 3vw;
  width: 100%;
  margin-top: 5vw;
}
.save__button {
  color: #fff;
  background: rebeccapurple;
  padding: 1vw 2vw;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
  margin-bottom: 0 !important;
}
.landing__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info__user {
  display: flex;
  align-items: center;
  padding: 5vw;
}
.info__user img {
  width: 30vw;
  border-radius: 50%;
  margin-right: 4vw;
}
.button_head {
  position: absolute;
  right: 13vw;
  top: 2.4vw;
  width: 13vw;
  cursor: pointer;
}
.land4 {
  position: relative;
}

.slider {
  position: absolute;
  left: 50%;
  bottom: 5vw;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slider_body {
  width: 70vw;
  height: 39.5vw;

  border-radius: 1.5vw;
  overflow: hidden;
}
.slider__buttons {
  display: flex;
  align-items: center;
}
.land5,
.land6,
.land8 {
  position: relative;
}
.youtube {
  position: absolute;
  bottom: 9.4vw;
  left: 50%;
  transform: translateX(-50%);
  width: 63.2vw;
  height: 35.7vw;
  border-radius: 0 !important;
}
.registration:hover,
.reg:hover {
  transform: translateX(-50%) translateY(-5px);
  box-shadow: 0px 0px 32px 15px rgb(168 255 0 / 50%);
}
.watch1:hover {
  box-shadow: 0px 0px 32px 15px rgb(168 255 0 / 30%);
  transform: translateX(-50%) translateY(-45%);
}
.copy_value:hover,
.link__to:hover {
  box-shadow: 0px 0px 22px 9px rgb(168 255 0 / 20%);
  transform: translateY(-5px);
}
.button_head:hover {
  transform: scale(1.05);
}
.youtube2 {
  position: absolute;
  top: 15.5vw;
  left: 49.3%;
  transform: translateX(-50%);
  width: 63.2vw;
  height: 35.7vw;
  border-radius: 0 !important;
}
.youtube3 {
  position: absolute;
  bottom: 7.5vw;
  left: 49.3%;
  transform: translateX(-50%);
  width: 63.2vw;
  height: 35.7vw;
  border-radius: 0 !important;
}
.left,
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  border: 3px solid #ffffff;
  color: #fff;
  font-size: 1.3vw;
  margin: 0.5vw;
  margin-top: 1vw;
  cursor: pointer;
  opacity: 0.6;
}
.lins {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -32vw;
}
.link_container {
  border: 2px solid rgba(255, 255, 255, 0.26);
  border-radius: 1vw;
  padding: 1vw;
  width: 27vw;
  margin: 1vw;
  color: #fff;
}
.copy_link {
  border: 2px solid rgba(255, 255, 255, 0.26);
  border-radius: 1vw;
  padding: 1vw;
  height: 9vw;
}
.copy_value {
  background: #a8ff00;
  border-radius: 2.5vw;
  font-size: 1vw;
  width: fit-content;
  padding: 1vw 2.5vw;
  color: black;
  margin: 1vw auto;
  margin-bottom: 0;
  cursor: pointer;
}
.copy_link p {
  word-break: break-all;
  text-align: center;
  font-size: 1vw;
}
.link_container > p {
  font-size: 1.5vw;
  text-align: center;

  height: 6vw;
}
.slider_body div {
  display: flex;
  width: 100%;
}
.slider_body img {
  width: 100%;
}
.slide_active {
  border: 3px solid #a8ff00 !important;
  color: #fff !important;
  opacity: 1 !important;
}
.registration {
  color: #1f1d21;
  font-size: 1.2vw;
  padding: 1.5vw 3vw;
  color: #1f1d21;
  border-radius: 2.5vw;
  position: absolute;
  left: 40%;
  top: 34vw;
  cursor: pointer;
  background: #a8ff00;
  -webkit-box-shadow: 0px 0px 20px 9px rgba(168, 255, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 9px rgba(168, 255, 0, 0.2);
  box-shadow: 0px 0px 20px 9px rgba(168, 255, 0, 0.2);
  transform: translateX(-50%);
}
.land3 {
  position: relative;
}
.dbd img {
  width: 19vw;
  margin: 0 1vw;
}
.dbd {
  display: flex;
  position: absolute;
  top: 42vw;
  width: 100vw;
  justify-content: center;
}
.slider_body > div {
  position: relative;
}
.dbd img:hover {
  transform: translateY(-10px);
}
.ddd {
  position: absolute;
  top: 68vw;
  width: 50vw;
  left: 25vw;
}
.watch1 {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #a8ff00;
  color: #1f1d21;
  font-size: 1.3vw;
  border-radius: 2.5vw;
  padding: 1.5vw 2vw;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.slider_body img {
  opacity: 0.3;
}
@media screen and (max-width: 640px) {
  .registration {
    color: #1f1d21;
    font-size: 5.2vw;
    padding: 4.5vw 9vw;
    color: #1f1d21;
    border-radius: 11.5vw;
    position: absolute;
    left: 10.5%;
    top: 148vw;
    cursor: pointer;
    background: #a8ff00;
    box-shadow: 0px 0px 20px 9px rgb(168 255 0 / 20%);
    transform: translateX(-50%);
  }
  .watch1 {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #a8ff00;
    color: #1f1d21;
    font-size: 4.3vw;
    border-radius: 8.5vw;
    padding: 4.5vw 4vw;
    transform: translate(-50%, -50%);
    width: 75vw;
    text-align: center;
    display: block;
    cursor: pointer;
  }
  .header_desc {
    text-align: center;
    font-size: 4.2vw;
    color: #ffffff;
    position: absolute;
    top: 74vw;
    width: 87vw;
    left: 6%;
    transform: translateX(-50%);
  }
  .button_head {
    position: absolute;
    right: 4vw;
    top: 8.4vw;
    width: 39vw;
    cursor: pointer;
  }
  .slider_body {
    width: 94vw;
    height: 53.5vw;
    border-radius: 3.5vw;
    overflow: hidden;
  }
  .left,
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9vw;
    height: 9vw;
    border-radius: 50%;
    border: 3px solid #ffffff;
    color: #fff;
    font-size: 3.3vw;
    margin: 3vw;
    margin-top: 1vw;
    cursor: pointer;
    opacity: 0.6;
  }
  .youtube {
    position: absolute;
    bottom: 36.2vw;
    left: 50%;
    transform: translateX(-50%);
    width: 83vw;
    height: 47vw;
    border-radius: 0;
    border: none;
  }
  .lins {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: -160vw;
  }
  .link_container {
    border: 2px solid rgba(255, 255, 255, 0.26);
    border-radius: 4vw;
    padding: 4vw;
    width: 90vw;
    margin: 4vw;
    color: #fff;
  }
  .link_container > p {
    font-size: 4.5vw;
    text-align: center;
    height: 14vw;
  }
  .copy_link {
    border: 2px solid rgba(255, 255, 255, 0.26);
    border-radius: 3vw;
    padding: 4vw;
    height: auto;
  }
  .youtube2 {
    position: absolute;
    top: 45.2vw;
    left: 50%;
    transform: translateX(-50%);
    width: 83.3vw;
    height: 47vw;

    border: none;
  }
  .youtube3 {
    position: absolute;
    bottom: 24vw;
    left: 49.3%;
    transform: translateX(-50%);
    width: 84vw;
    height: 46.7vw;
    border-radius: 0 !important;
  }
  .side_content {
    position: static;
    width: 90vw;
    transform: none;
    margin: 0 auto;
    background: rgba(54, 50, 56, 0.6);
    border-radius: 4vw;
    padding: 4vw;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .side_content > img {
    width: 57%;
    margin: 2.5vw;
    max-height: 43vw;
  }
  .side_content div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .side_content h3 {
    color: #533989;
    font-size: 8.3vw;
    text-align: center;
    margin-bottom: 1vw;
  }
  .img_load,
  .link__to {
    background: #a8ff00;
    border-radius: 6.5vw;
    font-size: 4.3vw;
    color: #1f1d21;
    padding: 3.3vw 7.5vw;
    cursor: pointer;
  }
  .title_pop {
    width: 90vw;
    padding-bottom: 8vw;
  }
  .pop_set_body div {
    flex-direction: column;
    align-items: center;
  }
  .pop_set_body div p {
    text-align: center;
  }
  .desc_pop {
    width: 90vw;
    font-size: 5vw;
  }
  .desc_more p {
    font-size: 3.5vw;
    font-weight: 700;
  }
  .pop_settings input {
    background: rgba(255, 255, 255, 0.2);

    outline: none;
    border-radius: 3vw;
    border: none;
    margin-top: 2vw;
    padding: 4vw;
    font-size: 4.2vw;
    color: #b7b1c2;
    width: 90vw;
    margin-bottom: 5vw;
  }
  .save__button {
    color: black;
    background: #a8ff00;
    padding: 3vw 6vw;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 9vw;
    font-size: 5.5vw;
    width: 50vw;
    cursor: pointer;
    margin-bottom: 0 !important;
    margin: 0 auto;
  }
  .img_load {
    margin-bottom: 4vw;
  }
  .photo_red {
    width: 40vw;
    height: 30vw;
    margin: 4vw 0;
  }
  .desc_more {
    text-align: center;
  }
  .settings {
    position: fixed;
    bottom: 4vw;
    right: 4vw;
    width: 10vw;
    height: 10vw;
    padding: 0.5vw;
    border-radius: 50%;
    background: rebeccapurple;
    cursor: pointer;
  }
  .settings img {
    padding: 1vw;
  }
  .timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.11);
    border-radius: 6vw;
    padding: 4.5vw 5vw;
    width: -moz-fit-content;
    width: fit-content;
    color: #fff;
    position: absolute;
    left: 50%;
    width: 90vw;
    transform: translateX(-50%);
    bottom: 26vw;
    top: auto;
  }
  .copy img {
    width: 3.5vw;
    margin-left: 1.5vw;
  }
  .reg {
    position: absolute;
    left: 50%;
    bottom: 47vw;
    font-size: 5.5vw !important;
    margin-left: 0 !important;
    font-weight: 800;
    padding: 2vw 5vw !important;
    text-align: center;
    transform: translateX(-50%);
  }
  .personal__code {
    padding: 6vw 8vw;
    border: 2px solid rgba(54, 50, 56, 0.6);
    border-radius: 5vw;
    position: absolute;
    left: 50%;
    width: 90vw;
    top: 59vw;
    text-align: center;
    font-size: 6.5vw;
    font-weight: 800;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);
  }
  .copy {
    color: black;
    font-size: 3vw;
    padding: 2.8vw 4vw;
    background: #a8ff00;
    border-radius: 7.5vw;
    display: flex;
    align-items: center;
    margin-left: 2vw;
    cursor: pointer;
  }
  .casul {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 6.5vw;
    padding: 1.5vw;
    padding-left: 2vw;
    margin-top: 1.5vw;
  }
  .timer div {
    margin-right: 0;
    width: auto;
    text-align: center;
  }
  .timer p {
    font-size: 12.5vw;
    font-weight: 800;
    line-height: 12.5vw;
  }
  .timer .link__to {
    padding: 3vw 5.5vw !important;
    transform: translateY(9vw);
  }
  .timer span {
    font-size: 4vw;
  }

  .link__to {
    padding: 3vw 9.5vw !important;
  }
  .side_content p {
    font-size: 4.3vw;
    color: #fff;
    width: 90%;
    text-align: center;

    line-height: 6vw;
    margin-bottom: 4.5vw;
  }

  .kek {
    background: none;
    border: none;
    color: #fff;
    font-size: 6.5vw;
    font-weight: 800;
    margin-left: 1.5vw;

    width: 15vw;
  }
  .copy_link p {
    word-break: break-all;
    text-align: center;
    font-size: 3vw;
  }
  .copy_value {
    background: #a8ff00;
    border-radius: 9.5vw;
    font-size: 3.5vw;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3vw 7.5vw;
    color: black;
    margin: 3vw auto;
    margin-bottom: 0;
    cursor: pointer;
  }
  .mainTgs {
    position: fixed;
    bottom: 25vw;
    right: 2vw;
    width: 20vw;
    height: 20vw;
    z-index: 100000;
    cursor: pointer;
    display: block;
  }
  .tgs {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
  }
  .mainTgs:hover .tgt {
    width: 29vw;
  }
  .tgt {
    width: 29vw;
    animation: rotate 12s infinite;
    animation-timing-function: linear;
  }
  .tg {
    width: 18.5vw;
    z-index: 100001;
    pointer-events: none;
  }
}
@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
