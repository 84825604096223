.profile_settings_container{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(../../img/fullfon.png);
    background-size: cover;
    overflow-y: scroll;
    padding: 10vw 5vw 0vw 5vw;
}
.profile_settings_container > input{
    width: 100%;
    padding: 4vw 2vw 4vw 5vw;
    margin-bottom: 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 1.5vw;
    font-weight: 900;
    font-size: 4vw;
    text-transform: none;
}
.secondInput {
    transform: scale(0);
    transition-delay: 0.1s;
}
.profile_settings_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 7vw;
    align-self: flex-start;
}
.password_box{
    display: flex;
    background: #EAE5F3;
    width: 100%;
    border-radius: 3vw;
    margin-bottom: 4.5vw;
}
.password_box_title{
    width: 33.5vw;
    font-size: 3.6vw;
    color: #4B4359;
    padding: 4vw 0 4vw 3.5vw;
    border-right: 1vw solid #DED6ED;
}
.password_box_input{
    width: 61%;
    outline: none;
    border: none;
    background: none;
    padding-left: 3.5vw;
    font-size: 4.1vw;
    letter-spacing: 0.1vw;
}
.change_password{
    align-self: flex-start;
    width: 100%;
    outline: none;
    border: none;
    background: linear-gradient(110deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    color: #ffffff;
    font-size: 4.1vw;
    padding: 4.1vw 0;
    border-radius: 2vw;
    margin-bottom: 6vw;
    transform: scale(0);
    transition-delay: 0.20s;
}
.profile_settings_changeTitle{
    font-weight: 900;
    font-size: 6.5vw;
    margin-bottom: 3vw;
    transform: scale(0);
    transition-delay: 0.25s;
}
.referal_box_settings{
    width: 100%;
    background: #ffffff;
    margin-bottom: 8.5vw;
    position: relative;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
    transform: scale(0);
    transition-delay: 0.25s;
}
.referal_box_settings p{
    text-align: center;
    color: #ffffff;
    border-radius: 3vw 3vw 0 0;
    font-size: 4.3vw;
    padding: 2.2vw 0;
    background: linear-gradient(303deg, rgba(86, 26, 211, 0.4) 0%, rgba(83, 0, 253, 0.6) 100%);
}
.referal_link_settings{
    width: 100%;
    padding: 3vw 2.5vw;
    background: #ffffff;
    position: relative;
    border-radius: 3vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.secSInput{
    transform: scale(0);
    transition-delay: 0.05s;
}
.referal_link_settings input{
    width: 100%;
    padding: 2.2vw 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 2.5vw;
    font-size: 3.8vw;
    text-transform: none;
    transition: 0s;
}
.referal_link_settings input::placeholder{
    text-align:start;
    transform: translateX(10vw);
}
.referal_link_settings input:focus-visible{
    outline: 0.1vw solid #702EF7;
}
.referal_link_settings button{
    position: absolute;
    right: 2.5vw;
    background: linear-gradient(110deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    border-radius: 2.5vw;
    font-size: 3.8vw;
    padding: 2.2vw 1.5vw;
    color: #ffffff;
    border: none;
}

.qr_code_box_settings{
    display: flex;
    background: #EAE5F3;
    border-radius: 3vw;
    width: 100%;
    transform: scale(0);
    transition-delay: 0.30s;
}
.white_box_for_qrcode{
    width: fit-content;
    margin-right: 5vw;
    background: #ffffff;
    border-radius: 3vw;
    padding: 3vw;
}
.white_box_for_qrcode > svg{
    width: 26vw;
    height: 26vw;
}
.your_qr_code_settings{
    padding: 5vw 0vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.your_qr_code_settings p{
    font-size: 5vw;
    width: 38vw;
    font-weight: 900;
    line-height: 4.6vw;
}
.your_qr_code_settings img{
    width: 17vw;
    margin-bottom: 2vw;
}