.header_bot {
  background: url(./fon.png);
  background-color: #201c24;
  background-size: cover;
  padding-top: 7vw;
  padding-bottom: 5vw;
  height: 100vh;
}
.bot_land {
  width: 100vw;
  overflow-x: hidden;
  background-color: #201c24;
}
.header_title {
  display: flex;
  align-items: flex-end;
  color: #fff;
}
.header_bot {
  width: 100%;
}

.header_title h1 {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 8vw;
}
.header_title span {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  width: 20vw;
  display: block;
  margin-left: 1vw;
  margin-bottom: 1.6vw;
}
.header_bot_body {
  width: 72vw;
  margin: 0 auto;
  position: relative;
}
.bot_sub {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 900;
  font-size: 3.5vw;

  /* identical to box height, or 91px */
  margin-top: -1vw;
  color: #a8ff00;
}
.x1_bot {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5vw;

  /* or 62px */

  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(142, 0, 253, 0.8) 28.23%,
      rgba(142, 0, 253, 0) 100%
    ),
    radial-gradient(
        94.78% 94.78% at 49.32% 5.22%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 49.95%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        65.67% 65.67% at 52.27% 146.27%,
        rgba(0, 0, 0, 0.2) 62.89%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #561ad3;
  border-radius: 3.5vw;
  width: fit-content;
  padding: 0.5vw 1.5vw;
  color: #ffffff;
  margin-top: 1.5vw;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.pop_bot {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}
.closeme {
  margin-left: 74vw;
  width: 3vw;
  cursor: pointer;
}
.youtubeBot1 {
  width: 70vw;
  height: 40vw;
  border-radius: 0 !important;
}
.product {
  color: #fff;
  font-size: 1.6vw;
  font-weight: 900;
  margin-top: 2.5vw;
}
.link_instruction {
  background: #a8ff00;
  box-shadow: 0px 0px 26px rgba(168, 255, 0, 0.38);
  border-radius: 2.8vw;
  font-size: 1.2vw;
  padding: 1.3vw 4vw;
  display: block;
  width: fit-content;
  font-weight: 700;
}
.link_instruction:hover {
  box-shadow: 0px 0px 36px rgba(168, 255, 0, 0.48);
  transform: translateY(-7px);
}
.bot_list {
  list-style-type: none;
  margin-top: 1vw;
}
.butt_sm {
  position: absolute;
  width: 9vw;
  top: 14vw;
  left: 40vw;
  cursor: pointer;
  animation: shake 2s infinite;
}
.bot_list li {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.05vw;
  margin-bottom: 0.5vw;
}
.bot_list li img {
  margin-right: 1vw;
  width: 2.2vw;
}
.bot_buttons {
  margin-top: 2.3vw;
  display: flex;
  justify-content: space-between;
}
.bot_buttons div a {
  background: rgba(31, 29, 33, 0.73);
  border: 2px solid #a8ff00;
  box-shadow: 0px 0px 26px rgba(168, 255, 0, 0.2);
  border-radius: 2.8vw;
  padding: 1.3vw 4vw;
  color: #fff;
  display: block;
  margin-left: 1.5vw;
  font-size: 1vw;
}
.bot_buttons div {
  display: flex;
  margin-right: -3vw;
}
.bot_buttons div a:hover {
  box-shadow: 0px 0px 36px rgba(168, 255, 0, 0.4);
  transform: translateY(-7px);
}
.youtubeBot {
  position: absolute;
  width: 50vw;
  height: 28.2vw;
  bottom: 18.8vw;
  border-radius: 0 !important;
}
.vid {
  width: 100vw;
}
.bot_video {
  position: relative;
  background: #201c24;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.razd {
  width: 80vw;
}
@media screen and (max-width: 800px) {
  .header_bot {
    background: url(./mob.png);
    background-size: cover;
    padding-top: 36vw;
    padding-bottom: 23vw;
    height: auto;
  }
  .butt_sm {
    position: absolute;
    width: 28vw;
    top: 136vw;
    left: 32vw;
    cursor: pointer;
    animation: shake 2s infinite;
  }
  .youtubeBot1 {
    width: 90vw;
    height: 60vw;
    border-radius: 0 !important;
  }
  .closeme {
    width: 7vw;
    margin-bottom: 4vw;
    margin-left: 80vw;
  }
  .header_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  .header_title span {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 600;
    font-size: 6vw;
    width: fit-content;
    display: block;
    margin-left: 1vw;
    margin-bottom: 1.6vw;
  }
  .header_title h1 {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 700;
    font-size: 18vw;
    margin-top: 1vw;
  }
  .header_bot_body {
    width: 90vw;
  }
  .bot_sub {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 900;
    font-size: 9.5vw;
    margin-top: 0;
    text-align: center;
    color: #a8ff00;
  }
  .x1_bot {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 700;
    font-size: 6.5vw;
    margin: 0 auto;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(142, 0, 253, 0.8) 28.23%,
        rgba(142, 0, 253, 0) 100%
      ),
      radial-gradient(
          94.78% 94.78% at 49.32% 5.22%,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0) 49.95%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          65.67% 65.67% at 52.27% 146.27%,
          rgba(0, 0, 0, 0.2) 62.89%,
          rgba(0, 0, 0, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #561ad3;
    border-radius: 11.5vw;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3.5vw 8.5vw;
    color: #ffffff;
    margin-top: 4.5vw;
    text-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  }
  .product {
    color: #fff;
    font-size: 5.6vw;
    font-weight: 900;
    margin-top: 109.5vw;
  }
  .bot_list li {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 4.05vw;
    width: 55vw;
    margin-bottom: 2vw;
  }
  .bot_list li img {
    margin-right: 2vw;
    width: 7.2vw;
  }
  .bot_list {
    list-style-type: none;
    margin-top: 4vw;
  }
  .bot_buttons {
    margin-top: 2.3vw;
    display: flex;
    flex-direction: column-reverse;
  }
  .bot_buttons div {
    flex-direction: column;
  }
  .link_instruction {
    background: rgba(31, 29, 33, 0.73);
    border: 2px solid #a8ff00;
    box-shadow: 0px 0px 26px rgb(168 255 0 / 20%);
    border-radius: 23.8vw;
    padding: 5vw 4vw;
    color: #fff;
    display: block;
    margin-left: 1.5vw;
    font-size: 6.2vw;
    text-align: center;
    width: 70vw;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 7vw;
  }
  .bot_buttons div a {
    background: #a8ff00;
    box-shadow: 0px 0px 26px rgb(168 255 0 / 38%);
    border-radius: 26.8vw;
    font-size: 6.2vw;
    padding: 5vw 4vw;
    color: black;
    display: block;
    margin: 0 auto;
    margin-top: 8vw;
    width: 70vw;
    font-weight: 700;
    text-align: center;
  }
  .bot_buttons div {
    display: flex;
    margin-right: 0;
  }
  .youtubeBot {
    position: absolute;
    width: 92vw;
    left: 3.5vw;
    height: 51.5vw;
    bottom: 29.8vw;
    border-radius: 0 !important;
  }
  .razd {
    width: 90vw;
  }
}
@keyframes shake {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.12);
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
