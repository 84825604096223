.promotion_presentation_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 15vw;
    background: url(../../../img/databasebackground.png);
    background-size: cover;
}
.promotion_presentation_title{
    font-size: 10vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 3vw;
}
.presentationScreen{
    width: 100%;
    margin-bottom: 5vw;
}
.arrows_box{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;
}
.arrows_box img{
    width: 9.5vw;
    margin: 0 5vw;
}
.arrows_box p{
    font-size: 4.5vw;
}
.full_screen_button_presentation{
    margin-top: 5vw;
    width: 100%;
    border-radius: 3vw;
    border: none;
    outline: none;
    padding: 4.5vw 0;
    font-size: 5vw;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.1vw;
    color: #4B4359;
    background: linear-gradient(94.5deg, #EAE5F3 26.56%, #EAE5F3 100.53%),linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
}
.full_screen_button_presentation:link {
  color: #4B4359;
}
.full_screen_button_presentation:visited {
  color: #4B4359;
}
.full_screen_button_presentation:hover {
  color: #4B4359;
}
.full_screen_button_presentation:active {
  color: #4B4359;
}