@font-face {
  font-family: "RoadRadio";
  src: url("./fonts/RoadRadio.ttf");
}
@font-face {
  font-family: "TerminatorGenisys";
  src: url("./fonts/TerminatorGenisys.ttf");
}
* {
  font-family: "RoadRadio";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: 0.5s;
}
.animated_page {
  width: 100%;
  height: 100%;
}
body {
  background: #ffffff;
}
input,
button {
  outline: none;
}
*:before,
*:after {
  box-sizing: border-box;
  color: #1b1b1b;
}
#root {
  background: #ffffff;
}
.App {
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-decoration: none;
  transform-style: preserve-3d;
  perspective: 500px;
}
.scroll_down_container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: 0.5s;
}
.scroll_down {
  background: rgba(169, 130, 250, 0.28);
  width: 50%;
  text-align: center;
  color: rgba(108, 87, 153, 1);
  font-size: 4.2vw;
  font-weight: 900;
  padding: 2vw;
  border-radius: 6vw 6vw 0 0;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: #1b1b1b;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  background: aliceblue;
}

.preloader_background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  animation: backScale 0.7s forwards;
  animation-delay: 7s;
}
.preloader_logo {
  width: 70vw;
  position: absolute;
  opacity: 0;
  animation: scaleLogo 1s forwards ease-out;
  animation-delay: 4.5s;
}
.preloader_title {
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #7500ff;
  transition: 0s;
  height: 100%;
  position: relative;
}
.preloader_title p {
  font-size: 20vw;
  font-weight: 900;
  position: absolute;
  font-family: "TerminatorGenisys";
  transform-origin: center;
  opacity: 0;
  text-shadow: rgba(117, 0, 255, 0.6) 1px 0 10px;
}
.first_word {
  animation: scale1 1.5s forwards ease-in-out;
  top: 50%;
  left: 50%;
}
.sec_word {
  animation: scale2 1.5s forwards ease-in-out;
  animation-delay: 1.5s;
  top: 50%;
  transform: translateY(-50%);
}
.third_word {
  animation: scale3 1.5s forwards ease-in-out;
  animation-delay: 3s;
  top: 50%;
  left: 0%;
}
@keyframes scale1 {
  0% {
    opacity: 0.2;
    transform: scale(10);
    filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
    filter: blur(0);
  }

  100% {
    opacity: 0;
    transform: rotateX(90deg) translate(-50%, -50%);
    filter: blur(10px);
  }
}
@keyframes scale2 {
  0% {
    opacity: 0.2;
    transform: scale(10);
    filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    transform: scale(1) translateY(-50%);
    filter: blur(0);
  }

  100% {
    opacity: 0;
    transform: rotateX(90deg) translateY(-50%);
    filter: blur(10px);
  }
}
@keyframes scale3 {
  0% {
    opacity: 0.2;
    transform: scale(10);
    filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    transform: scale(1) translate(50%, -50%);
    filter: blur(0);
  }

  100% {
    opacity: 0;
    transform: rotateX(90deg) translate(50%, -50%);
    filter: blur(10px);
  }
}
@keyframes scaleLogo {
  0% {
    opacity: 0.2;
    transform: scale(10);
    filter: blur(10px);
  }

  50%,
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}
@keyframes backScale {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dash {
  90% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 3000;
    stroke-dashoffset: 0;
  }
}

.light {
  position: absolute;
  width: 0px;
  opacity: 0.75;
  background-color: #7500ff;
  box-shadow: #7500ff 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}
.x1 {
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.x2 {
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3 {
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  left: -15%;
}

.x4 {
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5 {
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6 {
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  left: -81%;
}

.x7 {
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  animation-delay: 5.5s;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

@-webkit-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
@-moz-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
@-o-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
