.want_to_join_containers{
    width: 100vw;
    height: 100%;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 12vw;
}
.want_to_join_containersBig{
    width: 100vw;
    height: fit-content;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 12vw;
}
.want_to_join_parts_title{
    font-size: 8.8vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 5vw;
    color: #1B1B1B;
}
.want_to_join_club_balance_title{
    font-size: 8.4vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 5vw;
    color: #1B1B1B;
}
.want_to_join_videoPlaceholder{
    width: 85%;
}
.want_to_join_parts_undertitle{
    width: 85%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.35vw;
    line-height: 3.6vw;
    margin: 4.5vw 0;
}
.want_to_join_register_steps{
    width: 100%;
    display: flex;
    margin-bottom: 5.5vw;
    align-items: center;
}
.want_to_join_register_steps:last-of-type{
    margin-bottom: 0vw;
}
.want_to_join_register_steps img{
    width: 8vw;
    margin-right: 3vw;
}
.want_to_join_register_steps p{
    font-size: 3.1vw;
    font-weight: 900;
    letter-spacing: 0.1vw;
}
.want_to_join_moneyBag{
    width: 11vw;
    margin-bottom: 2vw;
}
.want_to_join_common_text{
    width: 90%;
    text-align: center;
    font-size: 3.3vw;
}
.want_to_join_containersFAQ{
    font-size: 3.1vw;
    line-height: 3.3vw;
}
.question_box{
    letter-spacing: -0.1vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 5vw;
    width: 100%;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    border-radius: 3vw;
}
.question_box_getmoney{
    letter-spacing: -0.1vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 3vw;
    width: 100%;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    border-radius: 3vw;
}
.white_question{
    background: #ffffff;
    border-radius: 3vw 3vw 0 0;
    text-align: center;
    padding: 2vw 3vw;
    width: 100%;
}
.purple_question{
    font-size: 3.3vw;
    width: 100%;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
    text-align: center;
    padding: 2vw 3vw;
}
.purple_question_getmoney{
    font-size: 3.5vw;
    width: 100%;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
    text-align: center;
    padding: 1.5vw 3vw;
}
.white_answer_getmoney{
    width: 100%;
    text-align: center;
    padding: 2.5vw 2vw;
    letter-spacing: -0.1vw;
    font-size: 2.7vw;
    border-radius: 0 0 3vw 3vw;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.green_answer{
    width: 100%;
    text-align: center;
    padding: 1.6vw;
    color: #ffffff;
    background: rgba(59, 225, 0, 1);
    border-radius: 0 0 3vw 3vw;
}
.white_answer{
    width: 100%;
    text-align: center;
    padding: 1.6vw;
    font-size: 3.3vw;
    border-radius: 0 0 3vw 3vw;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.white_answer p{
    width: 80%;
}
.white_answer_button{
    padding: 2vw 5vw;
    margin-right: 2vw;
    border-radius: 3vw;
    background: rgba(59, 225, 0, 1);
    color: #ffffff;
}
  .white_answer_button:link {
    color: #ffffff;
  }
  .white_answer_button:visited {
    color: #ffffff;
  }
  .white_answer_button:hover {
    color: #ffffff;
  }
  .white_answer_button:active {
    color: #ffffff;
  }
.white_answer_with_button{
    width: 100%;
    text-align: right;
    padding: 1.6vw;
    font-size: 3.3vw;
    border-radius: 0 0 3vw 3vw;
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.white_answer_with_button_p{
    margin-right: 5vw;
}
.want_to_join_bold_text{
    font-weight: 900;
    text-align: center;
    font-size: 3.7vw;
    margin-bottom: 3vw;
}
.want_to_join_bold_text:last-of-type{
    margin-top: 5vw;
}