.promotion_page_container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    padding: 12vw 5vw 4vw 5vw;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
}
.promotion_page_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    text-align: center;
}
.row_promotion_page_buttons{
    display: flex;
    width: 100%;
    margin-top: 4.2vw;
    justify-content: space-between;
}
.row_promotion_page_buttons:last-of-type{
    padding-bottom: 5vw;
}
/* .promotion_page_container .step_title{
    font-size: 10vw;
} */