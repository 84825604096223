.ultron_resources_container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11vw 5vw 5vw 5vw;
    /* background: url(../../img/welcomeBackground.png);
    background-size: cover; */
}
.resources_title{
    align-self: flex-start;
    font-size: 10vw;
    line-height: 8vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 15vw;
}
.resources_main_img_box{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 74%;
    background: #ffffff;
    padding-top: 23vw;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    margin-bottom: 6vw;
}
.resources_main_img_box p{
    color: #000000;
}
.resources_team_project{
    font-size: 3.6vw;
}
.resources_team_amount{
    font-weight: 900;
    font-size: 4.6vw;
    margin-bottom: 3vw;
}
.resources_main_img_box img{
    position: absolute;
}
.resourcesImg{
    top: -30%;
    width: 30vw;
    transform: rotateZ(0deg) scale(0);
}
.resourcesLines{
    width: 66vw;
    bottom: 0;
}
.resources_right_year{
    width: 38vw;
    height: 1vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.down_parts_row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 4vw;
}