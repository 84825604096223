body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "RoadRadio";
  src: url(/static/media/RoadRadio.f46c371e.ttf);
}
@font-face {
  font-family: "TerminatorGenisys";
  src: url(/static/media/TerminatorGenisys.8ca71063.ttf);
}
* {
  font-family: "RoadRadio";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: 0.5s;
}
.animated_page {
  width: 100%;
  height: 100%;
}
body {
  background: #ffffff;
}
input,
button {
  outline: none;
}
*:before,
*:after {
  box-sizing: border-box;
  color: #1b1b1b;
}
#root {
  background: #ffffff;
}
.App {
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 500px;
          perspective: 500px;
}
.scroll_down_container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: 0.5s;
}
.scroll_down {
  background: rgba(169, 130, 250, 0.28);
  width: 50%;
  text-align: center;
  color: rgba(108, 87, 153, 1);
  font-size: 4.2vw;
  font-weight: 900;
  padding: 2vw;
  border-radius: 6vw 6vw 0 0;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: #1b1b1b;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html {
  background: aliceblue;
}

.preloader_background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: backScale 0.7s forwards;
          animation: backScale 0.7s forwards;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.preloader_logo {
  width: 70vw;
  position: absolute;
  opacity: 0;
  -webkit-animation: scaleLogo 1s forwards ease-out;
          animation: scaleLogo 1s forwards ease-out;
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}
.preloader_title {
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #7500ff;
  transition: 0s;
  height: 100%;
  position: relative;
}
.preloader_title p {
  font-size: 20vw;
  font-weight: 900;
  position: absolute;
  font-family: "TerminatorGenisys";
  -webkit-transform-origin: center;
          transform-origin: center;
  opacity: 0;
  text-shadow: rgba(117, 0, 255, 0.6) 1px 0 10px;
}
.first_word {
  -webkit-animation: scale1 1.5s forwards ease-in-out;
          animation: scale1 1.5s forwards ease-in-out;
  top: 50%;
  left: 50%;
}
.sec_word {
  -webkit-animation: scale2 1.5s forwards ease-in-out;
          animation: scale2 1.5s forwards ease-in-out;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.third_word {
  -webkit-animation: scale3 1.5s forwards ease-in-out;
          animation: scale3 1.5s forwards ease-in-out;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  top: 50%;
  left: 0%;
}
@-webkit-keyframes scale1 {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) translate(-50%, -50%);
            transform: rotateX(90deg) translate(-50%, -50%);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
}
@keyframes scale1 {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    -webkit-transform: scale(1) translate(-50%, -50%);
            transform: scale(1) translate(-50%, -50%);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) translate(-50%, -50%);
            transform: rotateX(90deg) translate(-50%, -50%);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
}
@-webkit-keyframes scale2 {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-50%);
            transform: scale(1) translateY(-50%);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) translateY(-50%);
            transform: rotateX(90deg) translateY(-50%);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
}
@keyframes scale2 {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-50%);
            transform: scale(1) translateY(-50%);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) translateY(-50%);
            transform: rotateX(90deg) translateY(-50%);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
}
@-webkit-keyframes scale3 {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) translate(50%, -50%);
            transform: rotateX(90deg) translate(50%, -50%);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
}
@keyframes scale3 {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  85% {
    opacity: 1;
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) translate(50%, -50%);
            transform: rotateX(90deg) translate(50%, -50%);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
}
@-webkit-keyframes scaleLogo {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0);
            filter: blur(0);
  }
}
@keyframes scaleLogo {
  0% {
    opacity: 0.2;
    -webkit-transform: scale(10);
            transform: scale(10);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  50%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0);
            filter: blur(0);
  }
}
@-webkit-keyframes backScale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(10);
            transform: scale(10);
    opacity: 0;
  }
}
@keyframes backScale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(10);
            transform: scale(10);
    opacity: 0;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes dash {
  90% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 3000;
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  90% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 3000;
    stroke-dashoffset: 0;
  }
}

.light {
  position: absolute;
  width: 0px;
  opacity: 0.75;
  background-color: #7500ff;
  box-shadow: #7500ff 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}
.x1 {
  -webkit-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.x2 {
  -webkit-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3 {
  -webkit-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  left: -15%;
}

.x4 {
  -webkit-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5 {
  -webkit-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6 {
  -webkit-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  left: -81%;
}

.x7 {
  -webkit-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

@-webkit-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}
@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    top: 0vh;
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  100% {
    top: -100vh;
    opacity: 0;
  }
}

.percentfill_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 2.5vw;
  overflow: hidden;
  border-radius: 2vw;
  margin-bottom: 5vw;
}
.percentinfo_bar{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(119, 252, 132, 1);
  height: 100%;
  z-index: 1;
  border-radius: 5vw 0 0 5vw;
}
.percentfill_box > p{
  font-size: 4vw;
  z-index: 2;
  padding: 0.25vw 0;
}
.error_popup_box{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 20;
  background: rgba(217, 217, 217, 0.5);
  position: absolute;
  transition: 0.5s;
}
.error_popup{
  background: #ffffff;
  width: 75%;
  padding: 4vh 3vw;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 6px 30px 0px rgba(79, 61, 130, 0.4);
  border-radius: 4vw;
}
.error_popup_title{
  text-align: center;
  font-weight: 900;
  font-size: 6.5vw;
  margin-bottom: 4vh;
}
.error_popup_text{
  text-align: center;
  font-weight: 900;
  font-size: 5.5vw;
  margin-bottom: 5vh;

}
.error_popup_button{
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  color: #ffffff;
  font-size: 3.9vw;
  padding: 1.2vh 5vw;
  border-radius: 2vw;
}

.welcome_popup_box{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 20;
  background: rgba(217, 217, 217, 0.5);
  position: fixed;
  transition: 0.5s;
}
.welcome_popup{
  background: #ffffff;
  width: 75%;
  padding: 4vh 3vw;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 6px 30px 0px rgba(79, 61, 130, 0.4);
  border-radius: 4vw;
}
.welcome_popup_title{
  text-align: center;
  font-weight: 900;
  font-size: 6.5vw;
  margin-bottom: 4vh;
}
.welcome_popup_text{
  text-align: center;
  font-weight: 900;
  font-size: 5vw;
  margin-bottom: 5vh;

}
.welcome_popup_button{
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  color: #ffffff;
  font-size: 3.9vw;
  padding: 1.2vh 5vw;
  border-radius: 2vw;
}
.regdone_popup_title{
  text-align: center;
  font-weight: 900;
  font-size: 6.5vw;
  margin-bottom: 2vh;
}
.regdone_popup_text1{
  text-align: center;
  font-size: 3.5vw;
  margin-bottom: 1.5vh;
}
.regdone_popup_text2{
  text-align: center;
  font-weight: 900;
  font-size: 4vw;
  margin-bottom: 3.5vh;
}



.registration_pass_page_container{
  padding: 5vw 3.5vw 5vw 3.5vw;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: url(/static/media/welcomeBackground.61dbf1fc.png);
  background-size: cover;
}
.inputs_box{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registration_page_container {
  padding: 5vw 3.5vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(/static/media/welcomeBackground.61dbf1fc.png);
  background-size: cover;
  overflow-y: scroll;
}
.help {
  position: absolute;
  right: 3.5vw;
  width: auto;
  top: 50vw;
  text-align: right;
}
.help img {
  width: 12vw;
}
.help p {
  margin-top: -2vw;
  font-size: 3.5vw;
  font-weight: bold;
}
.videoplaceholder{
  width: 75%;
  margin-bottom: 0.5vh;
}
.videoplaceholder_bottom{
  font-size: 4vw;
  margin-bottom: 7vh;
}
.registration_title {
  font-family: "TerminatorGenisys";
  text-align: center;
  font-size: 8vw;
  margin-bottom: 3vw;
}
.registration_info {
  text-align: center;
  letter-spacing: 0.05vw;
  font-size: 4.3vw;
}
.ultron_logo {
  width: 73vw;
}
.short_pass {
  position: absolute;
  font-size: 3.2vw;
  right: 0vw;
  color: red;
  top: -4vw;
}
.full_inputs {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.referal_id_input {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.invited_id_box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.id_mavie {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.referal_link_mavie {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.referal_short {
  position: absolute;
  top: -4vw;
  left: 0vw;
  color: red;
  font-size: 3.2vw;
}
.registration_page_container > input:last-of-type {
  margin-bottom: 4.8vw;
}

.button_registration_on {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.5vw;
  padding: 3.4vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}
.button_registration_on:hover {
  background: #eae5f3;
  color: #702ef7;
}
.button_back_to_login {
  width: 100%;
  padding: 3.4vw 0;
  border-radius: 2vw;
  font-size: 5.5vw;
  text-align: center;
  background: #eae5f3;
  border: 0.2vw solid #eae5f3;
  transition: 0.5s;
}
.button_back_to_login:hover {
  background: #702ef7;
  color: #eae5f3;
}
.input_tel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tel_inputs,
.PhoneInput {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.tel_inputs:last-of-type {
  width: 100%;
}
.already_exists_phone {
  position: absolute;
  font-size: 3.2vw;
  left: 0vw;
  color: red;
  top: -4vw;
}
.first_input {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.05s;
}
.PhoneInputInput {
  background: none;
  border: none;
  font-weight: 900;
  font-size: 4vw;
}
.sec_input {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.1s;
}
.third_input {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.1s;
}
.fourth_input {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.15s;
}
.button_registration_on {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.2s;
}
.button_back_to_login {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.25s;
}

.registration_complete_container {
  width: 100vw;
  height: 100%;
  background-color: #ffffff;
  background: url(/static/media/welcomeBackground.61dbf1fc.png);
  background-size: cover;
  padding: 5vh 3.5vw 1vw 3.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
}
.registration_complete_title {
  font-family: "TerminatorGenisys";
  font-size: 7.5vw;
  letter-spacing: 0.1vw;
  line-height: 5.5vw;
  text-align: center;
  margin-bottom: 4vh;
}
.sec_step_reg_mavie_input {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.button_back_to_login_completed {
  width: 90%;
  position: absolute;
  bottom: 5vw;
  padding: 3.4vw 0;
  border-radius: 2vw;
  font-size: 5.5vw;
  text-align: center;
  background: #eae5f3;
  border: 0.2vw solid #eae5f3;
  transition: 0.5s;
}
.sec_step_reg_send_it {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6vw;
  transition: 0.5s;
  opacity: 0.5;
}
.dont_have_mavieID_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 3vw;
}
.dont_have_mavieID_title {
  background: linear-gradient(
      94.5deg,
      rgba(86, 26, 211, 0.6) 26.56%,
      rgba(83, 0, 253, 0.48) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  font-weight: 500;
  letter-spacing: 0.1vw;
  border-radius: 3vw 3vw 0 0;
  color: #ffffff;
  text-align: center;
  width: 100%;
  padding: 3vw 3vw 2vw 3vw;
  font-size: 5vw;
}
.dont_have_mavieID_text {
  text-align: center;
  font-size: 4.2vw;
  padding: 3vw 5vw;
}
.dont_have_mavieID_inputs_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 3vw 3vw;
  padding: 0 3vw;
}
.dont_have_mavieID_inviter_phone_name_box {
  width: 100%;
  display: flex;
  margin-bottom: 2vw;
  position: relative;
}
.dont_have_mavieID_inviter_phone_name_box:last-of-type {
  margin-bottom: 4vw;
}
.secStepRegIconsBox {
  background: rgba(171, 132, 253, 1);
  padding: 3.3vw 2vw;
  border-radius: 2vw 0 0 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secStepRegIconsBoxPhone {
  background: rgba(171, 132, 253, 1);
  padding: 3.6vw 2vw;
  border-radius: 2vw 0 0 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secStepRegIcons {
  width: 3.75vw;
}
.secStepRegIconsPhone {
  width: 4vw;
}
.dont_have_mavieID_inviter_name_phone {
  width: 100%;
  border-radius: 0 3vw 3vw 0;
  border: none;
  outline: none;
  background: #eae5f3;
  padding-left: 3.5vw;
  font-size: 4vw;
}
.dont_have_mavieID_inviter_phone_copy_button_box {
  display: flex;
  position: absolute;
  right: 2vw;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
.dont_have_mavieID_inviter_phone_copy_button {
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border: none;
  outline: none;
  color: #ffffff;
  padding: 1.5vw 0;
  font-size: 3.1vw;
  padding-left: 4vw;
  width: 32vw;
  border-radius: 2vw;
}
.structuresListCopy {
  position: absolute;
  width: 4vw;
  right: 1vw;
}
.videoPlaceholder_reg_sec_step {
  width: 85%;
  margin: 6vw 0 4vw 0;
}
.video_how_to_call_to_inviter {
  text-align: center;
  font-weight: 700;
  font-size: 4.4vw;
  line-height: 4.5vw;
  margin-bottom: 5vw;
  width: 100%;
}
.video_how_to_call_to_inviter > span {
  color: rgba(86, 26, 211, 1);
}
.call_tech_support {
  width: 100%;
  text-align: center;
  font-size: 3.8vw;
  margin-bottom: 5vw;
}
.call_tech_support > span {
  color: rgba(88, 231, 0, 1);
}
.send_it_reg_kurator{
  width: 100%;
  border: 0.2vw solid;
  background: linear-gradient(0deg, #B890FF, #B890FF),
  linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6vw;
  transition: 0.5s;

}
.send_it_reg_sec_step {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6vw;
  transition: 0.5s;
}
.send_it_reg_sec_step:link {
  color: #ffffff;
}
.send_it_reg_sec_step:visited {
  color: #ffffff;
}
.send_it_reg_sec_step:hover {
  color: #ffffff;
}
.send_it_reg_sec_step:active {
  color: #ffffff;
}

.hidden_thank_for_registration_box_background {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: rgba(18, 15, 26, 0.34);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  /* height: 143vh; */
  align-items: center;
  height: 100%;
}
.hidden_thank_for_registration_box {
  width: 87%;
  background: #ffffff;
  /* margin-top: 35vh; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4vw;
  padding: 12vw 6vw;
  box-shadow: 2px 4px 19px 5px rgba(112, 46, 247, 0.35);
}
.hidden_thank_for_registration_title {
  text-align: center;
  font-size: 8vw;
  font-weight: 900;
  margin-bottom: 2vw;
}
.hidden_thank_for_registration_text {
  text-align: center;
  font-size: 5vw;
  font-weight: 900;
  margin-bottom: 6vw;
}
.hidden_thank_for_registration_button {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}
.hidden_thank_for_registration_button:link {
  color: #ffffff;
}
.hidden_thank_for_registration_button:visited {
  color: #ffffff;
}
.hidden_thank_for_registration_button:hover {
  color: #ffffff;
}
.hidden_thank_for_registration_button:active {
  color: #ffffff;
}
.registration_wait_container {
  opacity: 0;
  display: flex;
  pointer-events: none;
  width: 100vw;
  height: 100%;
  background: url(/static/media/welcomeBackground.61dbf1fc.png);
  background-size: 100%;
  padding: 5vh 3.5vw 1vw 3.5vw;
  flex-direction: column;
  align-items: center;
  position: absolute;
  justify-content: center;
  box-shadow: 2px 4px 19px 0px rgba(0, 83, 139, 0.21);
}
.bottom_padding{
  padding: 3vw;
}

.help_button_on_register {
  color: #1b1b1b;
  position: absolute;
  right: 3.5vw;
  top: 40vh;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 1s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.help_button_on_register:link {
  color: #1b1b1b;
}
.help_button_on_register:visited {
  color: #1b1b1b;
}
.help_button_on_register:hover {
  color: #1b1b1b;
}
.help_button_on_register:active {
  color: #1b1b1b;
}
.help_button_on_register > img {
  width: 8.5vw;
  border-radius: 50%;
  box-shadow: 0px 1px 8px 0px rgba(27, 104, 0, 0.25);
}
.help_button_on_register > p {
  font-size: 3.5vw;
  font-weight: 900;
  margin-top: 1vw;
}
.login_page_container {
  padding: 5vw 3.5vw 5vw 3.5vw;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: url(/static/media/welcomeBackground.61dbf1fc.png);
  background-size: cover;
}
.login_title {
  font-family: "TerminatorGenisys";
  font-size: 8vw;
  margin-bottom: 3vw;
  text-align: center;
}
.login_info {
  text-align: center;
  letter-spacing: 0.05vw;
  font-size: 4.3vw;
}
.ultron_logo {
  -webkit-transform: rotateZ(0deg) scale(0);
          transform: rotateZ(0deg) scale(0);
  width: 73vw;
  margin: -8vw 0 -6vw 0;
}
.login_page_container > input {
  width: 100%;
  padding: 4vw 2vw 4vw 5vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.05s;
}
.login_page_container > input:last-of-type {
  margin-bottom: 8vw;
  transition-delay: 0.1s;
}
.button_login_on_box {
  width: 100%;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.15s;
  margin-bottom: 10vw;
}
.button_login_on {
  width: 100%;
  border: none;
  font-size: 5.5vw;
  padding: 3.6vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
}
.button_login_on:hover {
  background: #eae5f3;
  color: #702ef7;
}
.forgot_password {
  background: none;
  border: none;
  font-size: 3.8vw;
  align-self: flex-start;
  margin-top: 3vw;
  margin-bottom: 4vw;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.2s;
}
.button_go_to_registration {
  width: 100%;
  padding: 3.6vw 0;
  border-radius: 2vw;
  font-size: 5.5vw;
  text-align: center;
  background: linear-gradient(0deg, #b891ff, #b891ff),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  color: #ffffff;
  border: none;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.25s;
}
.button_go_to_registration:hover {
  background: #702ef7;
  color: #eae5f3;
}
.ultron_text_logo {
  margin-bottom: 5vw;
  margin-top: auto;
  font-size: 7vw;
  font-weight: 900;
  letter-spacing: 0.6vw;
  color: #23016a;
}
.help_button_on_login {
  color: #1b1b1b;
  position: absolute;
  right: 3.5vw;
  top: 3vw;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 1s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.help_button_on_login:link {
  color: #1b1b1b;
}
.help_button_on_login:visited {
  color: #1b1b1b;
}
.help_button_on_login:hover {
  color: #1b1b1b;
}
.help_button_on_login:active {
  color: #1b1b1b;
}
.help_button_on_login > img {
  width: 8.5vw;
  border-radius: 50%;
  box-shadow: 0px 1px 8px 0px rgba(27, 104, 0, 0.25);
}
.help_button_on_login > p {
  font-size: 3.5vw;
  font-weight: 900;
  margin-top: 1vw;
}

.button_continue_login {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}
.button_continue_login:hover {
  background: #eae5f3;
  color: #702ef7;
}
.loginpass_popup_text {
  text-align: center;
  font-weight: 900;
  font-size: 4.5vw;
  margin-bottom: 3.5vh;
}

.welcome_page_container {
  padding: 4.5vw 5vw 5vw 5vw;
  padding-top: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(/static/media/fullfon.51e89401.png);
  background-size: cover;
  overflow-y: scroll;
}
.ultronLogo {
  width: 70vw;
  margin-top: -9vw;
  margin-bottom: -7vw;
  -webkit-transform: rotateZ(0deg) scale(0);
          transform: rotateZ(0deg) scale(0);
}
.welcome_title {
  text-align: center;
  font-family: "TerminatorGenisys";
  font-size: 9.5vw;
  line-height: 8.5vw;
  text-transform: uppercase;
  font-weight: 900;
  position: fixed;

  width: 100vw;
  left: 0vw;

  padding: 6vw 0;
  z-index: 2000;
  top: 2vw;
  transition: 1s;
}
.model {
  opacity: 0;
  transition: 0.5s;
}
.welcomeImg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22vw;
}
.welcomeImg_img {
  position: absolute;
  width: 53vw;
  z-index: 10;
  margin-top: 3vw;
}
.welcomeImg_blur {
  z-index: 5;
  width: 31vw;
  height: 31vw;
  position: absolute;
  background: rgba(230, 160, 255, 0.88);
  -webkit-filter: blur(20.5px);
          filter: blur(20.5px);
  margin-top: 3vw;
}
.welcomeImg_text {
  z-index: 2;
  position: absolute;
  color: #eae5f3;
  font-size: 16.5vw;
  font-family: "TerminatorGenisys";
}
.welcome_links_box {
  margin-top: 4vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4vw;
}
.database_link {
  background: #ffffff;
  border-radius: 3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.5vw;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.05s;
}
.database_link:last-of-type {
  margin-bottom: 0;
}
.welcome_links_left_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 56%;
}
.database_link_img {
  width: 30vw;
  margin-bottom: 1vw;
  margin-left: -6vw;
  -webkit-animation: dataBaseImg 5s infinite ease-in-out;
          animation: dataBaseImg 5s infinite ease-in-out;
}
.database_link_img1 {
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.database_link_img2 {
  -webkit-animation-delay: 9.5s;
          animation-delay: 9.5s;
}
@-webkit-keyframes dataBaseImg {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  12.5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes dataBaseImg {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  12.5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  25% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.database_link p {
  font-size: 4vw;
  font-weight: 900;
  text-align: center;
  margin-bottom: 3.5vw;
}
.referal_box_welcome {
  position: relative;
  width: 100%;
  height: 24.5vw;
  background: #ffffff;
  margin-bottom: 4.5vw;
  border-radius: 3vw;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.referal_box_welcome2 {
  position: relative;
  width: 100%;
  height: 24.5vw;
  background: #ffffff;
  border-radius: 3vw;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.referal_box_welcome2 > p {
  text-align: center;
  color: #ffffff;
  font-size: 4.3vw;
  padding: 2.2vw 0;
  border-radius: 3vw 3vw 0 0;
  background: linear-gradient(
    303deg,
    rgba(86, 26, 211, 0.4) 0%,
    rgba(83, 0, 253, 0.6) 100%
  );
}
.referal_link_father {
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  color: #ffffff;
  padding: 2.5vw 11vw;
  border-radius: 2vw;
}
.referal_link_father:link {
  color: #ffffff;
}
.referal_link_father:visited {
  color: #ffffff;
}
.referal_link_father:hover {
  color: #ffffff;
}
.referal_link_father:active {
  color: #ffffff;
}
.referal_link_welcome2 {
  width: 100%;
  padding: 3vw 2.5vw;
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3vw;
}
.referal_link_father_text {
  font-size: 2.8vw;
  line-height: 2.9vw;
  margin-left: 1vw;
}
.referal_box_welcome > p {
  text-align: center;
  color: #ffffff;
  font-size: 5.1vw;
  padding: 2.2vw 0;
  border-radius: 3vw 3vw 0 0;
  background: linear-gradient(
    303deg,
    rgba(86, 26, 211, 0.4) 0%,
    rgba(83, 0, 253, 0.6) 100%
  );
}
.referal_link_welcome {
  width: 100%;
  padding: 3vw 2.5vw;
  background: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3vw;
}
.personal_ID {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3vw;
  width: 37vw;
  height: 9vw;
  border-radius: 1.5vw;
  background: #eae5f3;
  margin-right: 2vw;
}
.personal_ID p {
  font-weight: 900;
  font-size: 4.2vw;
}
.give_that_code {
  width: 48vw;
  font-weight: 900;
  font-size: 2.6vw;
}
.copyIcon {
  width: 6vw;
  margin-left: 2vw;
}
.welcome_links_column {
  display: flex;
  flex-direction: column;
  width: 39%;
}
.refDelay1 {
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.35s;
}
.konkurses_link {
  background: #ffffff;
  border-radius: 3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 2vw;
  margin-bottom: 3vw;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0);
          transform: scale(0);
  transition-delay: 0.1s;
}
.konkurses_link:last-of-type {
  margin-bottom: 0;
}
.konkurses_link_img {
  width: 18vw;
  margin-bottom: 2vw;
}
.konkurses_link p {
  font-size: 4.1vw;
  font-weight: 900;
  margin-bottom: 1.5vw;
}
.down_padding {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 5vw;
}
.hidden_input {
  opacity: 0;
  transition: 0s;
  width: 0;
  height: 0;
}
.arrow_animated_on_welcome_page_box {
  position: absolute;
  width: 6vw;
  height: 6vw;
  background: linear-gradient(
      0deg,
      rgba(86, 26, 211, 0.24),
      rgba(86, 26, 211, 0.24)
    ),
    linear-gradient(0deg, #702ef7, #702ef7);
  top: 4vw;
  right: 4vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: galochkaAnimation 4s infinite;
          animation: galochkaAnimation 4s infinite;
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.arrow_animated_on_welcome_page {
  width: 3vw;
}
@-webkit-keyframes galochkaAnimation {
  0% {
    -webkit-transform: scale(0) rotateY(0deg);
            transform: scale(0) rotateY(0deg);
  }
  15% {
    -webkit-transform: scale(1) rotateY(0deg);
            transform: scale(1) rotateY(0deg);
  }
  35% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
  45% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
  70% {
    -webkit-transform: scale(0) rotateY(360deg);
            transform: scale(0) rotateY(360deg);
  }
  100% {
    -webkit-transform: scale(0) rotateY(0deg);
            transform: scale(0) rotateY(0deg);
  }
}
@keyframes galochkaAnimation {
  0% {
    -webkit-transform: scale(0) rotateY(0deg);
            transform: scale(0) rotateY(0deg);
  }
  15% {
    -webkit-transform: scale(1) rotateY(0deg);
            transform: scale(1) rotateY(0deg);
  }
  35% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
  45% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
  70% {
    -webkit-transform: scale(0) rotateY(360deg);
            transform: scale(0) rotateY(360deg);
  }
  100% {
    -webkit-transform: scale(0) rotateY(0deg);
            transform: scale(0) rotateY(0deg);
  }
}
.personal_ID_input_box {
  display: flex;
  font-weight: 900;
  font-size: 4.5vw;
}
.personal_ID_input_box input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding-left: 2vw;
  font-size: 4.5vw;
  font-weight: 900;
}

.database_page_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    padding: 12vw 5vw 5vw 5vw;
    background: url(/static/media/fullfon.51e89401.png);
    background-size: cover;
    overflow-y: scroll;
}
.database_ultron_search_input_box{
    width: 57%;
    position: absolute;
    top: 3vw;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10;
}
.database_ultron_search_input_box_box{
    margin-right: 6vw;
}
.database_ultron_search_input{
    width: 45vw;
    border: none;
    outline: none;
    font-size: 4vw;
    padding-bottom: 0.75vw;
    border-bottom: 0.2vw solid #7230FA;
}
.database_ultron_search_input_img{
    width: 4vw;
}
.searcher_box{
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
    align-items: center;
    background: rgba(234, 229, 243, 0.9);
}
.searcher_result_box{
    background: #ffffff;
    width: 85%;
    border-radius: 3vw;
    padding: 4vw 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5vw;
}
.searcher_result_box:first-of-type{
    margin-top: 2.5vw;
}
.searcher_result_box > p{
    font-size: 4.4vw;
    width: 60%;
}
.searcher_result_box > button{
    padding: 2vw 2.5vw;
    color: #ffffff;
    font-size: 4.4vw;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 1.8vw;
    border: none;
}
.searcher_result_box > a{
    padding: 2vw 2.5vw;
    color: #ffffff;
    font-size: 4.4vw;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 1.8vw;
    border: none;
}
.searcher_result_box > a:link {
  color: #ffffff;
}
.searcher_result_box > a:visited {
  color: #ffffff;
}
.searcher_result_box > a:hover {
  color: #ffffff;
}
.searcher_result_box > a:active {
  color: #ffffff;
}




.database_page_title{
    font-size: 9.8vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    text-align: center;
}
.row_database_buttons{
    display: flex;
    width: 100%;
    margin-top: 4.2vw;
    justify-content: space-between;
}
.row_database_buttons:last-of-type{
    padding-bottom: 5vw;
}
/* .database_page_container .step_title{
    font-size: 10vw;
} */
.background_numbers{
    position: absolute;
    width: 12vw;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
}
.fourth_step_start_container{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 1.4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.fourth_step_start_container > img{
    width: 17.5vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.15s;
}
.fourth_step_start_containerBig{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 1.4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.fourth_step_start_containerBig > img{
    width: 22.5vw;
}
.fourth_step_title{
    width: 21vw;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 4.4vw;
    font-size: 3.6vw;
    letter-spacing: 0vw;
    font-weight: 900;
    text-align: center;
    position: relative;
}
.fourth_step_title img{
    width: 13vw;
    margin-top: 1vw;
}
.fourth_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}




.steps_block_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    padding: 4.3vw 4vw 4.3vw 6vw;
    margin-bottom: 1.75vh;
}
.steps_block_img{
    width: 26vw;
}
.steps_block_info_box{
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.steps_block_number{
    font-family: "RoadRadio";
    font-size: 24vw;
    line-height: 10vw;
    color: rgba(83, 0, 253, 0.1);
    margin-right: 1vw;
}
.steps_block_title{
    font-weight: 900;
    font-size: 5vw;
    margin-left: 0.7vw;
}
.arrow_button_back_container {
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  padding: 3vw;
  background: rgba(255, 255, 255, 1);
}
.arrow_button_back_container > div {
  display: flex;
  align-items: center;
}
.arrow_button_back_container > div > img {
  width: 9vw;
  margin-right: 1.5vw;
}
.arrow_button_back_container > p {
  font-size: 4vw;
  letter-spacing: -0.2vw;
}

.upper_icons_burger {
  position: absolute;
  width: 13.6vw;
  top: 2.5vw;
  right: 1vw;
  z-index: 10122222;
  transition: 0.5s;
}

.burger_menu_container {
  position: fixed;
  width: 0vw;
  height: 100vh;
  display: flex;
  top: 0vw;
  left: 0vw;
  flex-direction: column;
  background: rgba(0, 0, 0, 0);
  transition: 0.5s;
  align-self: flex-start;
  z-index: 10000;
  overflow-y: scroll;
}
.pod_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 0;
}
.pod_menu a {
  width: 60% !important;
  justify-content: flex-end !important;
  margin-top: 0 !important;
}
.burger_menu_links {
  position: fixed;
  display: none;
  pointer-events: all;
  flex-direction: column;
  width: 50vw;
  right: -50vw;
  height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
  padding: 20vw 5vw 5vw 7vw;
  transition: 0.5s;
}
.burger_menu_links a:first-of-type {
  margin-top: 8vw;
}
.burger_menu_links a,
.lir {
  width: 100%;
  background: #702ef7;
  margin-bottom: 4.5vw;
  padding: 2.7vw 0;
  border-radius: 1.5vw;
  font-size: 3.6vw;
  color: #ffffff;
  transition: 0.5s;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.right_links {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
}
.list_dot {
  margin: 0 2vw;
  width: 1.25vw;
  height: 1.25vw;
  border-radius: 10vw;
  background: #d0baff;
}
.ultronMiniLogo {
  width: 100%;
  margin-top: auto;
  padding-bottom: 10vw;
}

.where_to_start_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    align-items: center;
    padding: 0 5vw;
    padding-top: 12vw;
    padding-bottom: 5vw;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
}
.where_to_start_title{
    font-size: 9.6vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 2vw;
}
.where_to_start_undertitle{
    font-size: 4.1vw;
    width: 92%;
    text-align: center;
    margin-bottom: 3vw;
}
.start_part_img{
    width: 25vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
}
.start_part_imgBig{
    width: 23vw;
    margin-left: 1.7vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
}
.watch_video{
    width: 45vw;
    padding: 2.4vw 0;
    border: none;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    color: #ffffff;
    border-radius: 2vw;
    text-align: center;
    font-size: 3.6vw;
}
.watch_video:link {
    color: #ffffff;
  }
  .watch_video:visited {
    color: #ffffff;
  }
  .watch_video:hover {
    color: #ffffff;
  }
  .watch_video:active {
    color: #ffffff;
  } 
.video_size{
    width: "100%";
    height: "1vh";
}
.video_automatization_container{
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
  padding-top: 12vw;
  /* background: url(../../img/databasebackground.png);
  background-size: cover; */
}
.arrow_button_data_base_container{
    position: absolute;
    top: 4.4vw;
    left: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw 0;
}
.arrow_button_data_base_container > img{
    width: 9vw;
    margin-right: 1.5vw;
}
.arrow_button_data_base_container > p{
    font-size: 4vw;
    letter-spacing: -0.2vw;
}
.where_to_start_part_container {
  width: 100%;
  height: 26.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.2vw 0vw 2vw 4vw;
  background: #ffffff;
  margin-bottom: 3vw;
  border-radius: 3vw;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.where_to_start_part_container > img {
  width: 25vw;
}
@-webkit-keyframes scalingWhereToStart {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  7% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  14% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  21% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scalingWhereToStart {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  7% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  14% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  21% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.start_part_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1vw;
}
.start_part_info p {
  font-size: 3.7vw;
  width: 40vw;
  line-height: 3.7vw;
  margin-bottom: 2.5vw;
  color: #1b1b1b;
}
.start_part_info button {
  width: 45vw;
  padding: 2.4vw 0;
  border: none;
  background: linear-gradient(
    98deg,
    rgba(58, 205, 6, 1) 0%,
    rgba(88, 231, 0, 1) 100%
  );
  color: #ffffff;
  border-radius: 2vw;
  font-size: 3.6vw;
}
.start_part_right_year {
  width: 1.8vw;
  height: 14vw;
  border-radius: 4vw 0 0 4vw;
  background: linear-gradient(
    30deg,
    rgba(83, 5, 244, 0.8406) 0%,
    rgba(86, 26, 211, 1) 100%
  );
  opacity: 0.6;
  display: flex;
  opacity: 0;
  align-self: center;
}

.want_to_join_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    padding: 12vw 5vw 4vw 5vw;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
}
.want_to_join_title{
    font-size: 9.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    text-align: center;
}
.row_want_to_join_buttons{
    display: flex;
    width: 100%;
    margin-top: 4.2vw;
    justify-content: space-between;
}
.row_want_to_join_buttons:last-of-type{
    padding-bottom: 5vw;
}
/* .where_to_start_container .step_title{
    font-size: 10vw;
} */
.first_step_start_container{
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    border-radius: 2.5vw;
    padding: 5.5vw 0vw 5.5vw 4.5vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.first_step_start_container > img{
    width: 36vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    -webkit-animation: firstStepImg 6s infinite;
            animation: firstStepImg 6s infinite;
    -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
}
@-webkit-keyframes firstStepImg{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    6%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    12%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    18%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes firstStepImg{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    6%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    12%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    18%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
.first_step_start_containerWantJoin{
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    border-radius: 2.5vw;
    padding: 7.5vw 0vw 7.5vw 4.5vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.first_step_start_containerWantJoin > img{
    width: 36vw;
    -webkit-animation: firstStepImg 5s infinite;
            animation: firstStepImg 5s infinite;
    -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
}
.step_title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.step_title>img{
    width: 5vw;
    margin-top: 2vw;
    margin-bottom: 3vw;
}
.step_title>p{
    text-align: center;
    width: 45vw;
    line-height: 5.2vw;
    font-size: 5vw;
    font-weight: 900;
}
.first_step_start_right_year{
    width: 1.8vw;
    height: 16vw;
    border-radius: 4vw 0 0 4vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
    display: flex;
    align-self: center;
}
.second_step_start_container{
    background: #ffffff;
    width: 49vw;
    height: 100%;
    padding: 1vw 0vw 0vw 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.second_step_title{
    width: 100%;
    line-height: 4.4vw;
    font-size: 4vw;
    padding: 5vw 0;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.second_step_start_container > img{
    width: 24.5vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.05s;

}
.animatedImgPromotionTwo{
    -webkit-animation: SecondStepImg 6s infinite;
            animation: SecondStepImg 6s infinite;
    -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
}
@-webkit-keyframes SecondStepImg{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    18%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    24%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    30%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    36%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes SecondStepImg{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    18%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    24%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    30%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    36%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
.second_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.third_step_start_container{
    background: #ffffff;
    width: 37vw;
    height: 100%;
    padding-top: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.third_step_title{
    width: 100%;
    line-height: 4.4vw;
    font-size: 4vw;
    letter-spacing: 0vw;
    font-weight: 900;
    padding: 5vw 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.third_step_start_container > img{
    width: 23.5vw;
    margin-right: 1vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.1s;
}

@-webkit-keyframes ThirdStepImg{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    36%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    42%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    48%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    54%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes ThirdStepImg{
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    36%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    42%{
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    48%{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    54%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
.animatedImgPromotionThree{
    -webkit-animation: ThirdStepImg 6s infinite;
            animation: ThirdStepImg 6s infinite;
    -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
            animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-animation-delay: 5s;
            animation-delay: 5s;
}
.third_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.fifth_step_start_container{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 1.4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.fifth_step_start_container > img{
    width: 24.5vw;
    margin-bottom: 2.2vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.2s;
}
.fifth_step_start_containerBig{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 1.4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.fifth_step_start_containerBig > img{
    width: 20.5vw;
    margin-bottom: 2.2vw;
}
.fifth_step_title{
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 4.4vw;
    font-size: 3.6vw;
    letter-spacing: 0vw;
    font-weight: 900;
    text-align: center;
    position: relative;
}
.fifth_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.sixth_step_start_container{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 2.6vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.sixth_step_title{
    width: 21vw;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 4.4vw;
    font-size: 3.6vw;
    letter-spacing: 0vw;
    font-weight: 900;
    text-align: center;
    position: relative;
}
.sixth_step_start_container > img{
    width: 16.8vw;
    margin-bottom: 2.2vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.25s;
}
.sixth_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.promotion_page_container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    padding: 12vw 5vw 4vw 5vw;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
}
.promotion_page_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    text-align: center;
}
.row_promotion_page_buttons{
    display: flex;
    width: 100%;
    margin-top: 4.2vw;
    justify-content: space-between;
}
.row_promotion_page_buttons:last-of-type{
    padding-bottom: 5vw;
}
/* .promotion_page_container .step_title{
    font-size: 10vw;
} */
.ultron_resources_container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11vw 5vw 5vw 5vw;
    /* background: url(../../img/welcomeBackground.png);
    background-size: cover; */
}
.resources_title{
    align-self: flex-start;
    font-size: 10vw;
    line-height: 8vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 15vw;
}
.resources_main_img_box{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 74%;
    background: #ffffff;
    padding-top: 23vw;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    margin-bottom: 6vw;
}
.resources_main_img_box p{
    color: #000000;
}
.resources_team_project{
    font-size: 3.6vw;
}
.resources_team_amount{
    font-weight: 900;
    font-size: 4.6vw;
    margin-bottom: 3vw;
}
.resources_main_img_box img{
    position: absolute;
}
.resourcesImg{
    top: -30%;
    width: 30vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
}
.resourcesLines{
    width: 66vw;
    bottom: 0;
}
.resources_right_year{
    width: 38vw;
    height: 1vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.down_parts_row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 4vw;
}
.arrow_button_go_back_container{
    position: absolute;
    top: 4.4vw;
    left: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw 0;
}
.arrow_button_go_back_container > img{
    width: 9vw;
    margin-right: 1.5vw;
}
.arrow_button_go_back_container > p{
    font-size: 4vw;
    letter-spacing: -0.2vw;
}
.resources_down_part_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 24.5vw;
  border-radius: 2.5vw;
  overflow: hidden;
  box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
  margin: 0 2vw 3vw 2vw;
}
.down_part_title {
  width: 100%;
  text-align: center;
  background: linear-gradient(
    94.5deg,
    rgba(86, 26, 211, 0.6) 26.56%,
    rgba(83, 0, 253, 0.48) 100.53%
  );
  color: #ffffff;
  font-size: 2.6vw;
  height: 14vw;
  padding: 0 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.whitesquare_box {
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw 0;
  height: 16vw;
}
.whitesquare_box_img {
  width: 52%;
}
.bottom_view_button {
  background: linear-gradient(
    98deg,
    rgba(58, 205, 6, 1) 0%,
    rgba(88, 231, 0, 1) 100%
  );
  width: 100%;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 1.5vw 0;
  font-size: 3.2vw;
  text-align: center;
}
.bottom_view_button:link {
  color: #ffffff;
}
.bottom_view_button:visited {
  color: #ffffff;
}
.bottom_view_button:hover {
  color: #ffffff;
}
.bottom_view_button:active {
  color: #ffffff;
}

.birgesPage_container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11vw 5vw 5vw 5vw;
    background: url(/static/media/welcomeBackground.61dbf1fc.png);
    background-size: cover;
}
.birgesPage_title{
    align-self: flex-start;
    font-size: 10vw;
    line-height: 8vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 2vw;
}
.birgesImg{
    width: 50vw;
    margin-bottom: 3.5vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
}
.birges_row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vw;
}
.birges_partOne{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 57%;
    padding-top: 4vw;
    background: #ffffff;
    overflow: hidden;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
}
.birges_partTwo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 39%;
    padding-top: 4vw;
    background: #ffffff;
    overflow: hidden;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
}
.birges_partThree{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 31.5%;
    padding-top: 2vw;
    background: #ffffff;
    overflow: hidden;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
}
.birges_partOne img{
    width: 44vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.05s;
}
.birges_partTwo img{
    width: 28vw;
    margin-top: 2.7vw;
    margin-bottom: 3.2vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.1s;
}
.birgesImg3{
    width: 23.7vw;
    margin-bottom: 1vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.15s;
}
.birgesImg4{
    margin-top: 1vw;
    width: 23vw;
    margin-bottom: 5.2vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.2s;
}
.birgesImg5{
    margin-top: 4vw;
    width: 18vw;
    margin-bottom: 4.3vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.25s;
}
.part_title{
    color: #561AD3;
    font-weight: 900;
    font-size: 4.2vw;
}
.part_text{
    font-size: 3.1vw;
    color: #1B1B1B;
    margin-bottom: 3.5vw;
}
.birges_partOne a{
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 2vw 0;
    font-size: 3.5vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    text-align: center;
}
.birges_partOne a:link {
    color: #ffffff;
}
.birges_partOne a:visited {
    color: #ffffff;
}
.birges_partOne a:hover {
    color: #ffffff;
}
.birges_partOne a:active {
    color: #ffffff;
}
.birges_partTwo a{
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 2vw 0;
    font-size: 3.5vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    text-align: center;
}
.birges_partTwo a:link {
    color: #ffffff;
}
.birges_partTwo a:visited {
    color: #ffffff;
}
.birges_partTwo a:hover {
    color: #ffffff;
}
.birges_partTwo a:active {
    color: #ffffff;
}
.birges_partThree a{
    width: 100%;
    border: none;
    color: #ffffff;
    padding: 2vw 0;
    font-size: 3.5vw;
    margin-top: 2.5vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    text-align: center;
}
.birges_partThree a:link {
    color: #ffffff;
}
.birges_partThree a:visited {
    color: #ffffff;
}
.birges_partThree a:hover {
    color: #ffffff;
}
.birges_partThree a:active {
    color: #ffffff;
}
@-webkit-keyframes wiggle {
    0%, 2% {
      -webkit-transform: rotateZ(0);
              transform: rotateZ(0);
    }
    5% {
      -webkit-transform: rotateZ(-15deg);
              transform: rotateZ(-15deg);
    }
    7% {
      -webkit-transform: rotateZ(10deg);
              transform: rotateZ(10deg);
    }
    8% {
      -webkit-transform: rotateZ(-10deg);
              transform: rotateZ(-10deg);
    }
    10% {
      -webkit-transform: rotateZ(6deg);
              transform: rotateZ(6deg);
    }
    12% {
      -webkit-transform: rotateZ(-4deg);
              transform: rotateZ(-4deg);
    }
    13%, 100% {
      -webkit-transform: rotateZ(0);
              transform: rotateZ(0);
    }
  }
@keyframes wiggle {
    0%, 2% {
      -webkit-transform: rotateZ(0);
              transform: rotateZ(0);
    }
    5% {
      -webkit-transform: rotateZ(-15deg);
              transform: rotateZ(-15deg);
    }
    7% {
      -webkit-transform: rotateZ(10deg);
              transform: rotateZ(10deg);
    }
    8% {
      -webkit-transform: rotateZ(-10deg);
              transform: rotateZ(-10deg);
    }
    10% {
      -webkit-transform: rotateZ(6deg);
              transform: rotateZ(6deg);
    }
    12% {
      -webkit-transform: rotateZ(-4deg);
              transform: rotateZ(-4deg);
    }
    13%, 100% {
      -webkit-transform: rotateZ(0);
              transform: rotateZ(0);
    }
  }
.birges_first_animation{
    -webkit-animation: 6s linear infinite;
            animation: 6s linear infinite;
}
.birges_sec_animation{
    -webkit-animation: 6s linear infinite;
            animation: 6s linear infinite;
    -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
}
.birgesImg3{
    -webkit-animation: 6s linear infinite;
            animation: 6s linear infinite;
    -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;
}
.birgesImg4{
    -webkit-animation: 6s linear infinite;
            animation: 6s linear infinite;
    -webkit-animation-delay: 2.1s;
            animation-delay: 2.1s;
}
.birgesImg5{
    -webkit-animation: 6s linear infinite;
            animation: 6s linear infinite;
    -webkit-animation-delay: 2.8s;
            animation-delay: 2.8s;
}
.analytics_page_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 12vw 5vw 5vw 5vw;
    background: url(/static/media/welcomeBackground.61dbf1fc.png);
    background-size: cover;
}
.analytics_title{
    align-self: flex-start;
    font-size: 10vw;
    line-height: 8vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 3vw;
}
.analyticsImg{
    width: 49vw;
    margin-bottom: 5vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
}
.analytics_row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2.5vw;
    margin-bottom: 5.5vw;
}
.analytics_part_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw;
    border-radius: 2.5vw;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.analytics_part_containerBig{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 49vw;
    border-radius: 2.5vw;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.analytics_part_containerHalfbig{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
    border-radius: 2.5vw;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.analytics_part_titleOne{
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);  
    color: #ffffff;
    font-size: 3.6vw;
    height: 9vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.analytics_part_titleTwo{
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);  
    color: #ffffff;
    font-size: 3.6vw;
    height: 6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.analytics_whitesquare_box{
    width: 100%;
    height: 15vw;
    background: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
}
.analytics_whitesquare_box img{
    width: 14vw;
}
.analytics_part_bottom_button{
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    width: 100%;
    border: none;
    outline: none;
    color: #ffffff;
    padding: 1.5vw 0;
    font-size: 3.3vw;
    text-align: center;
}
.analytics_part_bottom_button:link {
    color: #ffffff;
}
.analytics_part_bottom_button:visited {
    color: #ffffff;
}
.analytics_part_bottom_button:hover {
    color: #ffffff;
}
.analytics_part_bottom_button:active {
    color: #ffffff;
}
.firstDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.05s;
}
.secDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.1s;
}
.thirdDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.15s;
}
.fourthDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.2s;
}
.fifthDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.25s;
}
.sixthDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.3s;
}
.seventhDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.35s;
}
.eithDelayRef{
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.4s;
}
.profile_settings_container{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url(/static/media/fullfon.51e89401.png);
    background-size: cover;
    overflow-y: scroll;
    padding: 10vw 5vw 0vw 5vw;
}
.profile_settings_container > input{
    width: 100%;
    padding: 4vw 2vw 4vw 5vw;
    margin-bottom: 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 1.5vw;
    font-weight: 900;
    font-size: 4vw;
    text-transform: none;
}
.secondInput {
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.1s;
}
.profile_settings_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 7vw;
    align-self: flex-start;
}
.password_box{
    display: flex;
    background: #EAE5F3;
    width: 100%;
    border-radius: 3vw;
    margin-bottom: 4.5vw;
}
.password_box_title{
    width: 33.5vw;
    font-size: 3.6vw;
    color: #4B4359;
    padding: 4vw 0 4vw 3.5vw;
    border-right: 1vw solid #DED6ED;
}
.password_box_input{
    width: 61%;
    outline: none;
    border: none;
    background: none;
    padding-left: 3.5vw;
    font-size: 4.1vw;
    letter-spacing: 0.1vw;
}
.change_password{
    align-self: flex-start;
    width: 100%;
    outline: none;
    border: none;
    background: linear-gradient(110deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    color: #ffffff;
    font-size: 4.1vw;
    padding: 4.1vw 0;
    border-radius: 2vw;
    margin-bottom: 6vw;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.20s;
}
.profile_settings_changeTitle{
    font-weight: 900;
    font-size: 6.5vw;
    margin-bottom: 3vw;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.25s;
}
.referal_box_settings{
    width: 100%;
    background: #ffffff;
    margin-bottom: 8.5vw;
    position: relative;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.25s;
}
.referal_box_settings p{
    text-align: center;
    color: #ffffff;
    border-radius: 3vw 3vw 0 0;
    font-size: 4.3vw;
    padding: 2.2vw 0;
    background: linear-gradient(303deg, rgba(86, 26, 211, 0.4) 0%, rgba(83, 0, 253, 0.6) 100%);
}
.referal_link_settings{
    width: 100%;
    padding: 3vw 2.5vw;
    background: #ffffff;
    position: relative;
    border-radius: 3vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.secSInput{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.05s;
}
.referal_link_settings input{
    width: 100%;
    padding: 2.2vw 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 2.5vw;
    font-size: 3.8vw;
    text-transform: none;
    transition: 0s;
}
.referal_link_settings input::-webkit-input-placeholder{
    text-align:start;
    -webkit-transform: translateX(10vw);
            transform: translateX(10vw);
}
.referal_link_settings input::placeholder{
    text-align:start;
    -webkit-transform: translateX(10vw);
            transform: translateX(10vw);
}
.referal_link_settings input:focus-visible{
    outline: 0.1vw solid #702EF7;
}
.referal_link_settings button{
    position: absolute;
    right: 2.5vw;
    background: linear-gradient(110deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    border-radius: 2.5vw;
    font-size: 3.8vw;
    padding: 2.2vw 1.5vw;
    color: #ffffff;
    border: none;
}

.qr_code_box_settings{
    display: flex;
    background: #EAE5F3;
    border-radius: 3vw;
    width: 100%;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.30s;
}
.white_box_for_qrcode{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 5vw;
    background: #ffffff;
    border-radius: 3vw;
    padding: 3vw;
}
.white_box_for_qrcode > svg{
    width: 26vw;
    height: 26vw;
}
.your_qr_code_settings{
    padding: 5vw 0vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.your_qr_code_settings p{
    font-size: 5vw;
    width: 38vw;
    font-weight: 900;
    line-height: 4.6vw;
}
.your_qr_code_settings img{
    width: 17vw;
    margin-bottom: 2vw;
}
.why_join_ultron_container{
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 12vw;
    /* background: url(../../../img/databasebackground.png);
    background-size: cover; */
}
.why_join_ultron_title{
    font-size: 8.6vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 6vw;
    color: #1B1B1B;
}
.why_join_ultron_undertitle{
    width: 100%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.35vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
}
.why_join_ultron_text{
    font-size: 3.3vw;
    margin-bottom: 4.5vw;
    text-align: center;
    line-height: 3.6vw;
}
.whyJoinCircle{
    width: 20.7vw;
    margin-top: 2.5vw;
    margin-bottom: 2vw;
}
.reason_project_box{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8vw;
    border-radius: 3vw;
    border: 0.2vw solid #9873E5;
    padding: 5vw 0;
    margin-bottom: 4vw;
}
.why_join_ultron_text_reason{
    font-size: 3.3vw;
    text-align: center;
    line-height: 3.6vw;
}
.reasonProjectBoxImg{
    position: absolute;
    top: -45%;
    width: 22vw;
}
.presentation_ultron_container{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 13vw;
    /* background: url(../../../img/databasebackground.png);
    background-size: cover; */
}
.presentation_title{
    font-size: 9.8vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    
}
.presentation_text{
    font-size: 4.1vw;
    margin-bottom: 7vw;
    text-align: center;
    line-height: 4vw;
}
.presentation_button_box{
    display: flex;
    border-radius: 3vw;
    width: 100%;
    align-items: center;
    padding: 3vw 4vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
    background: #ffffff;
    margin-bottom: 3.5vw;
}
.presentation_button_box > img{
    width: 12.5vw;
    height: 12.5vw;
    padding: 2vw;
    background: linear-gradient(94.5deg,#561ad3 26.56%,rgba(83,0,253,.8) 100.53%);
    border-radius: 2.5vw;
}
.presentation_button_box > p{
    font-size: 4.7vw;
    line-height: 4.5vw;
    margin-top: 0.5vw;
    margin-left: 3vw;
    margin-right: auto;
    width: 54.5vw;
}
.arrow_button_presentation_box{
    background: #3BE100;
    width: 12.5vw;
    height: 12.5vw;
    border-radius: 2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_button_presentation_box img{
    width: 5.5vw;

}



.presentations_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    padding-top: 12vw;
    background: url(/static/media/databasebackground.866269db.png);
    background-size: 100%;
}
.presentations_containerBig{
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    padding-top: 12vw;
    background: url(/static/media/databasebackground.866269db.png);
    background-size: 100%;
}
.presentation_titles{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    color: #1B1B1B;
}
.videoPlaceholder_presentations{
    width: 86%;
    margin-bottom: 5vw;
}
.videoPlaceholder_presentations_vebinars{
    width: 86%;
}
.vebinars_part{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.presentations_text {
    width: 86%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.7vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw; 
    margin-top: 3vw;
}
.presentations_vebinars_text{
    width: 75%;
    padding: 2vw 4vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 0 0 3vw 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.7vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw; 
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.presentations_vebinars_text span{
    text-align: start;
    padding-left: 2vw;
}
.left_numbers_circles_vebinars{
    position: absolute;
    left: -4vw;
    color: #1B1B1B;
    background: #ffffff;
    border: 0.7vw solid rgba(86, 26, 211, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7vw;
    width: 8vw;
    height: 8vw;
    border-radius: 10vw;
}
.presentations_next_text{
    width: 88%;
    text-align: center;
    font-size: 4.1vw;
}
.how_make_money_text{
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0 2vw;
    margin-bottom: 5vw;
}
.how_make_money_text img{
    width: 8vw;
    margin-right: 2.5vw;
}
.how_make_money_text p {
    font-size: 3.4vw;
    line-height: 3.5vw;
}
.prognoz_money_cost{
    width: 96%;
    border: 0.2vw solid #9873E5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 0 2vw 0vw;
    border-radius: 3vw;
    position: relative;
    margin-top: 7vw;
}
.prognoz_money_cost img{
    position: absolute;
    top: -35%;
    width: 11vw;
}
.prognoz_money_cost p{
    font-size: 3.7vw;
    font-weight: 900;
    text-align: center;
}
.how_make_money_text_bold{
    font-weight: 900;
    font-size: 3.9vw;
    text-align: center;
    width: 79%;
    margin-top: 6vw;
    margin-bottom: 2vw;
}
.date_money_income_box{
    width: 79%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3vw;
}
.date_money_income_row{
    display: flex;
    width: 100%;
    background: rgba(224, 209, 255, 1);
}
.date_money_income_row:nth-child(even){
    background: rgba(239, 232, 255, 1);
}
.date_money_income_row:first-of-type{border-radius: 3vw 3vw 0 0;}
.date_money_income_row:last-of-type{border-radius: 0 0 3vw 3vw;}
.date_money_income_row p{
    font-size: 4vw;
    font-weight: 900;
    color: #654D96;
}
.date_money_income_row_left{
    margin-right: 1vw;
    padding: 1vw 0;
    width: 44%;
    text-align: center;
    border-right: 0.7vw solid #AC9CD1;
}
.date_money_income_row_right{
    padding: 1vw 0;
    padding-left: 3vw;
}
.how_make_money_text_common{
    width: 73%;
    margin-top: 4vw;
    font-size: 3.3vw;
    text-align: center;
}
.how_make_money_text_common span{
    color: rgba(86, 26, 211, 1);
    font-weight: 900;
}

.date_money_income_box_downer{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3vw;
}
.date_money_income_row_left_downer{
    margin-right: 1vw;
    padding: 1vw 0;
    width: 56%;
    text-align: center;
    border-right: 0.7vw solid #AC9CD1;
}
.date_money_income_row_right_downer{
    padding: 1vw 0;
    padding-left: 3vw;
}
iframe{
    border-radius: 2.5vw;
}
.promotion_how_it_works_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 15vw;
    background: url(/static/media/welcomeBackground.61dbf1fc.png);
    background-size: cover;
}
.promotion_how_it_works_title{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 6vw;
}
.videoPlaceholder{
    width: 90%;
    margin-bottom: 4vw;
}
.promotion_how_it_works_text{
    width: 90%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.35vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
    margin-top: 2vw;
}
.promotion_marketing_mavie_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vw 5vw 5vw 5vw;
    background: url(/static/media/welcomeBackground.61dbf1fc.png);
    background-size: cover;
}
.promotion_marketing_mavie_title{
    font-size: 10vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
}
.marketingMavieImg{
    width: 45vw;
    margin-bottom: 3vw;
}
.promotion_marketing_mavie_text{
    width: 100%;
    text-align: center;
    font-size: 3.15vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
}
.link_to_main_video_promotion{
    width: 100%;
    border-radius: 3vw;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    margin-bottom: 4vw;
}
.link_to_main_video_promotion > p{
    font-size: 3.8vw;
    padding: 1vw 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
}
.link_to_main_video_promotion_footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.link_to_main_video_promotion_footer > p{
    padding: 2vw 0 2vw 4vw;
    font-size: 3.4vw;
}
.link_to_main_video_promotion_footer > button{
    background: rgba(59, 225, 0, 1);
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 3.7vw;
    border-radius: 0 0 3vw 0;
    padding: 1vw 3.5vw;
}
.marketingMavieSteps{
    position: relative;
}
.marketingMavieSteps > img{
    width: 28vw;
}
.marketingMavieSteps_numbers{
    position: absolute;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    font-size: 9vw;
    
}
.marketingMavieSteps{
    display: flex;
    justify-content: center;
    align-items: center;
}
.marketingMavieSteps1{
    left: -18%;
    top: 20%;
}
.marketingMavieSteps2{
    top: -14%;
}
.marketingMavieSteps3{
    right: -22%;
    top: 15%;
}
.work_in_team_first_steps{
    width: 100%;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding-top: 2vw;
    border-radius: 3vw;
    margin-bottom: 3vw;
}
.work_in_team_first_steps_text{
    font-size: 4.1vw;
    color: rgba(86, 26, 211, 1);
    font-weight: 900;
    margin: 2vw 0;
}
.work_in_team_first_steps_button{
    width: 100%;
    background: rgba(59, 225, 0, 1);
    text-align: center;
    color: #ffffff;
    padding: 2vw;
    border-radius: 0 0 3vw 3vw;
    font-size: 3.5vw;
    color: #ffffff;
}
.work_in_team_first_steps_button:link {
    color: #ffffff;
}
.work_in_team_first_steps_button:visited {
    color: #ffffff;
}
.work_in_team_first_steps_button:hover {
    color: #ffffff;
}
.work_in_team_first_steps_button:active {
    color: #ffffff;
}
.work_in_team_first_steps_bottom{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.work_in_team_steps_bottom{
    width: 47%;
    padding-top: 4vw;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3.5vw;
}
.work_in_team_steps_text{
    font-size: 4vw;
    text-align: center;
    color: rgba(86, 26, 211, 1);
    font-weight: 900;

}
.work_in_team_steps_text_versions{
    font-size: 3.7vw;
    margin-bottom: 1vw;
}
.mavieIcon{
    width: 13.5vw;
    margin-bottom: 1.5vw;
}
.promotion_presentation_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 15vw;
    background: url(/static/media/databasebackground.866269db.png);
    background-size: cover;
}
.promotion_presentation_title{
    font-size: 10vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 3vw;
}
.presentationScreen{
    width: 100%;
    margin-bottom: 5vw;
}
.arrows_box{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;
}
.arrows_box img{
    width: 9.5vw;
    margin: 0 5vw;
}
.arrows_box p{
    font-size: 4.5vw;
}
.full_screen_button_presentation{
    margin-top: 5vw;
    width: 100%;
    border-radius: 3vw;
    border: none;
    outline: none;
    padding: 4.5vw 0;
    font-size: 5vw;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.1vw;
    color: #4B4359;
    background: linear-gradient(94.5deg, #EAE5F3 26.56%, #EAE5F3 100.53%),linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
}
.full_screen_button_presentation:link {
  color: #4B4359;
}
.full_screen_button_presentation:visited {
  color: #4B4359;
}
.full_screen_button_presentation:hover {
  color: #4B4359;
}
.full_screen_button_presentation:active {
  color: #4B4359;
}
.promotion_selling_posts_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 15vw;
    background: url(/static/media/welcomeBackground.61dbf1fc.png);
    background-size: cover;
}
.promotion_selling_posts_container > img{
    width: 90%;
}
.promotion_selling_posts_title{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 3vw;
}
.selling_posts_links_box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.selling_posts_links_doubled{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3vw;
}
.selling_posts_links_doubled_box{
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 47%;
    border-radius: 2vw;
    padding: 1.5vw 2vw 1.5vw 3.5vw;
}
.selling_posts_links_doubled_box > p{
    font-size: 3.6vw;
    font-weight: 900;
}
.arrow_button_selling_posts_links_doubled_box{
    background: #3BE100;
    width: 8.6vw;
    height: 8.6vw;
    border-radius: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_button_selling_posts_links_doubled_box img{
    width: 4.5vw;
}
.selling_posts_links_solo_box{
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 2vw;
    padding: 1.5vw 2vw 1.5vw 3.5vw;
    margin-bottom: 3vw;

}
.selling_posts_links_solo_box > p{
    font-size: 3.6vw;
    font-weight: 900;
}
.promotion_where_to_start_container{
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vw 4vw 5vw 4vw;
    background: url(/static/media/welcomeBackground.61dbf1fc.png);
    background-size: cover;
}
.video_boxer{
    width: 99%;
    height: 48vw;
    margin-bottom: 2vw;
}
.promotion_where_to_start_title{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 6vw;
}
.promotion_where_to_start_purple_text{
    width: 90%;
    padding: 2vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.4vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
    letter-spacing: 0.1vw;
}
.vosklMark{
    width: 13vw;
    margin-bottom: 2.5vw;
}
.promotion_where_to_start_text{
    width: 100%;
    text-align: center;
    font-size: 3.15vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
}
.link_to_main_video_promotion{
    width: 100%;
    border-radius: 3vw;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    margin-bottom: 4vw;
}
.link_to_main_video_promotion > p{
    font-size: 3.8vw;
    padding: 1vw 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
}
.link_to_main_video_promotion_footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.link_to_main_video_promotion_footer > p{
    padding: 2vw 0 2vw 4vw;
    font-size: 3.4vw;
}
.link_to_main_video_promotion_footer > button{
    background: rgba(59, 225, 0, 1);
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 3.7vw;
    border-radius: 0 0 3vw 0;
    padding: 1vw 3.5vw;
}
.want_to_join_containers{
    width: 100vw;
    height: 100%;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 12vw;
}
.want_to_join_containersBig{
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 12vw;
}
.want_to_join_parts_title{
    font-size: 8.8vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 5vw;
    color: #1B1B1B;
}
.want_to_join_club_balance_title{
    font-size: 8.4vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 5vw;
    color: #1B1B1B;
}
.want_to_join_videoPlaceholder{
    width: 85%;
}
.want_to_join_parts_undertitle{
    width: 85%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.35vw;
    line-height: 3.6vw;
    margin: 4.5vw 0;
}
.want_to_join_register_steps{
    width: 100%;
    display: flex;
    margin-bottom: 5.5vw;
    align-items: center;
}
.want_to_join_register_steps:last-of-type{
    margin-bottom: 0vw;
}
.want_to_join_register_steps img{
    width: 8vw;
    margin-right: 3vw;
}
.want_to_join_register_steps p{
    font-size: 3.1vw;
    font-weight: 900;
    letter-spacing: 0.1vw;
}
.want_to_join_moneyBag{
    width: 11vw;
    margin-bottom: 2vw;
}
.want_to_join_common_text{
    width: 90%;
    text-align: center;
    font-size: 3.3vw;
}
.want_to_join_containersFAQ{
    font-size: 3.1vw;
    line-height: 3.3vw;
}
.question_box{
    letter-spacing: -0.1vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 5vw;
    width: 100%;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    border-radius: 3vw;
}
.question_box_getmoney{
    letter-spacing: -0.1vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 3vw;
    width: 100%;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    border-radius: 3vw;
}
.white_question{
    background: #ffffff;
    border-radius: 3vw 3vw 0 0;
    text-align: center;
    padding: 2vw 3vw;
    width: 100%;
}
.purple_question{
    font-size: 3.3vw;
    width: 100%;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
    text-align: center;
    padding: 2vw 3vw;
}
.purple_question_getmoney{
    font-size: 3.5vw;
    width: 100%;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
    text-align: center;
    padding: 1.5vw 3vw;
}
.white_answer_getmoney{
    width: 100%;
    text-align: center;
    padding: 2.5vw 2vw;
    letter-spacing: -0.1vw;
    font-size: 2.7vw;
    border-radius: 0 0 3vw 3vw;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.green_answer{
    width: 100%;
    text-align: center;
    padding: 1.6vw;
    color: #ffffff;
    background: rgba(59, 225, 0, 1);
    border-radius: 0 0 3vw 3vw;
}
.white_answer{
    width: 100%;
    text-align: center;
    padding: 1.6vw;
    font-size: 3.3vw;
    border-radius: 0 0 3vw 3vw;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.white_answer p{
    width: 80%;
}
.white_answer_button{
    padding: 2vw 5vw;
    margin-right: 2vw;
    border-radius: 3vw;
    background: rgba(59, 225, 0, 1);
    color: #ffffff;
}
  .white_answer_button:link {
    color: #ffffff;
  }
  .white_answer_button:visited {
    color: #ffffff;
  }
  .white_answer_button:hover {
    color: #ffffff;
  }
  .white_answer_button:active {
    color: #ffffff;
  }
.white_answer_with_button{
    width: 100%;
    text-align: right;
    padding: 1.6vw;
    font-size: 3.3vw;
    border-radius: 0 0 3vw 3vw;
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.white_answer_with_button_p{
    margin-right: 5vw;
}
.want_to_join_bold_text{
    font-weight: 900;
    text-align: center;
    font-size: 3.7vw;
    margin-bottom: 3vw;
}
.want_to_join_bold_text:last-of-type{
    margin-top: 5vw;
}
.profile_structures_container {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13vw 4vw 5vw 4vw;
  background: url(/static/media/fullfon.51e89401.png);
  background-size: cover;
  overflow-y: scroll;
}
.structures_title {
  font-size: 10vw;
  font-family: "TerminatorGenisys";
  margin-bottom: 6vw;
}
.who_invited_you_box {
  display: flex;
  align-self: flex-start;
  align-items: center;
  height: 15vw;
  width: 74vw;
}
.who_invited_you_photo {
  z-index: 100;
  width: 15vw;
  height: 15vw;
  padding: 0.5vw;
  border: 1vw solid#5619D3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #ffffff;
}
.who_invited_you_photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.who_invited_you_names_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.who_invited_you {
  text-align: right;
  font-size: 3.7vw;
  font-weight: 900;
  width: 66%;
  border-radius: 3vw;
  background: #eae5f3;
  z-index: 75;
  padding: 1vw 0;
  padding-right: 6vw;
  margin-left: 5vw;
}
.who_invited_you_name_ID {
  display: flex;
  z-index: 85;
}
.who_invited_you_name {
  background: #561ad3;
  margin-left: 5vw;
  padding: 0.5vw 0;
  text-align: right;
  font-size: 3.7vw;
  border-radius: 3vw;
  width: 66%;
  color: #ffffff;
  padding-right: 3vw;
}
.who_invited_you_ID {
  background: #aa82fb;
  color: #ffffff;
  padding: 0.5vw 0;
  padding-left: 7vw;
  padding-right: 1vw;
  border-radius: 3vw;
  font-size: 3.7vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: -1;
  text-align: left;
  position: absolute;
  left: 65%;
}
.structures_main_content {
  margin-top: 7vw;
  width: 100%;
}
.main_content_upper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.you_photo {
  z-index: 100;
  width: 31vw;
  height: 31vw;
  padding: 0.5vw;
  border: 1.3vw solid#5619D3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.you_photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.structure_downloader {
  right: -4vw;
  background: #eae5f3;
  width: 56vw;
  position: absolute;
  border-radius: 5vw 0 0 5vw;
  padding-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.structure_downloader_text {
  font-size: 3.6vw;
  text-align: center;
  padding: 0 2vw;
  font-weight: 900;
  margin-bottom: 1.5vw;
}
.structure_downloader_button {
  width: 35vw;
  border-radius: 3vw;
  border: none;
  background: #3be100;
  color: #ffffff;
  font-size: 4.1vw;
  padding: 2.5vw 0;
  margin-left: 2vw;
  margin-bottom: 4vw;
  text-align: center;
}
.structure_downloader_button:link {
  color: #ffffff;
}
.structure_downloader_button:visited {
  color: #ffffff;
}
.structure_downloader_button:hover {
  color: #ffffff;
}
.structure_downloader_button:active {
  color: #ffffff;
}
.main_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1vw;
}
.main_content_web_you {
  margin-left: 9vw;
  font-size: 4.2vw;
  font-weight: 900;
  padding: 0.7vw 3.4vw;
  border: 0.1vw solid #ab8dea;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1.5vw;
  align-self: flex-start;
  margin-bottom: -0.5vw;
}
.structuresBranch {
  width: 76%;
}
.invited_peoples {
  display: flex;
  width: 100%;
  align-self: flex-start;
  justify-content: space-between;
  padding: 0 0.5vw;
}
.invited_photo {
  z-index: 1;
  position: relative;
  width: 20vw;
  height: 20vw;
  padding: 0.5vw;
  border: 1.3vw solid#5619D3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-top: -3vw;
}
.invited_photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.invited_about {
  width: 22vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.invited_name {
  margin-top: 2vw;
  line-height: 3vw;
  text-align: center;
  font-size: 3vw;
}
.invited_ID {
  text-align: center;
  font-size: 3vw;
  font-weight: 900;
  background: #eae5f3;
  padding: 0vw 2vw;
  border-radius: 2vw;
}
.how_much_invited {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 4vw;
  background: #ffffff;
  box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
  border-radius: 3vw;
  padding-bottom: 3vw;
}
.how_much_invited_title {
  background: linear-gradient(
      94.5deg,
      rgba(86, 26, 211, 0.6) 26.56%,
      rgba(83, 0, 253, 0.48) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  color: #ffffff;
  font-size: 4.1vw;
  border-radius: 0 0 2vw 2vw;
  padding: 3vw 0;
  width: 80%;
  text-align: center;
  margin-bottom: 3vw;
}
.how_much_invited_amount {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vw 5vw;
  border-radius: 3vw;
  font-size: 6.2vw;
  font-weight: 900;
  text-align: center;
  background: #eae5f3;
}
.no_invited_box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.no_invited {
  font-size: 6.5vw;
  font-weight: 900;
  color: #5619d3;
  margin-top: 15vw;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.structures_list_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13vw 6vw 5vw 6vw;
  background: url(/static/media/welcomeBackground.61dbf1fc.png);
  background-position: 0 0;
  background-size: cover;
}
.structures_list_part_container {
  width: 100%;
  border-radius: 3vw;
  background: #ffffff;
  box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3vw;
  margin-bottom: 6vw;
}
.list_part_name_id_box {
  width: 100%;
  border-radius: 3vw 3vw 0 0;
  display: flex;
}
.list_part_name_box {
  background: linear-gradient(
      0deg,
      rgba(86, 26, 211, 0.6),
      rgba(86, 26, 211, 0.6)
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  border-radius: 3vw 0 3vw 0;
  padding: 2vw 0vw 1vw 3vw;
  width: 100%;
}
.list_part_name {
  color: #ffffff;
  font-size: 4.5vw;
}
.list_part_invitation {
  color: #ffffff;
  font-size: 3vw;
}
.list_part_id {
  width: 30vw;
  background: rgba(234, 229, 243, 1);
  height: 7vw;
  border-radius: 0 3vw 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
  font-weight: 900;
}
.list_part_refLink_box {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 92%;
  overflow: hidden;
  margin-top: 3vw;
  background: rgba(234, 229, 243, 1);
  border-radius: 2vw;
  font-size: 3.3vw;
  height: 7vw;
  padding: 1.2vw 0 1.2vw 3vw;
}
.list_part_refLink {
  width: 60%;
  background: none;
  outline: none;
  border: none;
  font-size: 3.3vw;
}
.list_part_copyLink {
  position: absolute;
  width: 30vw;
  right: 0;
  top: 0;
  border-radius: 2vw;
  font-size: 3.1vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  padding: 0vw 1.5vw;
  background: rgba(59, 225, 0, 1);
}
.list_part_copyLink img {
  width: 3.6vw;
}
.list_part_copyLinkIMG {
  pointer-events: none;
}

.documents_page_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vw 5vw 5vw 5vw;
    background: url(/static/media/fullfon.51e89401.png);
    background-size: cover;
    overflow-y: scroll;
}
.documents_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
}
.documents_undertitle{
    font-size: 4.1vw;
    text-align: center;
    margin-bottom: 2.5vw;
}
.document_box{
    display: flex;
    justify-content: space-between;
    padding: 2.2vw;
    padding-right: 0;
    /* transform: scale(0); */
    padding-left: 3vw;
    background: #ffffff;
    width: 100%;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    margin-bottom: 4.5vw;
}
.document_box_img{
    width: 30vw;
}
.document_about{
    width: 53%;
    margin-right: 1vw;
    padding: 1vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.document_about_text1{
    width: 100%;
    font-size: 3.6vw;
}
.document_about a{
    width: 99%;
    color: #ffffff;
    border: none;
    border-radius: 1.5vw;
    font-size: 3.6vw;
    padding: 2.5vw 0;
    background: linear-gradient(93.33deg, #3ACD06 0.41%, #58E700 100.4%);
    text-align: center;
}
.konkurses_page_container{
    width: 100vw;
    height: 100%;
    padding: 12vw 5vw 5vw 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: url(../../img/welcomeBackground.png);
    background-position: 0 0;
    background-size: cover; */
}
.konkurses_page_title{
    font-size: 9.7vw;
    font-family: "TerminatorGenisys";
    text-align: center;
}
.konkurses_page_parachute_img{
    width: 45vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.0s;

}
.konkurses_page_text{
    background: rgba(161, 120, 243, 1);
    border-radius: 3vw;
    padding: 3vw 0vw;
    color: #ffffff;
    font-size: 4.6vw;
    margin-bottom: 5vw;
    width: 90vw;
    text-align: center;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.05s;
}
.calendar_timer{
    
    -webkit-transform: scale(0);
    
            transform: scale(0);
    transition-delay: 0.1s;
}
.payday{
    
    -webkit-transform: scale(0);
    
            transform: scale(0);
    transition-delay: 0.15s;
}
.calendar_timer,.payday{
    width: 100%;
    padding: 2.5vw 0vw 2.5vw 2.6vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vw;
    background: #ffffff;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.payday{
    margin-bottom: 15%;
}
.calendar_timer img,.payday img{
    width: 13vw;
    margin-right: 4vw;
}
.calendar_box, .payday_box{
    display: flex;
}
.box_title{
    text-align: start;
    font-size: 5vw;
}
.box_numbers{
    font-size: 7vw;
    text-align: start;
    font-weight: 900;
}
.purple_ear{
    width: 1.5vw;
    height: 13vw;
    border-radius: 3vw 0vw 0vw 3vw;    
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.button_rollin{
    width: 90vw;
    text-align: center;
    padding: 5.8vw 0;
    color: #ffffff;
    font-size: 5.2vw;
    border-radius: 3vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    margin-bottom: 5vw;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.2s;
}
.upper_icons_profile{
    width: 13.6vw;
    border: 0.8vw solid #702EF7;
    border-radius: 2vw;
    position: absolute;
    top: 5.4vw;
    left: 5vw;
}
.profile_page_container{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vw 5vw 5vw 5vw;
    background: url(/static/media/fullfon.51e89401.png);
    background-size: cover;
    overflow-y: scroll;
}
.profile_page_container > button{
    width: 100%;
    border-radius: 1.5vw;
    border: none;
    background: #3BE100;
    margin-bottom: 3vw;
    color: #ffffff;
    font-size: 4vw;
    padding: 4vw 0;
}
.profile_header_buttons{
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buttonToName{
    font-size: 2.6vw;
}
.buttonToProfileSettings{
    width: 15vw;
}
.header_button{
    margin-bottom: 9vw;
    -webkit-transform: scale(0);
            transform: scale(0);
}
.profile_page_container > input{
    width: 100%;
    padding: 4vw 2vw 4vw 5vw;
    margin-bottom: 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 1.5vw;
    font-weight: 900;
    font-size: 4vw;
    text-transform: none;
}
.profile_page_container > input:focus-visible{
    outline: 0.1vw solid #702EF7;
}
.profile_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
}
.profile_photo_box{
    padding: 0.5vw;
    border-radius: 50%;
    width: 31vw;
    height: 31vw;
    border: 1.3vw solid rgba(112, 47, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 4.5vw;
    -webkit-transform: rotateZ(0deg) scale(0);
            transform: rotateZ(0deg) scale(0);
    transition-delay: 0.0s;
}
.firstInput{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.05s;
}
.secInput{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.1s;
}
.thirdInput{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.15s;
}
.fifthInput{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.25s;
}
.sixthInput{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.3s;
}
.sevenInput{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.35s;
}
.profile_photo{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.pencil_photo_profile{
    width: 11vw;
    position: absolute;
    bottom: 0;
    right: -4vw;
}
.referal_box{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 24.5vw;
    background: #ffffff;
    margin-bottom: 4.5vw;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.profileBoxRef1{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.05s;
}
.profileBoxRef2{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.1s;
}
.profileBoxRef3{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.15s;
}
.profileBoxRef4{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.2s;
}
.refDelay2{
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.2s;
}
.referal_box_title{
    text-align: center;
    color: #ffffff;
    font-size: 5.1vw;
}
.referal_box_title2{
    text-align: center;
    color: #ffffff;
    font-size: 5.1vw;
    padding: 2.2vw 0;
    border-radius: 2.5vw 2.5vw 0 0;
    background: linear-gradient(94.5deg, rgba(65, 18, 162, 0.6) 46.21%, rgba(83, 0, 253, 0.48) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
}
.profile_part_header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw 0;
    border-radius: 2.5vw 2.5vw 0 0;
    background: linear-gradient(94.5deg, rgba(152, 26, 211, 0.8) 26.56%, rgba(0, 25, 253, 0.64) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    position: relative;
}
.profile_icons{
    width: 6vw;
    margin-right: 1.5vw;
}
.profile_icons2{
    width: 6.5vw;
    position: absolute;
    right: 3vw;
}
.referal_link{
    width: 100%;
    padding: 3vw 2.5vw;
    background: #ffffff;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 2.5vw;
}
.referal_link_input{
    width: 100%;
    padding: 2.2vw 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 2vw;
    font-size: 3.8vw;
    text-transform: none;
}
.referal_link_sec_input{
    width: 100%;
    padding: 2.2vw 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 2.5vw;
    font-size: 3.8vw;
    text-transform: none;
}
.referal_link_sec_input:focus-visible{
    outline: 0.1vw solid #702EF7;
}
.referal_link button{
    position: absolute;
    right: 2.5vw;
    background: linear-gradient(110deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    border-radius: 2vw;
    font-size: 3.5vw;
    padding: 2.2vw 1.5vw;
    color: #ffffff;
    border: none;
}
.qr_code_box{
    display: flex;
    background: #EAE5F3;
    border-radius: 3vw;
    justify-content: center;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition-delay: 0.25s;
}
.qr_code_box > svg{
    width: 24vw;
    height: 24vw;
    margin-right: 7vw;
}
.your_qr_code{
    padding: 3vw 1vw 3vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.your_qr_code p{
    margin-top: 1vw;
    font-size: 6vw;
    width: 23vw;
    font-weight: 900;
    line-height: 4.6vw;
}
.your_qr_code img{
    width: 13vw;
}
.qrcode_placeholder_box{
    padding: 2vw;
    margin-right: 2vw;
    position: relative;
}
.qrcode_placeholder{
    width: 38vw;
    padding: 2.5vw 0 1vw 2vw;
    font-size: 3.5vw;
    border: 0.1vw solid rgba(210, 200, 227, 1);
    border-radius: 3vw;
}
.qrcodeplaceholderArrows{
    position: absolute;
    width: 6vw;
    top: 5vw;
    right: -1vw;
}
.sections_main_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url(/static/media/fullfon.51e89401.png);
    background-size: cover;
    overflow-y: scroll;
    position: relative;
    padding: 12vw 5vw 5vw 5vw;
}
.section_container_box{
    display: flex;
    width: 100%;
}
.sections_undertitle{
    font-size: 4.2vw;
    text-align: center;
    margin-bottom: 4vw;
}
.sections_header_img{
    width: 60%;
    margin-bottom: 8vw;
}
.content_container_box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12vw 5vw 5vw 5vw;
  background: url(/static/media/fullfon.51e89401.png);
  background-size: cover;
  overflow-y: scroll;
  position: relative;
}
.link_date {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 3.5vw;

  margin: 3vw 0;
}
.link_date p {
  background: #ab84fd;
  color: #fff;
  font-family: "RoadRadio";
  font-style: normal;
  font-style: normal;
  font-weight: 900;
  font-size: 4.3vw;
  text-align: center;
  padding: 1.5vw;
  border-radius: 3vw 3vw 0 0;
}
.link_date div {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5vw 3vw;
}
.link_date div span {
  font-family: "RoadRadio";
  font-style: normal;
  font-size: 4vw;
}
.link_date a {
  font-family: "RoadRadio";
  font-style: normal;
  font-style: normal;

  font-size: 4vw;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border-radius: 3vw;
  padding: 2.5vw 4vw;
}

.content_container_title {
  font-size: 9.8vw;
  font-family: "TerminatorGenisys";
  margin-bottom: 6vw;
  text-align: center;
  color: #1b1b1b;
}
.content_container_text1 {
  font-size: 4.2vw;
  margin-bottom: 6vw;
  text-align: center;
  color: #1b1b1b;
}
.content_container_text2 {
  font-size: 4.2vw;
  margin-bottom: 6vw;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(94.5deg, #9873e5 26.56%, #a178f3 100.53%),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  width: 100%;
  border-radius: 3vw;
  padding: 3vw 8vw;
}
.content_container_text3_box {
  width: 100%;
  border-radius: 3vw;
  margin-bottom: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
}
.content_container_text3_title {
  color: #ffffff;
  width: 100%;
  background: linear-gradient(
      94.5deg,
      rgba(86, 26, 211, 0.6) 26.56%,
      rgba(83, 0, 253, 0.48) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  padding: 1.5vw 0;
  text-align: center;
  border-radius: 3vw 3vw 0 0;
  font-size: 4.5vw;
}
.content_container_text3_text {
  text-align: center;
  padding: 3vw;
  font-size: 4vw;
  color: #1b1b1b;
}
.content_container_subtitle {
  font-size: 5vw;
  font-weight: 900;
  text-align: center;
  margin-bottom: 6vw;
}
.content_container_img1 {
  width: 100%;
  border-radius: 3vw;
  margin-bottom: 6vw;
}
.content_container_elem_box {
  width: 100%;
  display: flex;
  margin-bottom: 6vw;
}
.content_container_elem_img {
  width: 12.5vw;
  height: 12.5vw;
  margin-right: 3vw;
  box-shadow: 6px 3px 6px 0px rgba(181, 125, 216, 0.25);
  border-radius: 4.5vw;
  background: linear-gradient(
      94.5deg,
      #561ad3 26.56%,
      rgba(83, 0, 253, 0.8) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
}
.content_container_elem_text {
  width: 82%;
  font-size: 4.2vw;
  line-height: 3.5vw;
  margin-top: 1.5vw;
}
.content_container_img2_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  height: 25vw;
  margin-bottom: 6vw;
}
.content_container_img2 {
  border-radius: 50%;
  border: 1vw solid rgba(86, 26, 211, 1);
  width: 25vw;
  height: 25vw;
}
.content_container_table_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(224, 209, 255, 1);
  border-radius: 3vw;
  margin-bottom: 6vw;
}
.content_container_table_section {
  width: 100%;
  display: flex;
  align-items: center;
  height: 8.5vw;
  color: rgba(101, 77, 150, 1);
  font-weight: 900;
}
.content_container_table_section > p {
  width: 50%;
  padding-left: 4vw;
  display: flex;
  align-items: center;
  border-right: 0.2vw solid rgba(172, 156, 209, 1);
  height: 100%;
}
.content_container_table_section > span {
  width: 50%;
  padding-left: 4vw;
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 0.2vw solid rgba(172, 156, 209, 1);
}
.content_container_video_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3vw;
  margin-bottom: 6vw;
}
iframe {
  width: 100%;
  height: 50vw;
}

.settings {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 4vw;
  height: 4vw;
  padding: 0.5vw;
  border-radius: 50%;
  background: rebeccapurple;
  cursor: pointer;
}
.settings img {
  width: 100%;
}
.tgs {
  position: absolute;
  bottom: 0vw;
  right: 1vw;
  z-index: 100000;
  width: 11vw;
  height: 11vw;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}
.mainTgs {
  position: fixed;
  bottom: 0vw;
  right: 1vw;
  width: 11vw;
  height: 11vw;
  z-index: 100000;
  cursor: pointer;
}
.mainTgs:hover .tgsText {
  -webkit-transform: translate(-50%, -50%) scaleX(1);
          transform: translate(-50%, -50%) scaleX(1);
}
.tg,
.tgt {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: 0.5s;
}
.tg {
  width: 6.5vw;
  z-index: 100001;
  pointer-events: none;
}
.tgt {
  width: 11vw;
  -webkit-animation: rotate 12s infinite;
          animation: rotate 12s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.mainTgs:hover .tgt {
  width: 5vw;
}
.tgsText p {
  margin-left: 2vw;
}
.tgsText {
  color: #fff;
  font-size: 1.2vw;
  background: #4514aa;
  border: 1px solid #ffffff;
  border-radius: 50px 0 0 50px;
  position: absolute;
  height: 4.7vw;
  text-align: right;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: -24%;
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: translate(-50%, -50%) scaleX(0);
          transform: translate(-50%, -50%) scaleX(0);
  width: 14vw;
  overflow: hidden;
}
.landing__body {
  background: #1f1d21;
  overflow-x: hidden;
}
.land_img {
  width: 100%;
}
.settings {
  z-index: 100;
}
.pop_settings {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  z-index: 50;
  flex-direction: column;
  align-items: center;
  background: url(/static/media/settings.f19729aa.jpg);
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 3.5vw;
}
.kek {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5vw;
  font-weight: 800;
  margin-left: 0.5vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.pop_settings input {
  background: rgba(255, 255, 255, 0.2);

  outline: none;
  border-radius: 1vw;
  border: none;
  padding: 2vw;
  font-size: 1.2vw;
  color: #b7b1c2;
  width: 45vw;
}
.pop_set_body > div {
  margin-bottom: 2.5vw;
}
.pop_settings div {
  display: flex;
  align-items: center;
}
.header_land {
  position: relative;
}
.ult_head {
  top: 12vw;
  left: 27%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 46vw;
  position: absolute;
}
.header_desc {
  text-align: center;
  font-size: 1.2vw;
  color: #ffffff;
  position: absolute;
  top: 18vw;
  width: 56vw;
  left: 22%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.title_pop {
  padding-bottom: 2vw;
}
.pop_set_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.desc_pop {
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.6vw;
  width: 31vw;
}
.desc_more {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.land9,
.land12 {
  position: relative;
}
.tel_img {
  display: flex;
  align-items: center;
  position: absolute;
  top: 36vw;
  width: 100vw;
  justify-content: center;
}
.tel_img img {
  transition: 0.3s;
  width: 25vw;
}
.tel_img img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.tel_img img:first-child {
  margin-right: -6vw;
}
.tel_img img:last-child {
  margin-left: -6vw;
}
.timer {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.11);
  border-radius: 10vw;
  padding: 1.5vw 2vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 28vw;
}
.casul {
  display: flex;
  align-items: center;
}
.copy_link input {
  background: none;
  border: none;
  color: #fff;
}
.personal__code {
  padding: 2vw 3vw;
  border: 2px solid rgba(54, 50, 56, 0.6);
  border-radius: 1vw;
  position: absolute;
  left: 50%;
  top: 17vw;
  font-size: 1.5vw;
  font-weight: 800;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.copy {
  color: black;
  font-size: 1vw;
  padding: 0.8vw 2vw;
  background: #a8ff00;
  border-radius: 2.5vw;
  display: flex;
  align-items: center;
  margin-left: 2vw;
  cursor: pointer;
}
.reg {
  position: absolute;
  left: 50%;
  bottom: 11vw;
  font-size: 1.5vw;
  margin-left: 0 !important;
  font-weight: 800;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.copy img {
  width: 1.5vw;
  margin-left: 0.5vw;
}
.casul {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 2.5vw;
  padding: 0.5vw;
  padding-left: 2vw;
  margin-top: 1.5vw;
}
.timer div {
  margin-right: 5vw;
  width: 16vw;
}
.timer p {
  font-size: 4.5vw;
  font-weight: 800;
  line-height: 3.5vw;
}
.timer span {
  font-size: 1.3vw;
}
.timer .link__to {
  padding: 1vw 1.5vw !important;
}
.desc_more p {
  font-size: 1vw;
}
.img_load,
.link__to {
  background: #a8ff00;
  border-radius: 2.5vw;
  font-size: 1.3vw;
  color: #1f1d21;
  padding: 1.3vw 2.5vw;
  cursor: pointer;
}
.link__to {
  padding: 1vw 3.5vw !important;
}
#img__path {
  display: none;
}
.wait_photo {
  width: 12vw;
  height: 10vw;
  background: #ffffff;
  mix-blend-mode: overlay;
  margin-left: 0.5vw;
  margin-right: 2vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #b7b1c2;
  font-size: 1.2vw;
}
.side_content {
  position: absolute;
  right: 12vw;
  bottom: 5vw;
  background: rgba(54, 50, 56, 0.6);
  border-radius: 2vw;
  padding: 1vw;
  display: flex;
  align-items: center;
}
.side_content > img {
  width: 12.5vw;
  margin: 2.5vw;
}
.side_content h3 {
  color: #533989;
  font-size: 2.3vw;
  margin-bottom: 1vw;
}
.side_content div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.side_content p {
  font-size: 1.3vw;
  color: #fff;
  width: 50vw;
  line-height: 1.5vw;
  margin-bottom: 1.5vw;
}
.photo_red {
  width: 12vw;
  height: 10vw;

  margin-left: 0.5vw;
  margin-right: 2vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #b7b1c2;
  font-size: 1.2vw;
}
.get_trainer {
  text-decoration: none;
  color: #fff;
  background: rebeccapurple;
  padding: 5vw 10vw;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 3vw;
  width: 100%;
  margin-top: 5vw;
}
.save__button {
  color: #fff;
  background: rebeccapurple;
  padding: 1vw 2vw;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
  margin-bottom: 0 !important;
}
.landing__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info__user {
  display: flex;
  align-items: center;
  padding: 5vw;
}
.info__user img {
  width: 30vw;
  border-radius: 50%;
  margin-right: 4vw;
}
.button_head {
  position: absolute;
  right: 13vw;
  top: 2.4vw;
  width: 13vw;
  cursor: pointer;
}
.land4 {
  position: relative;
}

.slider {
  position: absolute;
  left: 50%;
  bottom: 5vw;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slider_body {
  width: 70vw;
  height: 39.5vw;

  border-radius: 1.5vw;
  overflow: hidden;
}
.slider__buttons {
  display: flex;
  align-items: center;
}
.land5,
.land6,
.land8 {
  position: relative;
}
.youtube {
  position: absolute;
  bottom: 9.4vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 63.2vw;
  height: 35.7vw;
  border-radius: 0 !important;
}
.registration:hover,
.reg:hover {
  -webkit-transform: translateX(-50%) translateY(-5px);
          transform: translateX(-50%) translateY(-5px);
  box-shadow: 0px 0px 32px 15px rgb(168 255 0 / 50%);
}
.watch1:hover {
  box-shadow: 0px 0px 32px 15px rgb(168 255 0 / 30%);
  -webkit-transform: translateX(-50%) translateY(-45%);
          transform: translateX(-50%) translateY(-45%);
}
.copy_value:hover,
.link__to:hover {
  box-shadow: 0px 0px 22px 9px rgb(168 255 0 / 20%);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.button_head:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.youtube2 {
  position: absolute;
  top: 15.5vw;
  left: 49.3%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 63.2vw;
  height: 35.7vw;
  border-radius: 0 !important;
}
.youtube3 {
  position: absolute;
  bottom: 7.5vw;
  left: 49.3%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 63.2vw;
  height: 35.7vw;
  border-radius: 0 !important;
}
.left,
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  border: 3px solid #ffffff;
  color: #fff;
  font-size: 1.3vw;
  margin: 0.5vw;
  margin-top: 1vw;
  cursor: pointer;
  opacity: 0.6;
}
.lins {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: -32vw;
}
.link_container {
  border: 2px solid rgba(255, 255, 255, 0.26);
  border-radius: 1vw;
  padding: 1vw;
  width: 27vw;
  margin: 1vw;
  color: #fff;
}
.copy_link {
  border: 2px solid rgba(255, 255, 255, 0.26);
  border-radius: 1vw;
  padding: 1vw;
  height: 9vw;
}
.copy_value {
  background: #a8ff00;
  border-radius: 2.5vw;
  font-size: 1vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vw 2.5vw;
  color: black;
  margin: 1vw auto;
  margin-bottom: 0;
  cursor: pointer;
}
.copy_link p {
  word-break: break-all;
  text-align: center;
  font-size: 1vw;
}
.link_container > p {
  font-size: 1.5vw;
  text-align: center;

  height: 6vw;
}
.slider_body div {
  display: flex;
  width: 100%;
}
.slider_body img {
  width: 100%;
}
.slide_active {
  border: 3px solid #a8ff00 !important;
  color: #fff !important;
  opacity: 1 !important;
}
.registration {
  color: #1f1d21;
  font-size: 1.2vw;
  padding: 1.5vw 3vw;
  color: #1f1d21;
  border-radius: 2.5vw;
  position: absolute;
  left: 40%;
  top: 34vw;
  cursor: pointer;
  background: #a8ff00;
  box-shadow: 0px 0px 20px 9px rgba(168, 255, 0, 0.2);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.land3 {
  position: relative;
}
.dbd img {
  width: 19vw;
  margin: 0 1vw;
}
.dbd {
  display: flex;
  position: absolute;
  top: 42vw;
  width: 100vw;
  justify-content: center;
}
.slider_body > div {
  position: relative;
}
.dbd img:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.ddd {
  position: absolute;
  top: 68vw;
  width: 50vw;
  left: 25vw;
}
.watch1 {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #a8ff00;
  color: #1f1d21;
  font-size: 1.3vw;
  border-radius: 2.5vw;
  padding: 1.5vw 2vw;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.slider_body img {
  opacity: 0.3;
}
@media screen and (max-width: 640px) {
  .registration {
    color: #1f1d21;
    font-size: 5.2vw;
    padding: 4.5vw 9vw;
    color: #1f1d21;
    border-radius: 11.5vw;
    position: absolute;
    left: 10.5%;
    top: 148vw;
    cursor: pointer;
    background: #a8ff00;
    box-shadow: 0px 0px 20px 9px rgb(168 255 0 / 20%);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .watch1 {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #a8ff00;
    color: #1f1d21;
    font-size: 4.3vw;
    border-radius: 8.5vw;
    padding: 4.5vw 4vw;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 75vw;
    text-align: center;
    display: block;
    cursor: pointer;
  }
  .header_desc {
    text-align: center;
    font-size: 4.2vw;
    color: #ffffff;
    position: absolute;
    top: 74vw;
    width: 87vw;
    left: 6%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .button_head {
    position: absolute;
    right: 4vw;
    top: 8.4vw;
    width: 39vw;
    cursor: pointer;
  }
  .slider_body {
    width: 94vw;
    height: 53.5vw;
    border-radius: 3.5vw;
    overflow: hidden;
  }
  .left,
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9vw;
    height: 9vw;
    border-radius: 50%;
    border: 3px solid #ffffff;
    color: #fff;
    font-size: 3.3vw;
    margin: 3vw;
    margin-top: 1vw;
    cursor: pointer;
    opacity: 0.6;
  }
  .youtube {
    position: absolute;
    bottom: 36.2vw;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 83vw;
    height: 47vw;
    border-radius: 0;
    border: none;
  }
  .lins {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: -160vw;
  }
  .link_container {
    border: 2px solid rgba(255, 255, 255, 0.26);
    border-radius: 4vw;
    padding: 4vw;
    width: 90vw;
    margin: 4vw;
    color: #fff;
  }
  .link_container > p {
    font-size: 4.5vw;
    text-align: center;
    height: 14vw;
  }
  .copy_link {
    border: 2px solid rgba(255, 255, 255, 0.26);
    border-radius: 3vw;
    padding: 4vw;
    height: auto;
  }
  .youtube2 {
    position: absolute;
    top: 45.2vw;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 83.3vw;
    height: 47vw;

    border: none;
  }
  .youtube3 {
    position: absolute;
    bottom: 24vw;
    left: 49.3%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 84vw;
    height: 46.7vw;
    border-radius: 0 !important;
  }
  .side_content {
    position: static;
    width: 90vw;
    -webkit-transform: none;
            transform: none;
    margin: 0 auto;
    background: rgba(54, 50, 56, 0.6);
    border-radius: 4vw;
    padding: 4vw;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .side_content > img {
    width: 57%;
    margin: 2.5vw;
    max-height: 43vw;
  }
  .side_content div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .side_content h3 {
    color: #533989;
    font-size: 8.3vw;
    text-align: center;
    margin-bottom: 1vw;
  }
  .img_load,
  .link__to {
    background: #a8ff00;
    border-radius: 6.5vw;
    font-size: 4.3vw;
    color: #1f1d21;
    padding: 3.3vw 7.5vw;
    cursor: pointer;
  }
  .title_pop {
    width: 90vw;
    padding-bottom: 8vw;
  }
  .pop_set_body div {
    flex-direction: column;
    align-items: center;
  }
  .pop_set_body div p {
    text-align: center;
  }
  .desc_pop {
    width: 90vw;
    font-size: 5vw;
  }
  .desc_more p {
    font-size: 3.5vw;
    font-weight: 700;
  }
  .pop_settings input {
    background: rgba(255, 255, 255, 0.2);

    outline: none;
    border-radius: 3vw;
    border: none;
    margin-top: 2vw;
    padding: 4vw;
    font-size: 4.2vw;
    color: #b7b1c2;
    width: 90vw;
    margin-bottom: 5vw;
  }
  .save__button {
    color: black;
    background: #a8ff00;
    padding: 3vw 6vw;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 9vw;
    font-size: 5.5vw;
    width: 50vw;
    cursor: pointer;
    margin-bottom: 0 !important;
    margin: 0 auto;
  }
  .img_load {
    margin-bottom: 4vw;
  }
  .photo_red {
    width: 40vw;
    height: 30vw;
    margin: 4vw 0;
  }
  .desc_more {
    text-align: center;
  }
  .settings {
    position: fixed;
    bottom: 4vw;
    right: 4vw;
    width: 10vw;
    height: 10vw;
    padding: 0.5vw;
    border-radius: 50%;
    background: rebeccapurple;
    cursor: pointer;
  }
  .settings img {
    padding: 1vw;
  }
  .timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.11);
    border-radius: 6vw;
    padding: 4.5vw 5vw;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    color: #fff;
    position: absolute;
    left: 50%;
    width: 90vw;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: 26vw;
    top: auto;
  }
  .copy img {
    width: 3.5vw;
    margin-left: 1.5vw;
  }
  .reg {
    position: absolute;
    left: 50%;
    bottom: 47vw;
    font-size: 5.5vw !important;
    margin-left: 0 !important;
    font-weight: 800;
    padding: 2vw 5vw !important;
    text-align: center;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .personal__code {
    padding: 6vw 8vw;
    border: 2px solid rgba(54, 50, 56, 0.6);
    border-radius: 5vw;
    position: absolute;
    left: 50%;
    width: 90vw;
    top: 59vw;
    text-align: center;
    font-size: 6.5vw;
    font-weight: 800;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .copy {
    color: black;
    font-size: 3vw;
    padding: 2.8vw 4vw;
    background: #a8ff00;
    border-radius: 7.5vw;
    display: flex;
    align-items: center;
    margin-left: 2vw;
    cursor: pointer;
  }
  .casul {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 6.5vw;
    padding: 1.5vw;
    padding-left: 2vw;
    margin-top: 1.5vw;
  }
  .timer div {
    margin-right: 0;
    width: auto;
    text-align: center;
  }
  .timer p {
    font-size: 12.5vw;
    font-weight: 800;
    line-height: 12.5vw;
  }
  .timer .link__to {
    padding: 3vw 5.5vw !important;
    -webkit-transform: translateY(9vw);
            transform: translateY(9vw);
  }
  .timer span {
    font-size: 4vw;
  }

  .link__to {
    padding: 3vw 9.5vw !important;
  }
  .side_content p {
    font-size: 4.3vw;
    color: #fff;
    width: 90%;
    text-align: center;

    line-height: 6vw;
    margin-bottom: 4.5vw;
  }

  .kek {
    background: none;
    border: none;
    color: #fff;
    font-size: 6.5vw;
    font-weight: 800;
    margin-left: 1.5vw;

    width: 15vw;
  }
  .copy_link p {
    word-break: break-all;
    text-align: center;
    font-size: 3vw;
  }
  .copy_value {
    background: #a8ff00;
    border-radius: 9.5vw;
    font-size: 3.5vw;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding: 3vw 7.5vw;
    color: black;
    margin: 3vw auto;
    margin-bottom: 0;
    cursor: pointer;
  }
  .mainTgs {
    position: fixed;
    bottom: 25vw;
    right: 2vw;
    width: 20vw;
    height: 20vw;
    z-index: 100000;
    cursor: pointer;
    display: block;
  }
  .tgs {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
  }
  .mainTgs:hover .tgt {
    width: 29vw;
  }
  .tgt {
    width: 29vw;
    -webkit-animation: rotate 12s infinite;
            animation: rotate 12s infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .tg {
    width: 18.5vw;
    z-index: 100001;
    pointer-events: none;
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

.header_bot {
  background: url(/static/media/fon.ce508df8.png);
  background-color: #201c24;
  background-size: cover;
  padding-top: 7vw;
  padding-bottom: 5vw;
  height: 100vh;
}
.bot_land {
  width: 100vw;
  overflow-x: hidden;
  background-color: #201c24;
}
.header_title {
  display: flex;
  align-items: flex-end;
  color: #fff;
}
.header_bot {
  width: 100%;
}

.header_title h1 {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 8vw;
}
.header_title span {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  width: 20vw;
  display: block;
  margin-left: 1vw;
  margin-bottom: 1.6vw;
}
.header_bot_body {
  width: 72vw;
  margin: 0 auto;
  position: relative;
}
.bot_sub {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 900;
  font-size: 3.5vw;

  /* identical to box height, or 91px */
  margin-top: -1vw;
  color: #a8ff00;
}
.x1_bot {
  font-family: "RoadRadio";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5vw;

  /* or 62px */

  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(142, 0, 253, 0.8) 28.23%,
      rgba(142, 0, 253, 0) 100%
    ),
    radial-gradient(
        94.78% 94.78% at 49.32% 5.22%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 49.95%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        65.67% 65.67% at 52.27% 146.27%,
        rgba(0, 0, 0, 0.2) 62.89%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #561ad3;
  border-radius: 3.5vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5vw 1.5vw;
  color: #ffffff;
  margin-top: 1.5vw;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.pop_bot {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}
.closeme {
  margin-left: 74vw;
  width: 3vw;
  cursor: pointer;
}
.youtubeBot1 {
  width: 70vw;
  height: 40vw;
  border-radius: 0 !important;
}
.product {
  color: #fff;
  font-size: 1.6vw;
  font-weight: 900;
  margin-top: 2.5vw;
}
.link_instruction {
  background: #a8ff00;
  box-shadow: 0px 0px 26px rgba(168, 255, 0, 0.38);
  border-radius: 2.8vw;
  font-size: 1.2vw;
  padding: 1.3vw 4vw;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 700;
}
.link_instruction:hover {
  box-shadow: 0px 0px 36px rgba(168, 255, 0, 0.48);
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}
.bot_list {
  list-style-type: none;
  margin-top: 1vw;
}
.butt_sm {
  position: absolute;
  width: 9vw;
  top: 14vw;
  left: 40vw;
  cursor: pointer;
  -webkit-animation: shake 2s infinite;
          animation: shake 2s infinite;
}
.bot_list li {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.05vw;
  margin-bottom: 0.5vw;
}
.bot_list li img {
  margin-right: 1vw;
  width: 2.2vw;
}
.bot_buttons {
  margin-top: 2.3vw;
  display: flex;
  justify-content: space-between;
}
.bot_buttons div a {
  background: rgba(31, 29, 33, 0.73);
  border: 2px solid #a8ff00;
  box-shadow: 0px 0px 26px rgba(168, 255, 0, 0.2);
  border-radius: 2.8vw;
  padding: 1.3vw 4vw;
  color: #fff;
  display: block;
  margin-left: 1.5vw;
  font-size: 1vw;
}
.bot_buttons div {
  display: flex;
  margin-right: -3vw;
}
.bot_buttons div a:hover {
  box-shadow: 0px 0px 36px rgba(168, 255, 0, 0.4);
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
}
.youtubeBot {
  position: absolute;
  width: 50vw;
  height: 28.2vw;
  bottom: 18.8vw;
  border-radius: 0 !important;
}
.vid {
  width: 100vw;
}
.bot_video {
  position: relative;
  background: #201c24;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.razd {
  width: 80vw;
}
@media screen and (max-width: 800px) {
  .header_bot {
    background: url(/static/media/mob.50f1df31.png);
    background-size: cover;
    padding-top: 36vw;
    padding-bottom: 23vw;
    height: auto;
  }
  .butt_sm {
    position: absolute;
    width: 28vw;
    top: 136vw;
    left: 32vw;
    cursor: pointer;
    -webkit-animation: shake 2s infinite;
            animation: shake 2s infinite;
  }
  .youtubeBot1 {
    width: 90vw;
    height: 60vw;
    border-radius: 0 !important;
  }
  .closeme {
    width: 7vw;
    margin-bottom: 4vw;
    margin-left: 80vw;
  }
  .header_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  .header_title span {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 600;
    font-size: 6vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    margin-left: 1vw;
    margin-bottom: 1.6vw;
  }
  .header_title h1 {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 700;
    font-size: 18vw;
    margin-top: 1vw;
  }
  .header_bot_body {
    width: 90vw;
  }
  .bot_sub {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 900;
    font-size: 9.5vw;
    margin-top: 0;
    text-align: center;
    color: #a8ff00;
  }
  .x1_bot {
    font-family: "RoadRadio";
    font-style: normal;
    font-weight: 700;
    font-size: 6.5vw;
    margin: 0 auto;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(142, 0, 253, 0.8) 28.23%,
        rgba(142, 0, 253, 0) 100%
      ),
      radial-gradient(
          94.78% 94.78% at 49.32% 5.22%,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0) 49.95%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(
          65.67% 65.67% at 52.27% 146.27%,
          rgba(0, 0, 0, 0.2) 62.89%,
          rgba(0, 0, 0, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #561ad3;
    border-radius: 11.5vw;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding: 3.5vw 8.5vw;
    color: #ffffff;
    margin-top: 4.5vw;
    text-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  }
  .product {
    color: #fff;
    font-size: 5.6vw;
    font-weight: 900;
    margin-top: 109.5vw;
  }
  .bot_list li {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 4.05vw;
    width: 55vw;
    margin-bottom: 2vw;
  }
  .bot_list li img {
    margin-right: 2vw;
    width: 7.2vw;
  }
  .bot_list {
    list-style-type: none;
    margin-top: 4vw;
  }
  .bot_buttons {
    margin-top: 2.3vw;
    display: flex;
    flex-direction: column-reverse;
  }
  .bot_buttons div {
    flex-direction: column;
  }
  .link_instruction {
    background: rgba(31, 29, 33, 0.73);
    border: 2px solid #a8ff00;
    box-shadow: 0px 0px 26px rgb(168 255 0 / 20%);
    border-radius: 23.8vw;
    padding: 5vw 4vw;
    color: #fff;
    display: block;
    margin-left: 1.5vw;
    font-size: 6.2vw;
    text-align: center;
    width: 70vw;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 7vw;
  }
  .bot_buttons div a {
    background: #a8ff00;
    box-shadow: 0px 0px 26px rgb(168 255 0 / 38%);
    border-radius: 26.8vw;
    font-size: 6.2vw;
    padding: 5vw 4vw;
    color: black;
    display: block;
    margin: 0 auto;
    margin-top: 8vw;
    width: 70vw;
    font-weight: 700;
    text-align: center;
  }
  .bot_buttons div {
    display: flex;
    margin-right: 0;
  }
  .youtubeBot {
    position: absolute;
    width: 92vw;
    left: 3.5vw;
    height: 51.5vw;
    bottom: 29.8vw;
    border-radius: 0 !important;
  }
  .razd {
    width: 90vw;
  }
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.inst {
  margin: 0 auto;
  display: block;
  width: 50vw;
  margin-bottom: 7vw;
}
.hinst {
  font-size: 3vw;
  font-weight: bold;
  color: #fff;
  width: 50vw;
  text-align: center;
  margin-bottom: 0.5vw;
}
@media screen and (max-width: 800px) {
  .inst {
    margin: 0 auto;
    display: block;
    width: 80vw;
    margin-bottom: 7vw;
  }
  .hinst {
    font-size: 5.2vw;
    font-weight: bold;
    color: #fff;
    width: 90vw;

    top: 0;
    text-align: center;
    margin-bottom: 0;
    margin-top: 7vw;
  }
}

