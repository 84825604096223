.burger_menu_container {
  position: fixed;
  width: 0vw;
  height: 100vh;
  display: flex;
  top: 0vw;
  left: 0vw;
  flex-direction: column;
  background: rgba(0, 0, 0, 0);
  transition: 0.5s;
  align-self: flex-start;
  z-index: 10000;
  overflow-y: scroll;
}
.pod_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 0;
}
.pod_menu a {
  width: 60% !important;
  justify-content: flex-end !important;
  margin-top: 0 !important;
}
.burger_menu_links {
  position: fixed;
  display: none;
  pointer-events: all;
  flex-direction: column;
  width: 50vw;
  right: -50vw;
  height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
  padding: 20vw 5vw 5vw 7vw;
  transition: 0.5s;
}
.burger_menu_links a:first-of-type {
  margin-top: 8vw;
}
.burger_menu_links a,
.lir {
  width: 100%;
  background: #702ef7;
  margin-bottom: 4.5vw;
  padding: 2.7vw 0;
  border-radius: 1.5vw;
  font-size: 3.6vw;
  color: #ffffff;
  transition: 0.5s;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.right_links {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
}
.list_dot {
  margin: 0 2vw;
  width: 1.25vw;
  height: 1.25vw;
  border-radius: 10vw;
  background: #d0baff;
}
.ultronMiniLogo {
  width: 100%;
  margin-top: auto;
  padding-bottom: 10vw;
}
