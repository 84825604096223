.promotion_how_it_works_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 15vw;
    background: url(../../../img/welcomeBackground.png);
    background-size: cover;
}
.promotion_how_it_works_title{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 6vw;
}
.videoPlaceholder{
    width: 90%;
    margin-bottom: 4vw;
}
.promotion_how_it_works_text{
    width: 90%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.35vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
    margin-top: 2vw;
}