.analytics_page_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 12vw 5vw 5vw 5vw;
    background: url(../../img/welcomeBackground.png);
    background-size: cover;
}
.analytics_title{
    align-self: flex-start;
    font-size: 10vw;
    line-height: 8vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 3vw;
}
.analyticsImg{
    width: 49vw;
    margin-bottom: 5vw;
    transform: rotateZ(0deg) scale(0);
}
.analytics_row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2.5vw;
    margin-bottom: 5.5vw;
}
.analytics_part_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw;
    border-radius: 2.5vw;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.analytics_part_containerBig{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 49vw;
    border-radius: 2.5vw;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.analytics_part_containerHalfbig{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
    border-radius: 2.5vw;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.analytics_part_titleOne{
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);  
    color: #ffffff;
    font-size: 3.6vw;
    height: 9vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.analytics_part_titleTwo{
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);  
    color: #ffffff;
    font-size: 3.6vw;
    height: 6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.analytics_whitesquare_box{
    width: 100%;
    height: 15vw;
    background: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
}
.analytics_whitesquare_box img{
    width: 14vw;
}
.analytics_part_bottom_button{
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    width: 100%;
    border: none;
    outline: none;
    color: #ffffff;
    padding: 1.5vw 0;
    font-size: 3.3vw;
    text-align: center;
}
.analytics_part_bottom_button:link {
    color: #ffffff;
}
.analytics_part_bottom_button:visited {
    color: #ffffff;
}
.analytics_part_bottom_button:hover {
    color: #ffffff;
}
.analytics_part_bottom_button:active {
    color: #ffffff;
}
.firstDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.05s;
}
.secDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.1s;
}
.thirdDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.15s;
}
.fourthDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.2s;
}
.fifthDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.25s;
}
.sixthDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.3s;
}
.seventhDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.35s;
}
.eithDelayRef{
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.4s;
}