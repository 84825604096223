.login_page_container {
  padding: 5vw 3.5vw 5vw 3.5vw;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: url(../../img/welcomeBackground.png);
  background-size: cover;
}
.login_title {
  font-family: "TerminatorGenisys";
  font-size: 8vw;
  margin-bottom: 3vw;
  text-align: center;
}
.login_info {
  text-align: center;
  letter-spacing: 0.05vw;
  font-size: 4.3vw;
}
.ultron_logo {
  transform: rotateZ(0deg) scale(0);
  width: 73vw;
  margin: -8vw 0 -6vw 0;
}
.login_page_container > input {
  width: 100%;
  padding: 4vw 2vw 4vw 5vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
  transform: scale(0);
  transition-delay: 0.05s;
}
.login_page_container > input:last-of-type {
  margin-bottom: 8vw;
  transition-delay: 0.1s;
}
.button_login_on_box {
  width: 100%;
  transform: scale(0);
  transition-delay: 0.15s;
  margin-bottom: 10vw;
}
.button_login_on {
  width: 100%;
  border: none;
  font-size: 5.5vw;
  padding: 3.6vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
}
.button_login_on:hover {
  background: #eae5f3;
  color: #702ef7;
}
.forgot_password {
  background: none;
  border: none;
  font-size: 3.8vw;
  align-self: flex-start;
  margin-top: 3vw;
  margin-bottom: 4vw;
  transform: scale(0);
  transition-delay: 0.2s;
}
.button_go_to_registration {
  width: 100%;
  padding: 3.6vw 0;
  border-radius: 2vw;
  font-size: 5.5vw;
  text-align: center;
  background: linear-gradient(0deg, #b891ff, #b891ff),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  color: #ffffff;
  border: none;
  transform: scale(0);
  transition-delay: 0.25s;
}
.button_go_to_registration:hover {
  background: #702ef7;
  color: #eae5f3;
}
.ultron_text_logo {
  margin-bottom: 5vw;
  margin-top: auto;
  font-size: 7vw;
  font-weight: 900;
  letter-spacing: 0.6vw;
  color: #23016a;
}
.help_button_on_login {
  color: #1b1b1b;
  position: absolute;
  right: 3.5vw;
  top: 3vw;
  transform: scale(0);
  transition-delay: 1s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.help_button_on_login:link {
  color: #1b1b1b;
}
.help_button_on_login:visited {
  color: #1b1b1b;
}
.help_button_on_login:hover {
  color: #1b1b1b;
}
.help_button_on_login:active {
  color: #1b1b1b;
}
.help_button_on_login > img {
  width: 8.5vw;
  border-radius: 50%;
  box-shadow: 0px 1px 8px 0px rgba(27, 104, 0, 0.25);
}
.help_button_on_login > p {
  font-size: 3.5vw;
  font-weight: 900;
  margin-top: 1vw;
}

.button_continue_login {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}
.button_continue_login:hover {
  background: #eae5f3;
  color: #702ef7;
}
.loginpass_popup_text {
  text-align: center;
  font-weight: 900;
  font-size: 4.5vw;
  margin-bottom: 3.5vh;
}
