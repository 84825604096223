.where_to_start_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    align-items: center;
    padding: 0 5vw;
    padding-top: 12vw;
    padding-bottom: 5vw;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
}
.where_to_start_title{
    font-size: 9.6vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 2vw;
}
.where_to_start_undertitle{
    font-size: 4.1vw;
    width: 92%;
    text-align: center;
    margin-bottom: 3vw;
}
.start_part_img{
    width: 25vw;
    transform: rotateZ(0deg) scale(0);
}
.start_part_imgBig{
    width: 23vw;
    margin-left: 1.7vw;
    transform: rotateZ(0deg) scale(0);
}
.watch_video{
    width: 45vw;
    padding: 2.4vw 0;
    border: none;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    color: #ffffff;
    border-radius: 2vw;
    text-align: center;
    font-size: 3.6vw;
}
.watch_video:link {
    color: #ffffff;
  }
  .watch_video:visited {
    color: #ffffff;
  }
  .watch_video:hover {
    color: #ffffff;
  }
  .watch_video:active {
    color: #ffffff;
  } 
.video_size{
    width: "100%";
    height: "1vh";
}
.video_automatization_container{
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
  padding-top: 12vw;
  /* background: url(../../img/databasebackground.png);
  background-size: cover; */
}