.sixth_step_start_container{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 2.6vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.sixth_step_title{
    width: 21vw;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 4.4vw;
    font-size: 3.6vw;
    letter-spacing: 0vw;
    font-weight: 900;
    text-align: center;
    position: relative;
}
.sixth_step_start_container > img{
    width: 16.8vw;
    margin-bottom: 2.2vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.25s;
}
.sixth_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}