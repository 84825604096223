.resources_down_part_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 24.5vw;
  border-radius: 2.5vw;
  overflow: hidden;
  box-shadow: 1px 6px 16px 0px rgb(141 85 175 / 25%);
  margin: 0 2vw 3vw 2vw;
}
.down_part_title {
  width: 100%;
  text-align: center;
  background: linear-gradient(
    94.5deg,
    rgba(86, 26, 211, 0.6) 26.56%,
    rgba(83, 0, 253, 0.48) 100.53%
  );
  color: #ffffff;
  font-size: 2.6vw;
  height: 14vw;
  padding: 0 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.whitesquare_box {
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw 0;
  height: 16vw;
}
.whitesquare_box_img {
  width: 52%;
}
.bottom_view_button {
  background: linear-gradient(
    98deg,
    rgba(58, 205, 6, 1) 0%,
    rgba(88, 231, 0, 1) 100%
  );
  width: 100%;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 1.5vw 0;
  font-size: 3.2vw;
  text-align: center;
}
.bottom_view_button:link {
  color: #ffffff;
}
.bottom_view_button:visited {
  color: #ffffff;
}
.bottom_view_button:hover {
  color: #ffffff;
}
.bottom_view_button:active {
  color: #ffffff;
}
