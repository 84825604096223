.profile_structures_container {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13vw 4vw 5vw 4vw;
  background: url(../../img/fullfon.png);
  background-size: cover;
  overflow-y: scroll;
}
.structures_title {
  font-size: 10vw;
  font-family: "TerminatorGenisys";
  margin-bottom: 6vw;
}
.who_invited_you_box {
  display: flex;
  align-self: flex-start;
  align-items: center;
  height: 15vw;
  width: 74vw;
}
.who_invited_you_photo {
  z-index: 100;
  width: 15vw;
  height: 15vw;
  padding: 0.5vw;
  border: 1vw solid#5619D3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #ffffff;
}
.who_invited_you_photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.who_invited_you_names_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.who_invited_you {
  text-align: right;
  font-size: 3.7vw;
  font-weight: 900;
  width: 66%;
  border-radius: 3vw;
  background: #eae5f3;
  z-index: 75;
  padding: 1vw 0;
  padding-right: 6vw;
  margin-left: 5vw;
}
.who_invited_you_name_ID {
  display: flex;
  z-index: 85;
}
.who_invited_you_name {
  background: #561ad3;
  margin-left: 5vw;
  padding: 0.5vw 0;
  text-align: right;
  font-size: 3.7vw;
  border-radius: 3vw;
  width: 66%;
  color: #ffffff;
  padding-right: 3vw;
}
.who_invited_you_ID {
  background: #aa82fb;
  color: #ffffff;
  padding: 0.5vw 0;
  padding-left: 7vw;
  padding-right: 1vw;
  border-radius: 3vw;
  font-size: 3.7vw;
  width: fit-content;
  z-index: -1;
  text-align: left;
  position: absolute;
  left: 65%;
}
.structures_main_content {
  margin-top: 7vw;
  width: 100%;
}
.main_content_upper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.you_photo {
  z-index: 100;
  width: 31vw;
  height: 31vw;
  padding: 0.5vw;
  border: 1.3vw solid#5619D3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.you_photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.structure_downloader {
  right: -4vw;
  background: #eae5f3;
  width: 56vw;
  position: absolute;
  border-radius: 5vw 0 0 5vw;
  padding-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.structure_downloader_text {
  font-size: 3.6vw;
  text-align: center;
  padding: 0 2vw;
  font-weight: 900;
  margin-bottom: 1.5vw;
}
.structure_downloader_button {
  width: 35vw;
  border-radius: 3vw;
  border: none;
  background: #3be100;
  color: #ffffff;
  font-size: 4.1vw;
  padding: 2.5vw 0;
  margin-left: 2vw;
  margin-bottom: 4vw;
  text-align: center;
}
.structure_downloader_button:link {
  color: #ffffff;
}
.structure_downloader_button:visited {
  color: #ffffff;
}
.structure_downloader_button:hover {
  color: #ffffff;
}
.structure_downloader_button:active {
  color: #ffffff;
}
.main_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1vw;
}
.main_content_web_you {
  margin-left: 9vw;
  font-size: 4.2vw;
  font-weight: 900;
  padding: 0.7vw 3.4vw;
  border: 0.1vw solid #ab8dea;
  width: fit-content;
  border-radius: 1.5vw;
  align-self: flex-start;
  margin-bottom: -0.5vw;
}
.structuresBranch {
  width: 76%;
}
.invited_peoples {
  display: flex;
  width: 100%;
  align-self: flex-start;
  justify-content: space-between;
  padding: 0 0.5vw;
}
.invited_photo {
  z-index: 1;
  position: relative;
  width: 20vw;
  height: 20vw;
  padding: 0.5vw;
  border: 1.3vw solid#5619D3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-top: -3vw;
}
.invited_photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.invited_about {
  width: 22vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.invited_name {
  margin-top: 2vw;
  line-height: 3vw;
  text-align: center;
  font-size: 3vw;
}
.invited_ID {
  text-align: center;
  font-size: 3vw;
  font-weight: 900;
  background: #eae5f3;
  padding: 0vw 2vw;
  border-radius: 2vw;
}
.how_much_invited {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 4vw;
  background: #ffffff;
  box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
  border-radius: 3vw;
  padding-bottom: 3vw;
}
.how_much_invited_title {
  background: linear-gradient(
      94.5deg,
      rgba(86, 26, 211, 0.6) 26.56%,
      rgba(83, 0, 253, 0.48) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  color: #ffffff;
  font-size: 4.1vw;
  border-radius: 0 0 2vw 2vw;
  padding: 3vw 0;
  width: 80%;
  text-align: center;
  margin-bottom: 3vw;
}
.how_much_invited_amount {
  width: fit-content;
  padding: 1vw 5vw;
  border-radius: 3vw;
  font-size: 6.2vw;
  font-weight: 900;
  text-align: center;
  background: #eae5f3;
}
.no_invited_box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.no_invited {
  font-size: 6.5vw;
  font-weight: 900;
  color: #5619d3;
  margin-top: 15vw;
  text-align: center;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.structures_list_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13vw 6vw 5vw 6vw;
  background: url(../../img/welcomeBackground.png);
  background-position: 0 0;
  background-size: cover;
}
.structures_list_part_container {
  width: 100%;
  border-radius: 3vw;
  background: #ffffff;
  box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3vw;
  margin-bottom: 6vw;
}
.list_part_name_id_box {
  width: 100%;
  border-radius: 3vw 3vw 0 0;
  display: flex;
}
.list_part_name_box {
  background: linear-gradient(
      0deg,
      rgba(86, 26, 211, 0.6),
      rgba(86, 26, 211, 0.6)
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  border-radius: 3vw 0 3vw 0;
  padding: 2vw 0vw 1vw 3vw;
  width: 100%;
}
.list_part_name {
  color: #ffffff;
  font-size: 4.5vw;
}
.list_part_invitation {
  color: #ffffff;
  font-size: 3vw;
}
.list_part_id {
  width: 30vw;
  background: rgba(234, 229, 243, 1);
  height: 7vw;
  border-radius: 0 3vw 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
  font-weight: 900;
}
.list_part_refLink_box {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 92%;
  overflow: hidden;
  margin-top: 3vw;
  background: rgba(234, 229, 243, 1);
  border-radius: 2vw;
  font-size: 3.3vw;
  height: 7vw;
  padding: 1.2vw 0 1.2vw 3vw;
}
.list_part_refLink {
  width: 60%;
  background: none;
  outline: none;
  border: none;
  font-size: 3.3vw;
}
.list_part_copyLink {
  position: absolute;
  width: 30vw;
  right: 0;
  top: 0;
  border-radius: 2vw;
  font-size: 3.1vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  padding: 0vw 1.5vw;
  background: rgba(59, 225, 0, 1);
}
.list_part_copyLink img {
  width: 3.6vw;
}
.list_part_copyLinkIMG {
  pointer-events: none;
}
