.second_step_start_container{
    background: #ffffff;
    width: 49vw;
    height: 100%;
    padding: 1vw 0vw 0vw 0vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.second_step_title{
    width: 100%;
    line-height: 4.4vw;
    font-size: 4vw;
    padding: 5vw 0;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.second_step_start_container > img{
    width: 24.5vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.05s;

}
.animatedImgPromotionTwo{
    animation: SecondStepImg 6s infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-delay: 5s;
}
@keyframes SecondStepImg{
    0%{
        transform: scale(1);
    }
    18%{
        transform: scale(1);
    }
    24%{
        transform: scale(0.9);
    }
    30%{
        transform: scale(1.1);
    }
    36%{
        transform: scale(1);
    }
    100%{
        transform: scale(1);
    }
}
.second_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}