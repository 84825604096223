.promotion_marketing_mavie_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vw 5vw 5vw 5vw;
    background: url(../../../img/welcomeBackground.png);
    background-size: cover;
}
.promotion_marketing_mavie_title{
    font-size: 10vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
}
.marketingMavieImg{
    width: 45vw;
    margin-bottom: 3vw;
}
.promotion_marketing_mavie_text{
    width: 100%;
    text-align: center;
    font-size: 3.15vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
}
.link_to_main_video_promotion{
    width: 100%;
    border-radius: 3vw;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    margin-bottom: 4vw;
}
.link_to_main_video_promotion > p{
    font-size: 3.8vw;
    padding: 1vw 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
}
.link_to_main_video_promotion_footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.link_to_main_video_promotion_footer > p{
    padding: 2vw 0 2vw 4vw;
    font-size: 3.4vw;
}
.link_to_main_video_promotion_footer > button{
    background: rgba(59, 225, 0, 1);
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 3.7vw;
    border-radius: 0 0 3vw 0;
    padding: 1vw 3.5vw;
}
.marketingMavieSteps{
    position: relative;
}
.marketingMavieSteps > img{
    width: 28vw;
}
.marketingMavieSteps_numbers{
    position: absolute;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    font-size: 9vw;
    
}
.marketingMavieSteps{
    display: flex;
    justify-content: center;
    align-items: center;
}
.marketingMavieSteps1{
    left: -18%;
    top: 20%;
}
.marketingMavieSteps2{
    top: -14%;
}
.marketingMavieSteps3{
    right: -22%;
    top: 15%;
}
.work_in_team_first_steps{
    width: 100%;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding-top: 2vw;
    border-radius: 3vw;
    margin-bottom: 3vw;
}
.work_in_team_first_steps_text{
    font-size: 4.1vw;
    color: rgba(86, 26, 211, 1);
    font-weight: 900;
    margin: 2vw 0;
}
.work_in_team_first_steps_button{
    width: 100%;
    background: rgba(59, 225, 0, 1);
    text-align: center;
    color: #ffffff;
    padding: 2vw;
    border-radius: 0 0 3vw 3vw;
    font-size: 3.5vw;
    color: #ffffff;
}
.work_in_team_first_steps_button:link {
    color: #ffffff;
}
.work_in_team_first_steps_button:visited {
    color: #ffffff;
}
.work_in_team_first_steps_button:hover {
    color: #ffffff;
}
.work_in_team_first_steps_button:active {
    color: #ffffff;
}
.work_in_team_first_steps_bottom{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.work_in_team_steps_bottom{
    width: 47%;
    padding-top: 4vw;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3.5vw;
}
.work_in_team_steps_text{
    font-size: 4vw;
    text-align: center;
    color: rgba(86, 26, 211, 1);
    font-weight: 900;

}
.work_in_team_steps_text_versions{
    font-size: 3.7vw;
    margin-bottom: 1vw;
}
.mavieIcon{
    width: 13.5vw;
    margin-bottom: 1.5vw;
}