.database_page_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    padding: 12vw 5vw 5vw 5vw;
    background: url(../../img/fullfon.png);
    background-size: cover;
    overflow-y: scroll;
}
.database_ultron_search_input_box{
    width: 57%;
    position: absolute;
    top: 3vw;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10;
}
.database_ultron_search_input_box_box{
    margin-right: 6vw;
}
.database_ultron_search_input{
    width: 45vw;
    border: none;
    outline: none;
    font-size: 4vw;
    padding-bottom: 0.75vw;
    border-bottom: 0.2vw solid #7230FA;
}
.database_ultron_search_input_img{
    width: 4vw;
}
.searcher_box{
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
    align-items: center;
    background: rgba(234, 229, 243, 0.9);
}
.searcher_result_box{
    background: #ffffff;
    width: 85%;
    border-radius: 3vw;
    padding: 4vw 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5vw;
}
.searcher_result_box:first-of-type{
    margin-top: 2.5vw;
}
.searcher_result_box > p{
    font-size: 4.4vw;
    width: 60%;
}
.searcher_result_box > button{
    padding: 2vw 2.5vw;
    color: #ffffff;
    font-size: 4.4vw;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 1.8vw;
    border: none;
}
.searcher_result_box > a{
    padding: 2vw 2.5vw;
    color: #ffffff;
    font-size: 4.4vw;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    border-radius: 1.8vw;
    border: none;
}
.searcher_result_box > a:link {
  color: #ffffff;
}
.searcher_result_box > a:visited {
  color: #ffffff;
}
.searcher_result_box > a:hover {
  color: #ffffff;
}
.searcher_result_box > a:active {
  color: #ffffff;
}




.database_page_title{
    font-size: 9.8vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    text-align: center;
}
.row_database_buttons{
    display: flex;
    width: 100%;
    margin-top: 4.2vw;
    justify-content: space-between;
}
.row_database_buttons:last-of-type{
    padding-bottom: 5vw;
}
/* .database_page_container .step_title{
    font-size: 10vw;
} */
.background_numbers{
    position: absolute;
    width: 12vw;
    transform: translateY(-50%);
    top: 50%;
}