.profile_page_container{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vw 5vw 5vw 5vw;
    background: url(../../img/fullfon.png);
    background-size: cover;
    overflow-y: scroll;
}
.profile_page_container > button{
    width: 100%;
    border-radius: 1.5vw;
    border: none;
    background: #3BE100;
    margin-bottom: 3vw;
    color: #ffffff;
    font-size: 4vw;
    padding: 4vw 0;
}
.profile_header_buttons{
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buttonToName{
    font-size: 2.6vw;
}
.buttonToProfileSettings{
    width: 15vw;
}
.header_button{
    margin-bottom: 9vw;
    transform: scale(0);
}
.profile_page_container > input{
    width: 100%;
    padding: 4vw 2vw 4vw 5vw;
    margin-bottom: 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 1.5vw;
    font-weight: 900;
    font-size: 4vw;
    text-transform: none;
}
.profile_page_container > input:focus-visible{
    outline: 0.1vw solid #702EF7;
}
.profile_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
}
.profile_photo_box{
    padding: 0.5vw;
    border-radius: 50%;
    width: 31vw;
    height: 31vw;
    border: 1.3vw solid rgba(112, 47, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 4.5vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.0s;
}
.firstInput{
    transform: scale(0);
    transition-delay: 0.05s;
}
.secInput{
    transform: scale(0);
    transition-delay: 0.1s;
}
.thirdInput{
    transform: scale(0);
    transition-delay: 0.15s;
}
.fifthInput{
    transform: scale(0);
    transition-delay: 0.25s;
}
.sixthInput{
    transform: scale(0);
    transition-delay: 0.3s;
}
.sevenInput{
    transform: scale(0);
    transition-delay: 0.35s;
}
.profile_photo{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}
.pencil_photo_profile{
    width: 11vw;
    position: absolute;
    bottom: 0;
    right: -4vw;
}
.referal_box{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 24.5vw;
    background: #ffffff;
    margin-bottom: 4.5vw;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.profileBoxRef1{
    transform: scale(0);
    transition-delay: 0.05s;
}
.profileBoxRef2{
    transform: scale(0);
    transition-delay: 0.1s;
}
.profileBoxRef3{
    transform: scale(0);
    transition-delay: 0.15s;
}
.profileBoxRef4{
    transform: scale(0);
    transition-delay: 0.2s;
}
.refDelay2{
    transform: scale(0);
    transition-delay: 0.2s;
}
.referal_box_title{
    text-align: center;
    color: #ffffff;
    font-size: 5.1vw;
}
.referal_box_title2{
    text-align: center;
    color: #ffffff;
    font-size: 5.1vw;
    padding: 2.2vw 0;
    border-radius: 2.5vw 2.5vw 0 0;
    background: linear-gradient(94.5deg, rgba(65, 18, 162, 0.6) 46.21%, rgba(83, 0, 253, 0.48) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
}
.profile_part_header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw 0;
    border-radius: 2.5vw 2.5vw 0 0;
    background: linear-gradient(94.5deg, rgba(152, 26, 211, 0.8) 26.56%, rgba(0, 25, 253, 0.64) 100.53%),
linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
    position: relative;
}
.profile_icons{
    width: 6vw;
    margin-right: 1.5vw;
}
.profile_icons2{
    width: 6.5vw;
    position: absolute;
    right: 3vw;
}
.referal_link{
    width: 100%;
    padding: 3vw 2.5vw;
    background: #ffffff;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 2.5vw;
}
.referal_link_input{
    width: 100%;
    padding: 2.2vw 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 2vw;
    font-size: 3.8vw;
    text-transform: none;
}
.referal_link_sec_input{
    width: 100%;
    padding: 2.2vw 3vw;
    background: #EAE5F3;
    border: none;
    border-radius: 2.5vw;
    font-size: 3.8vw;
    text-transform: none;
}
.referal_link_sec_input:focus-visible{
    outline: 0.1vw solid #702EF7;
}
.referal_link button{
    position: absolute;
    right: 2.5vw;
    background: linear-gradient(110deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    border-radius: 2vw;
    font-size: 3.5vw;
    padding: 2.2vw 1.5vw;
    color: #ffffff;
    border: none;
}
.qr_code_box{
    display: flex;
    background: #EAE5F3;
    border-radius: 3vw;
    justify-content: center;
    transform: scale(0);
    transition-delay: 0.25s;
}
.qr_code_box > svg{
    width: 24vw;
    height: 24vw;
    margin-right: 7vw;
}
.your_qr_code{
    padding: 3vw 1vw 3vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.your_qr_code p{
    margin-top: 1vw;
    font-size: 6vw;
    width: 23vw;
    font-weight: 900;
    line-height: 4.6vw;
}
.your_qr_code img{
    width: 13vw;
}
.qrcode_placeholder_box{
    padding: 2vw;
    margin-right: 2vw;
    position: relative;
}
.qrcode_placeholder{
    width: 38vw;
    padding: 2.5vw 0 1vw 2vw;
    font-size: 3.5vw;
    border: 0.1vw solid rgba(210, 200, 227, 1);
    border-radius: 3vw;
}
.qrcodeplaceholderArrows{
    position: absolute;
    width: 6vw;
    top: 5vw;
    right: -1vw;
}