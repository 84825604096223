.percentfill_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 2.5vw;
  overflow: hidden;
  border-radius: 2vw;
  margin-bottom: 5vw;
}
.percentinfo_bar{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(119, 252, 132, 1);
  height: 100%;
  z-index: 1;
  border-radius: 5vw 0 0 5vw;
}
.percentfill_box > p{
  font-size: 4vw;
  z-index: 2;
  padding: 0.25vw 0;
}
.error_popup_box{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 20;
  background: rgba(217, 217, 217, 0.5);
  position: absolute;
  transition: 0.5s;
}
.error_popup{
  background: #ffffff;
  width: 75%;
  padding: 4vh 3vw;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 6px 30px 0px rgba(79, 61, 130, 0.4);
  border-radius: 4vw;
}
.error_popup_title{
  text-align: center;
  font-weight: 900;
  font-size: 6.5vw;
  margin-bottom: 4vh;
}
.error_popup_text{
  text-align: center;
  font-weight: 900;
  font-size: 5.5vw;
  margin-bottom: 5vh;

}
.error_popup_button{
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  color: #ffffff;
  font-size: 3.9vw;
  padding: 1.2vh 5vw;
  border-radius: 2vw;
}

.welcome_popup_box{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 20;
  background: rgba(217, 217, 217, 0.5);
  position: fixed;
  transition: 0.5s;
}
.welcome_popup{
  background: #ffffff;
  width: 75%;
  padding: 4vh 3vw;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 6px 30px 0px rgba(79, 61, 130, 0.4);
  border-radius: 4vw;
}
.welcome_popup_title{
  text-align: center;
  font-weight: 900;
  font-size: 6.5vw;
  margin-bottom: 4vh;
}
.welcome_popup_text{
  text-align: center;
  font-weight: 900;
  font-size: 5vw;
  margin-bottom: 5vh;

}
.welcome_popup_button{
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  color: #ffffff;
  font-size: 3.9vw;
  padding: 1.2vh 5vw;
  border-radius: 2vw;
}
.regdone_popup_title{
  text-align: center;
  font-weight: 900;
  font-size: 6.5vw;
  margin-bottom: 2vh;
}
.regdone_popup_text1{
  text-align: center;
  font-size: 3.5vw;
  margin-bottom: 1.5vh;
}
.regdone_popup_text2{
  text-align: center;
  font-weight: 900;
  font-size: 4vw;
  margin-bottom: 3.5vh;
}



.registration_pass_page_container{
  padding: 5vw 3.5vw 5vw 3.5vw;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: url(../../img/welcomeBackground.png);
  background-size: cover;
}
.inputs_box{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registration_page_container {
  padding: 5vw 3.5vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../../img/welcomeBackground.png);
  background-size: cover;
  overflow-y: scroll;
}
.help {
  position: absolute;
  right: 3.5vw;
  width: auto;
  top: 50vw;
  text-align: right;
}
.help img {
  width: 12vw;
}
.help p {
  margin-top: -2vw;
  font-size: 3.5vw;
  font-weight: bold;
}
.videoplaceholder{
  width: 75%;
  margin-bottom: 0.5vh;
}
.videoplaceholder_bottom{
  font-size: 4vw;
  margin-bottom: 7vh;
}
.registration_title {
  font-family: "TerminatorGenisys";
  text-align: center;
  font-size: 8vw;
  margin-bottom: 3vw;
}
.registration_info {
  text-align: center;
  letter-spacing: 0.05vw;
  font-size: 4.3vw;
}
.ultron_logo {
  width: 73vw;
}
.short_pass {
  position: absolute;
  font-size: 3.2vw;
  right: 0vw;
  color: red;
  top: -4vw;
}
.full_inputs {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.referal_id_input {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.invited_id_box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.id_mavie {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.referal_link_mavie {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.referal_short {
  position: absolute;
  top: -4vw;
  left: 0vw;
  color: red;
  font-size: 3.2vw;
}
.registration_page_container > input:last-of-type {
  margin-bottom: 4.8vw;
}

.button_registration_on {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.5vw;
  padding: 3.4vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}
.button_registration_on:hover {
  background: #eae5f3;
  color: #702ef7;
}
.button_back_to_login {
  width: 100%;
  padding: 3.4vw 0;
  border-radius: 2vw;
  font-size: 5.5vw;
  text-align: center;
  background: #eae5f3;
  border: 0.2vw solid #eae5f3;
  transition: 0.5s;
}
.button_back_to_login:hover {
  background: #702ef7;
  color: #eae5f3;
}
.input_tel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tel_inputs,
.PhoneInput {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.tel_inputs:last-of-type {
  width: 100%;
}
.already_exists_phone {
  position: absolute;
  font-size: 3.2vw;
  left: 0vw;
  color: red;
  top: -4vw;
}
.first_input {
  transform: scale(0);
  transition-delay: 0.05s;
}
.PhoneInputInput {
  background: none;
  border: none;
  font-weight: 900;
  font-size: 4vw;
}
.sec_input {
  transform: scale(0);
  transition-delay: 0.1s;
}
.third_input {
  transform: scale(0);
  transition-delay: 0.1s;
}
.fourth_input {
  transform: scale(0);
  transition-delay: 0.15s;
}
.button_registration_on {
  transform: scale(0);
  transition-delay: 0.2s;
}
.button_back_to_login {
  transform: scale(0);
  transition-delay: 0.25s;
}

.registration_complete_container {
  width: 100vw;
  height: 100%;
  background-color: #ffffff;
  background: url(../../img/welcomeBackground.png);
  background-size: cover;
  padding: 5vh 3.5vw 1vw 3.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
}
.registration_complete_title {
  font-family: "TerminatorGenisys";
  font-size: 7.5vw;
  letter-spacing: 0.1vw;
  line-height: 5.5vw;
  text-align: center;
  margin-bottom: 4vh;
}
.sec_step_reg_mavie_input {
  width: 100%;
  padding: 4vw 2vw 4vw 3vw;
  margin-bottom: 5vw;
  background: #eae5f3;
  border: none;
  border-radius: 1.5vw;
  font-weight: 900;
  font-size: 4vw;
  text-transform: none;
}
.button_back_to_login_completed {
  width: 90%;
  position: absolute;
  bottom: 5vw;
  padding: 3.4vw 0;
  border-radius: 2vw;
  font-size: 5.5vw;
  text-align: center;
  background: #eae5f3;
  border: 0.2vw solid #eae5f3;
  transition: 0.5s;
}
.sec_step_reg_send_it {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6vw;
  transition: 0.5s;
  opacity: 0.5;
}
.dont_have_mavieID_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 3vw;
}
.dont_have_mavieID_title {
  background: linear-gradient(
      94.5deg,
      rgba(86, 26, 211, 0.6) 26.56%,
      rgba(83, 0, 253, 0.48) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  font-weight: 500;
  letter-spacing: 0.1vw;
  border-radius: 3vw 3vw 0 0;
  color: #ffffff;
  text-align: center;
  width: 100%;
  padding: 3vw 3vw 2vw 3vw;
  font-size: 5vw;
}
.dont_have_mavieID_text {
  text-align: center;
  font-size: 4.2vw;
  padding: 3vw 5vw;
}
.dont_have_mavieID_inputs_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 0 3vw 3vw;
  padding: 0 3vw;
}
.dont_have_mavieID_inviter_phone_name_box {
  width: 100%;
  display: flex;
  margin-bottom: 2vw;
  position: relative;
}
.dont_have_mavieID_inviter_phone_name_box:last-of-type {
  margin-bottom: 4vw;
}
.secStepRegIconsBox {
  background: rgba(171, 132, 253, 1);
  padding: 3.3vw 2vw;
  border-radius: 2vw 0 0 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secStepRegIconsBoxPhone {
  background: rgba(171, 132, 253, 1);
  padding: 3.6vw 2vw;
  border-radius: 2vw 0 0 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secStepRegIcons {
  width: 3.75vw;
}
.secStepRegIconsPhone {
  width: 4vw;
}
.dont_have_mavieID_inviter_name_phone {
  width: 100%;
  border-radius: 0 3vw 3vw 0;
  border: none;
  outline: none;
  background: #eae5f3;
  padding-left: 3.5vw;
  font-size: 4vw;
}
.dont_have_mavieID_inviter_phone_copy_button_box {
  display: flex;
  position: absolute;
  right: 2vw;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
.dont_have_mavieID_inviter_phone_copy_button {
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border: none;
  outline: none;
  color: #ffffff;
  padding: 1.5vw 0;
  font-size: 3.1vw;
  padding-left: 4vw;
  width: 32vw;
  border-radius: 2vw;
}
.structuresListCopy {
  position: absolute;
  width: 4vw;
  right: 1vw;
}
.videoPlaceholder_reg_sec_step {
  width: 85%;
  margin: 6vw 0 4vw 0;
}
.video_how_to_call_to_inviter {
  text-align: center;
  font-weight: 700;
  font-size: 4.4vw;
  line-height: 4.5vw;
  margin-bottom: 5vw;
  width: 100%;
}
.video_how_to_call_to_inviter > span {
  color: rgba(86, 26, 211, 1);
}
.call_tech_support {
  width: 100%;
  text-align: center;
  font-size: 3.8vw;
  margin-bottom: 5vw;
}
.call_tech_support > span {
  color: rgba(88, 231, 0, 1);
}
.send_it_reg_kurator{
  width: 100%;
  border: 0.2vw solid;
  background: linear-gradient(0deg, #B890FF, #B890FF),
  linear-gradient(95.31deg, rgba(255, 255, 255, 0.14) -0.38%, rgba(255, 255, 255, 0.12) 46.68%, rgba(148, 148, 148, 0) 100%);
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6vw;
  transition: 0.5s;

}
.send_it_reg_sec_step {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6vw;
  transition: 0.5s;
}
.send_it_reg_sec_step:link {
  color: #ffffff;
}
.send_it_reg_sec_step:visited {
  color: #ffffff;
}
.send_it_reg_sec_step:hover {
  color: #ffffff;
}
.send_it_reg_sec_step:active {
  color: #ffffff;
}

.hidden_thank_for_registration_box_background {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: rgba(18, 15, 26, 0.34);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  /* height: 143vh; */
  align-items: center;
  height: 100%;
}
.hidden_thank_for_registration_box {
  width: 87%;
  background: #ffffff;
  /* margin-top: 35vh; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4vw;
  padding: 12vw 6vw;
  box-shadow: 2px 4px 19px 5px rgba(112, 46, 247, 0.35);
}
.hidden_thank_for_registration_title {
  text-align: center;
  font-size: 8vw;
  font-weight: 900;
  margin-bottom: 2vw;
}
.hidden_thank_for_registration_text {
  text-align: center;
  font-size: 5vw;
  font-weight: 900;
  margin-bottom: 6vw;
}
.hidden_thank_for_registration_button {
  width: 100%;
  border: 0.2vw solid #702ef7;
  background: #702ef7;
  font-size: 5.1vw;
  padding: 2.7vw 0;
  border-radius: 2vw;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}
.hidden_thank_for_registration_button:link {
  color: #ffffff;
}
.hidden_thank_for_registration_button:visited {
  color: #ffffff;
}
.hidden_thank_for_registration_button:hover {
  color: #ffffff;
}
.hidden_thank_for_registration_button:active {
  color: #ffffff;
}
.registration_wait_container {
  opacity: 0;
  display: flex;
  pointer-events: none;
  width: 100vw;
  height: 100%;
  background: url(../../img/welcomeBackground.png);
  background-size: 100%;
  padding: 5vh 3.5vw 1vw 3.5vw;
  flex-direction: column;
  align-items: center;
  position: absolute;
  justify-content: center;
  box-shadow: 2px 4px 19px 0px rgba(0, 83, 139, 0.21);
}
.bottom_padding{
  padding: 3vw;
}

.help_button_on_register {
  color: #1b1b1b;
  position: absolute;
  right: 3.5vw;
  top: 40vh;
  transform: scale(0);
  transition-delay: 1s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.help_button_on_register:link {
  color: #1b1b1b;
}
.help_button_on_register:visited {
  color: #1b1b1b;
}
.help_button_on_register:hover {
  color: #1b1b1b;
}
.help_button_on_register:active {
  color: #1b1b1b;
}
.help_button_on_register > img {
  width: 8.5vw;
  border-radius: 50%;
  box-shadow: 0px 1px 8px 0px rgba(27, 104, 0, 0.25);
}
.help_button_on_register > p {
  font-size: 3.5vw;
  font-weight: 900;
  margin-top: 1vw;
}