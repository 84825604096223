.where_to_start_part_container {
  width: 100%;
  height: 26.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.2vw 0vw 2vw 4vw;
  background: #ffffff;
  margin-bottom: 3vw;
  border-radius: 3vw;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.where_to_start_part_container > img {
  width: 25vw;
}
@keyframes scalingWhereToStart {
  0% {
    transform: scale(1);
  }
  7% {
    transform: scale(1.1);
  }
  14% {
    transform: scale(1);
  }
  21% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
.start_part_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1vw;
}
.start_part_info p {
  font-size: 3.7vw;
  width: 40vw;
  line-height: 3.7vw;
  margin-bottom: 2.5vw;
  color: #1b1b1b;
}
.start_part_info button {
  width: 45vw;
  padding: 2.4vw 0;
  border: none;
  background: linear-gradient(
    98deg,
    rgba(58, 205, 6, 1) 0%,
    rgba(88, 231, 0, 1) 100%
  );
  color: #ffffff;
  border-radius: 2vw;
  font-size: 3.6vw;
}
.start_part_right_year {
  width: 1.8vw;
  height: 14vw;
  border-radius: 4vw 0 0 4vw;
  background: linear-gradient(
    30deg,
    rgba(83, 5, 244, 0.8406) 0%,
    rgba(86, 26, 211, 1) 100%
  );
  opacity: 0.6;
  display: flex;
  opacity: 0;
  align-self: center;
}
