.promotion_selling_posts_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 15vw;
    background: url(../../../img/welcomeBackground.png);
    background-size: cover;
}
.promotion_selling_posts_container > img{
    width: 90%;
}
.promotion_selling_posts_title{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 3vw;
}
.selling_posts_links_box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.selling_posts_links_doubled{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3vw;
}
.selling_posts_links_doubled_box{
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 47%;
    border-radius: 2vw;
    padding: 1.5vw 2vw 1.5vw 3.5vw;
}
.selling_posts_links_doubled_box > p{
    font-size: 3.6vw;
    font-weight: 900;
}
.arrow_button_selling_posts_links_doubled_box{
    background: #3BE100;
    width: 8.6vw;
    height: 8.6vw;
    border-radius: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_button_selling_posts_links_doubled_box img{
    width: 4.5vw;
}
.selling_posts_links_solo_box{
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 2vw;
    padding: 1.5vw 2vw 1.5vw 3.5vw;
    margin-bottom: 3vw;

}
.selling_posts_links_solo_box > p{
    font-size: 3.6vw;
    font-weight: 900;
}