.presentation_ultron_container{
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 13vw;
    /* background: url(../../../img/databasebackground.png);
    background-size: cover; */
}
.presentation_title{
    font-size: 9.8vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    
}
.presentation_text{
    font-size: 4.1vw;
    margin-bottom: 7vw;
    text-align: center;
    line-height: 4vw;
}
.presentation_button_box{
    display: flex;
    border-radius: 3vw;
    width: 100%;
    align-items: center;
    padding: 3vw 4vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
    background: #ffffff;
    margin-bottom: 3.5vw;
}
.presentation_button_box > img{
    width: 12.5vw;
    height: 12.5vw;
    padding: 2vw;
    background: linear-gradient(94.5deg,#561ad3 26.56%,rgba(83,0,253,.8) 100.53%);
    border-radius: 2.5vw;
}
.presentation_button_box > p{
    font-size: 4.7vw;
    line-height: 4.5vw;
    margin-top: 0.5vw;
    margin-left: 3vw;
    margin-right: auto;
    width: 54.5vw;
}
.arrow_button_presentation_box{
    background: #3BE100;
    width: 12.5vw;
    height: 12.5vw;
    border-radius: 2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_button_presentation_box img{
    width: 5.5vw;

}



.presentations_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    padding-top: 12vw;
    background: url(../../../img/databasebackground.png);
    background-size: 100%;
}
.presentations_containerBig{
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    padding-top: 12vw;
    background: url(../../../img/databasebackground.png);
    background-size: 100%;
}
.presentation_titles{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    color: #1B1B1B;
}
.videoPlaceholder_presentations{
    width: 86%;
    margin-bottom: 5vw;
}
.videoPlaceholder_presentations_vebinars{
    width: 86%;
}
.vebinars_part{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.presentations_text {
    width: 86%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.7vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw; 
    margin-top: 3vw;
}
.presentations_vebinars_text{
    width: 75%;
    padding: 2vw 4vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 0 0 3vw 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.7vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw; 
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.presentations_vebinars_text span{
    text-align: start;
    padding-left: 2vw;
}
.left_numbers_circles_vebinars{
    position: absolute;
    left: -4vw;
    color: #1B1B1B;
    background: #ffffff;
    border: 0.7vw solid rgba(86, 26, 211, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7vw;
    width: 8vw;
    height: 8vw;
    border-radius: 10vw;
}
.presentations_next_text{
    width: 88%;
    text-align: center;
    font-size: 4.1vw;
}
.how_make_money_text{
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0 2vw;
    margin-bottom: 5vw;
}
.how_make_money_text img{
    width: 8vw;
    margin-right: 2.5vw;
}
.how_make_money_text p {
    font-size: 3.4vw;
    line-height: 3.5vw;
}
.prognoz_money_cost{
    width: 96%;
    border: 0.2vw solid #9873E5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vw 0 2vw 0vw;
    border-radius: 3vw;
    position: relative;
    margin-top: 7vw;
}
.prognoz_money_cost img{
    position: absolute;
    top: -35%;
    width: 11vw;
}
.prognoz_money_cost p{
    font-size: 3.7vw;
    font-weight: 900;
    text-align: center;
}
.how_make_money_text_bold{
    font-weight: 900;
    font-size: 3.9vw;
    text-align: center;
    width: 79%;
    margin-top: 6vw;
    margin-bottom: 2vw;
}
.date_money_income_box{
    width: 79%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3vw;
}
.date_money_income_row{
    display: flex;
    width: 100%;
    background: rgba(224, 209, 255, 1);
}
.date_money_income_row:nth-child(even){
    background: rgba(239, 232, 255, 1);
}
.date_money_income_row:first-of-type{border-radius: 3vw 3vw 0 0;}
.date_money_income_row:last-of-type{border-radius: 0 0 3vw 3vw;}
.date_money_income_row p{
    font-size: 4vw;
    font-weight: 900;
    color: #654D96;
}
.date_money_income_row_left{
    margin-right: 1vw;
    padding: 1vw 0;
    width: 44%;
    text-align: center;
    border-right: 0.7vw solid #AC9CD1;
}
.date_money_income_row_right{
    padding: 1vw 0;
    padding-left: 3vw;
}
.how_make_money_text_common{
    width: 73%;
    margin-top: 4vw;
    font-size: 3.3vw;
    text-align: center;
}
.how_make_money_text_common span{
    color: rgba(86, 26, 211, 1);
    font-weight: 900;
}

.date_money_income_box_downer{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3vw;
}
.date_money_income_row_left_downer{
    margin-right: 1vw;
    padding: 1vw 0;
    width: 56%;
    text-align: center;
    border-right: 0.7vw solid #AC9CD1;
}
.date_money_income_row_right_downer{
    padding: 1vw 0;
    padding-left: 3vw;
}
iframe{
    border-radius: 2.5vw;
}