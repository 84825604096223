.konkurses_page_container{
    width: 100vw;
    height: 100%;
    padding: 12vw 5vw 5vw 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: url(../../img/welcomeBackground.png);
    background-position: 0 0;
    background-size: cover; */
}
.konkurses_page_title{
    font-size: 9.7vw;
    font-family: "TerminatorGenisys";
    text-align: center;
}
.konkurses_page_parachute_img{
    width: 45vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.0s;

}
.konkurses_page_text{
    background: rgba(161, 120, 243, 1);
    border-radius: 3vw;
    padding: 3vw 0vw;
    color: #ffffff;
    font-size: 4.6vw;
    margin-bottom: 5vw;
    width: 90vw;
    text-align: center;
    transform: scale(0);
    transition-delay: 0.05s;
}
.calendar_timer{
    
    transform: scale(0);
    transition-delay: 0.1s;
}
.payday{
    
    transform: scale(0);
    transition-delay: 0.15s;
}
.calendar_timer,.payday{
    width: 100%;
    padding: 2.5vw 0vw 2.5vw 2.6vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vw;
    background: #ffffff;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.payday{
    margin-bottom: 15%;
}
.calendar_timer img,.payday img{
    width: 13vw;
    margin-right: 4vw;
}
.calendar_box, .payday_box{
    display: flex;
}
.box_title{
    text-align: start;
    font-size: 5vw;
}
.box_numbers{
    font-size: 7vw;
    text-align: start;
    font-weight: 900;
}
.purple_ear{
    width: 1.5vw;
    height: 13vw;
    border-radius: 3vw 0vw 0vw 3vw;    
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}
.button_rollin{
    width: 90vw;
    text-align: center;
    padding: 5.8vw 0;
    color: #ffffff;
    font-size: 5.2vw;
    border-radius: 3vw;
    background: linear-gradient(98deg, rgba(58, 205, 6, 1) 0%, rgba(88, 231, 0, 1) 100%);
    margin-bottom: 5vw;
    transform: scale(0);
    transition-delay: 0.2s;
}