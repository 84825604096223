.arrow_button_back_container {
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  padding: 3vw;
  background: rgba(255, 255, 255, 1);
}
.arrow_button_back_container > div {
  display: flex;
  align-items: center;
}
.arrow_button_back_container > div > img {
  width: 9vw;
  margin-right: 1.5vw;
}
.arrow_button_back_container > p {
  font-size: 4vw;
  letter-spacing: -0.2vw;
}
