.first_step_start_container{
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    border-radius: 2.5vw;
    padding: 5.5vw 0vw 5.5vw 4.5vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.first_step_start_container > img{
    width: 36vw;
    transform: rotateZ(0deg) scale(0);
    animation: firstStepImg 6s infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-delay: 5s;
}
@keyframes firstStepImg{
    0%{
        transform: scale(1);
    }
    6%{
        transform: scale(0.9);
    }
    12%{
        transform: scale(1.1);
    }
    18%{
        transform: scale(1);
    }
    100%{
        transform: scale(1);
    }
}
.first_step_start_containerWantJoin{
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    border-radius: 2.5vw;
    padding: 7.5vw 0vw 7.5vw 4.5vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.first_step_start_containerWantJoin > img{
    width: 36vw;
    animation: firstStepImg 5s infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-delay: 5s;
}
.step_title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.step_title>img{
    width: 5vw;
    margin-top: 2vw;
    margin-bottom: 3vw;
}
.step_title>p{
    text-align: center;
    width: 45vw;
    line-height: 5.2vw;
    font-size: 5vw;
    font-weight: 900;
}
.first_step_start_right_year{
    width: 1.8vw;
    height: 16vw;
    border-radius: 4vw 0 0 4vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
    display: flex;
    align-self: center;
}