.promotion_where_to_start_container{
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vw 4vw 5vw 4vw;
    background: url(../../../img/welcomeBackground.png);
    background-size: cover;
}
.video_boxer{
    width: 99%;
    height: 48vw;
    margin-bottom: 2vw;
}
.promotion_where_to_start_title{
    font-size: 9vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 6vw;
}
.promotion_where_to_start_purple_text{
    width: 90%;
    padding: 2vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.4vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
    letter-spacing: 0.1vw;
}
.vosklMark{
    width: 13vw;
    margin-bottom: 2.5vw;
}
.promotion_where_to_start_text{
    width: 100%;
    text-align: center;
    font-size: 3.15vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
}
.link_to_main_video_promotion{
    width: 100%;
    border-radius: 3vw;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    margin-bottom: 4vw;
}
.link_to_main_video_promotion > p{
    font-size: 3.8vw;
    padding: 1vw 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(94.5deg, rgba(86, 26, 211, 0.6) 26.56%, rgba(83, 0, 253, 0.48) 100.53%);
    border-radius: 3vw 3vw 0 0;
    color: #ffffff;
}
.link_to_main_video_promotion_footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.link_to_main_video_promotion_footer > p{
    padding: 2vw 0 2vw 4vw;
    font-size: 3.4vw;
}
.link_to_main_video_promotion_footer > button{
    background: rgba(59, 225, 0, 1);
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 3.7vw;
    border-radius: 0 0 3vw 0;
    padding: 1vw 3.5vw;
}