.fourth_step_start_container{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 1.4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.fourth_step_start_container > img{
    width: 17.5vw;
    transform: rotateZ(0deg) scale(0);
    transition-delay: 0.15s;
}
.fourth_step_start_containerBig{
    background: #ffffff;
    width: 27vw;
    height: 39vw;
    padding-top: 1.4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 3vw;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
.fourth_step_start_containerBig > img{
    width: 22.5vw;
}
.fourth_step_title{
    width: 21vw;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 4.4vw;
    font-size: 3.6vw;
    letter-spacing: 0vw;
    font-weight: 900;
    text-align: center;
    position: relative;
}
.fourth_step_title img{
    width: 13vw;
    margin-top: 1vw;
}
.fourth_step_start_right_year{
    width: 16vw;
    height: 1.8vw;
    border-radius: 4vw 4vw 0vw 0vw;
    background: linear-gradient(30deg, rgba(83, 5, 244, 0.8406) 0%, rgba(86, 26, 211, 1) 100%);
    opacity: 0.6;
}




.steps_block_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgba(141, 85, 175, 0.25);
    padding: 4.3vw 4vw 4.3vw 6vw;
    margin-bottom: 1.75vh;
}
.steps_block_img{
    width: 26vw;
}
.steps_block_info_box{
    display: flex;
    align-items: center;
    width: fit-content;
}
.steps_block_number{
    font-family: "RoadRadio";
    font-size: 24vw;
    line-height: 10vw;
    color: rgba(83, 0, 253, 0.1);
    margin-right: 1vw;
}
.steps_block_title{
    font-weight: 900;
    font-size: 5vw;
    margin-left: 0.7vw;
}