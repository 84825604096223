.why_join_ultron_container{
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
    padding-top: 12vw;
    /* background: url(../../../img/databasebackground.png);
    background-size: cover; */
}
.why_join_ultron_title{
    font-size: 8.6vw;
    text-align: center;
    width: 100%;
    line-height: 7.5vw;
    text-transform: uppercase;
    font-family: "TerminatorGenisys";
    margin-bottom: 6vw;
    color: #1B1B1B;
}
.why_join_ultron_undertitle{
    width: 100%;
    padding: 3vw;
    background: linear-gradient(94.5deg, #9873E5 26.56%, #A178F3 100.53%);
    border-radius: 3vw;
    color: #ffffff;
    text-align: center;
    font-size: 3.35vw;
    line-height: 3.6vw;
    margin-bottom: 4.5vw;
}
.why_join_ultron_text{
    font-size: 3.3vw;
    margin-bottom: 4.5vw;
    text-align: center;
    line-height: 3.6vw;
}
.whyJoinCircle{
    width: 20.7vw;
    margin-top: 2.5vw;
    margin-bottom: 2vw;
}
.reason_project_box{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8vw;
    border-radius: 3vw;
    border: 0.2vw solid #9873E5;
    padding: 5vw 0;
    margin-bottom: 4vw;
}
.why_join_ultron_text_reason{
    font-size: 3.3vw;
    text-align: center;
    line-height: 3.6vw;
}
.reasonProjectBoxImg{
    position: absolute;
    top: -45%;
    width: 22vw;
}