.content_container_box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12vw 5vw 5vw 5vw;
  background: url(../../img/fullfon.png);
  background-size: cover;
  overflow-y: scroll;
  position: relative;
}
.link_date {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 3.5vw;

  margin: 3vw 0;
}
.link_date p {
  background: #ab84fd;
  color: #fff;
  font-family: "RoadRadio";
  font-style: normal;
  font-style: normal;
  font-weight: 900;
  font-size: 4.3vw;
  text-align: center;
  padding: 1.5vw;
  border-radius: 3vw 3vw 0 0;
}
.link_date div {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5vw 3vw;
}
.link_date div span {
  font-family: "RoadRadio";
  font-style: normal;
  font-size: 4vw;
}
.link_date a {
  font-family: "RoadRadio";
  font-style: normal;
  font-style: normal;

  font-size: 4vw;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  border-radius: 3vw;
  padding: 2.5vw 4vw;
}

.content_container_title {
  font-size: 9.8vw;
  font-family: "TerminatorGenisys";
  margin-bottom: 6vw;
  text-align: center;
  color: #1b1b1b;
}
.content_container_text1 {
  font-size: 4.2vw;
  margin-bottom: 6vw;
  text-align: center;
  color: #1b1b1b;
}
.content_container_text2 {
  font-size: 4.2vw;
  margin-bottom: 6vw;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(94.5deg, #9873e5 26.56%, #a178f3 100.53%),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  width: 100%;
  border-radius: 3vw;
  padding: 3vw 8vw;
}
.content_container_text3_box {
  width: 100%;
  border-radius: 3vw;
  margin-bottom: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
}
.content_container_text3_title {
  color: #ffffff;
  width: 100%;
  background: linear-gradient(
      94.5deg,
      rgba(86, 26, 211, 0.6) 26.56%,
      rgba(83, 0, 253, 0.48) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
  padding: 1.5vw 0;
  text-align: center;
  border-radius: 3vw 3vw 0 0;
  font-size: 4.5vw;
}
.content_container_text3_text {
  text-align: center;
  padding: 3vw;
  font-size: 4vw;
  color: #1b1b1b;
}
.content_container_subtitle {
  font-size: 5vw;
  font-weight: 900;
  text-align: center;
  margin-bottom: 6vw;
}
.content_container_img1 {
  width: 100%;
  border-radius: 3vw;
  margin-bottom: 6vw;
}
.content_container_elem_box {
  width: 100%;
  display: flex;
  margin-bottom: 6vw;
}
.content_container_elem_img {
  width: 12.5vw;
  height: 12.5vw;
  margin-right: 3vw;
  box-shadow: 6px 3px 6px 0px rgba(181, 125, 216, 0.25);
  border-radius: 4.5vw;
  background: linear-gradient(
      94.5deg,
      #561ad3 26.56%,
      rgba(83, 0, 253, 0.8) 100.53%
    ),
    linear-gradient(
      95.31deg,
      rgba(255, 255, 255, 0.14) -0.38%,
      rgba(255, 255, 255, 0.12) 46.68%,
      rgba(148, 148, 148, 0) 100%
    );
}
.content_container_elem_text {
  width: 82%;
  font-size: 4.2vw;
  line-height: 3.5vw;
  margin-top: 1.5vw;
}
.content_container_img2_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  height: 25vw;
  margin-bottom: 6vw;
}
.content_container_img2 {
  border-radius: 50%;
  border: 1vw solid rgba(86, 26, 211, 1);
  width: 25vw;
  height: 25vw;
}
.content_container_table_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(224, 209, 255, 1);
  border-radius: 3vw;
  margin-bottom: 6vw;
}
.content_container_table_section {
  width: 100%;
  display: flex;
  align-items: center;
  height: 8.5vw;
  color: rgba(101, 77, 150, 1);
  font-weight: 900;
}
.content_container_table_section > p {
  width: 50%;
  padding-left: 4vw;
  display: flex;
  align-items: center;
  border-right: 0.2vw solid rgba(172, 156, 209, 1);
  height: 100%;
}
.content_container_table_section > span {
  width: 50%;
  padding-left: 4vw;
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 0.2vw solid rgba(172, 156, 209, 1);
}
.content_container_video_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3vw;
  margin-bottom: 6vw;
}
iframe {
  width: 100%;
  height: 50vw;
}
