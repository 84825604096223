.inst {
  margin: 0 auto;
  display: block;
  width: 50vw;
  margin-bottom: 7vw;
}
.hinst {
  font-size: 3vw;
  font-weight: bold;
  color: #fff;
  width: 50vw;
  text-align: center;
  margin-bottom: 0.5vw;
}
@media screen and (max-width: 800px) {
  .inst {
    margin: 0 auto;
    display: block;
    width: 80vw;
    margin-bottom: 7vw;
  }
  .hinst {
    font-size: 5.2vw;
    font-weight: bold;
    color: #fff;
    width: 90vw;

    top: 0;
    text-align: center;
    margin-bottom: 0;
    margin-top: 7vw;
  }
}
