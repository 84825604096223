.welcome_page_container {
  padding: 4.5vw 5vw 5vw 5vw;
  padding-top: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../../img/fullfon.png);
  background-size: cover;
  overflow-y: scroll;
}
.ultronLogo {
  width: 70vw;
  margin-top: -9vw;
  margin-bottom: -7vw;
  transform: rotateZ(0deg) scale(0);
}
.welcome_title {
  text-align: center;
  font-family: "TerminatorGenisys";
  font-size: 9.5vw;
  line-height: 8.5vw;
  text-transform: uppercase;
  font-weight: 900;
  position: fixed;

  width: 100vw;
  left: 0vw;

  padding: 6vw 0;
  z-index: 2000;
  top: 2vw;
  transition: 1s;
}
.model {
  opacity: 0;
  transition: 0.5s;
}
.welcomeImg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22vw;
}
.welcomeImg_img {
  position: absolute;
  width: 53vw;
  z-index: 10;
  margin-top: 3vw;
}
.welcomeImg_blur {
  z-index: 5;
  width: 31vw;
  height: 31vw;
  position: absolute;
  background: rgba(230, 160, 255, 0.88);
  filter: blur(20.5px);
  margin-top: 3vw;
}
.welcomeImg_text {
  z-index: 2;
  position: absolute;
  color: #eae5f3;
  font-size: 16.5vw;
  font-family: "TerminatorGenisys";
}
.welcome_links_box {
  margin-top: 4vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4vw;
}
.database_link {
  background: #ffffff;
  border-radius: 3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.5vw;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(0);
  transition-delay: 0.05s;
}
.database_link:last-of-type {
  margin-bottom: 0;
}
.welcome_links_left_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 56%;
}
.database_link_img {
  width: 30vw;
  margin-bottom: 1vw;
  margin-left: -6vw;
  animation: dataBaseImg 5s infinite ease-in-out;
}
.database_link_img1 {
  animation-delay: 7s;
}
.database_link_img2 {
  animation-delay: 9.5s;
}
@keyframes dataBaseImg {
  0% {
    transform: scale(1);
  }
  12.5% {
    transform: scale(1.1);
  }
  25% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
}
.database_link p {
  font-size: 4vw;
  font-weight: 900;
  text-align: center;
  margin-bottom: 3.5vw;
}
.referal_box_welcome {
  position: relative;
  width: 100%;
  height: 24.5vw;
  background: #ffffff;
  margin-bottom: 4.5vw;
  border-radius: 3vw;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.referal_box_welcome2 {
  position: relative;
  width: 100%;
  height: 24.5vw;
  background: #ffffff;
  border-radius: 3vw;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 20%);
}
.referal_box_welcome2 > p {
  text-align: center;
  color: #ffffff;
  font-size: 4.3vw;
  padding: 2.2vw 0;
  border-radius: 3vw 3vw 0 0;
  background: linear-gradient(
    303deg,
    rgba(86, 26, 211, 0.4) 0%,
    rgba(83, 0, 253, 0.6) 100%
  );
}
.referal_link_father {
  background: linear-gradient(93.33deg, #3acd06 0.41%, #58e700 100.4%);
  color: #ffffff;
  padding: 2.5vw 11vw;
  border-radius: 2vw;
}
.referal_link_father:link {
  color: #ffffff;
}
.referal_link_father:visited {
  color: #ffffff;
}
.referal_link_father:hover {
  color: #ffffff;
}
.referal_link_father:active {
  color: #ffffff;
}
.referal_link_welcome2 {
  width: 100%;
  padding: 3vw 2.5vw;
  background: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3vw;
}
.referal_link_father_text {
  font-size: 2.8vw;
  line-height: 2.9vw;
  margin-left: 1vw;
}
.referal_box_welcome > p {
  text-align: center;
  color: #ffffff;
  font-size: 5.1vw;
  padding: 2.2vw 0;
  border-radius: 3vw 3vw 0 0;
  background: linear-gradient(
    303deg,
    rgba(86, 26, 211, 0.4) 0%,
    rgba(83, 0, 253, 0.6) 100%
  );
}
.referal_link_welcome {
  width: 100%;
  padding: 3vw 2.5vw;
  background: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3vw;
}
.personal_ID {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3vw;
  width: 37vw;
  height: 9vw;
  border-radius: 1.5vw;
  background: #eae5f3;
  margin-right: 2vw;
}
.personal_ID p {
  font-weight: 900;
  font-size: 4.2vw;
}
.give_that_code {
  width: 48vw;
  font-weight: 900;
  font-size: 2.6vw;
}
.copyIcon {
  width: 6vw;
  margin-left: 2vw;
}
.welcome_links_column {
  display: flex;
  flex-direction: column;
  width: 39%;
}
.refDelay1 {
  transform: scale(0);
  transition-delay: 0.35s;
}
.konkurses_link {
  background: #ffffff;
  border-radius: 3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 2vw;
  margin-bottom: 3vw;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(0);
  transition-delay: 0.1s;
}
.konkurses_link:last-of-type {
  margin-bottom: 0;
}
.konkurses_link_img {
  width: 18vw;
  margin-bottom: 2vw;
}
.konkurses_link p {
  font-size: 4.1vw;
  font-weight: 900;
  margin-bottom: 1.5vw;
}
.down_padding {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 5vw;
}
.hidden_input {
  opacity: 0;
  transition: 0s;
  width: 0;
  height: 0;
}
.arrow_animated_on_welcome_page_box {
  position: absolute;
  width: 6vw;
  height: 6vw;
  background: linear-gradient(
      0deg,
      rgba(86, 26, 211, 0.24),
      rgba(86, 26, 211, 0.24)
    ),
    linear-gradient(0deg, #702ef7, #702ef7);
  top: 4vw;
  right: 4vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: galochkaAnimation 4s infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-delay: 7s;
  transform: scale(0);
}
.arrow_animated_on_welcome_page {
  width: 3vw;
}
@keyframes galochkaAnimation {
  0% {
    transform: scale(0) rotateY(0deg);
  }
  15% {
    transform: scale(1) rotateY(0deg);
  }
  35% {
    transform: scale(1) rotateY(360deg);
  }
  45% {
    transform: scale(1) rotateY(360deg);
  }
  70% {
    transform: scale(0) rotateY(360deg);
  }
  100% {
    transform: scale(0) rotateY(0deg);
  }
}
.personal_ID_input_box {
  display: flex;
  font-weight: 900;
  font-size: 4.5vw;
}
.personal_ID_input_box input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  padding-left: 2vw;
  font-size: 4.5vw;
  font-weight: 900;
}
