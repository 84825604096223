.want_to_join_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    padding: 12vw 5vw 4vw 5vw;
    /* background: url(../../img/databasebackground.png);
    background-size: cover; */
}
.want_to_join_title{
    font-size: 9.5vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
    text-align: center;
}
.row_want_to_join_buttons{
    display: flex;
    width: 100%;
    margin-top: 4.2vw;
    justify-content: space-between;
}
.row_want_to_join_buttons:last-of-type{
    padding-bottom: 5vw;
}
/* .where_to_start_container .step_title{
    font-size: 10vw;
} */