.arrow_button_go_back_container{
    position: absolute;
    top: 4.4vw;
    left: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw 0;
}
.arrow_button_go_back_container > img{
    width: 9vw;
    margin-right: 1.5vw;
}
.arrow_button_go_back_container > p{
    font-size: 4vw;
    letter-spacing: -0.2vw;
}