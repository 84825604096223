.documents_page_container{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vw 5vw 5vw 5vw;
    background: url(../../img/fullfon.png);
    background-size: cover;
    overflow-y: scroll;
}
.documents_title{
    font-size: 10vw;
    font-family: "TerminatorGenisys";
    margin-bottom: 4vw;
}
.documents_undertitle{
    font-size: 4.1vw;
    text-align: center;
    margin-bottom: 2.5vw;
}
.document_box{
    display: flex;
    justify-content: space-between;
    padding: 2.2vw;
    padding-right: 0;
    /* transform: scale(0); */
    padding-left: 3vw;
    background: #ffffff;
    width: 100%;
    border-radius: 3vw;
    box-shadow: 1px 6px 16px 0px rgba(181, 125, 216, 0.25);
    margin-bottom: 4.5vw;
}
.document_box_img{
    width: 30vw;
}
.document_about{
    width: 53%;
    margin-right: 1vw;
    padding: 1vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.document_about_text1{
    width: 100%;
    font-size: 3.6vw;
}
.document_about a{
    width: 99%;
    color: #ffffff;
    border: none;
    border-radius: 1.5vw;
    font-size: 3.6vw;
    padding: 2.5vw 0;
    background: linear-gradient(93.33deg, #3ACD06 0.41%, #58E700 100.4%);
    text-align: center;
}